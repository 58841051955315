import React from "react";
import AmadeusLogo1 from "../assets/images/suppliers/Amadeus_Logo_1.png";
import TravelportLogo4 from "../assets/images/suppliers/Travelport_Logo_4.png";
import MystiflyLogo5 from "../assets/images/suppliers/Mystifly_Logo_5.png";
import IATILogo7 from "../assets/images/suppliers/IATI_Logo_7.png";
import MultireisenLogo8 from "../assets/images/suppliers/Multireisen_Logo_8.png";
import KiwiLogo9 from "../assets/images/suppliers/Kiwi_Logo_9.png";
import AeroCRS from "../assets/images/suppliers/AeroCRS.png";

const SuppliersFlight = () => {
  return (
    <ul className="suppliers-logos mt-5">
      <li>
        <img src={AmadeusLogo1} alt="Amadeus" />
      </li>
      <li>
        <img src={TravelportLogo4} alt="Travelport" />
      </li>
      <li>
        <img src={MystiflyLogo5} alt="Mystifly" />
      </li>
      <li>
        <img src={IATILogo7} alt="IATI" />
      </li>
      <li>
        <img src={MultireisenLogo8} alt="Multireisen" />
      </li>
      <li>
        <img src={KiwiLogo9} alt="Kiwi" />
      </li>
      <li>
        <img src={AeroCRS} alt="AeroCRS" />
      </li>
    </ul>
  );
};

export default SuppliersFlight;
