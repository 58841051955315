import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import InfographicsBox from "../components/infographics-box";

const ResourcesVideos = () => {
  const travelTechArr = [...Array(80).keys()];
  return (
    <div className="page-inside page-infographics">
      <PageHeader page="videos" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center mb-4">
            <div
              style={{
                boxShadow:
                  "0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset",
              }}
              className="rounded p-4"
            >
              <h5 className="mb-3 text-primary" style={{ minHeight: "50px" }}>
                Benefits of TravelCarma's Booking Platform for Travel Agencies
                and DMCs
              </h5>
              <iframe
                width="100%"
                height="360"
                frameborder="0"
                src="https://www.youtube.com/embed/0GzarSQLLSQ"
                className="rounded"
                key="1"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6 text-center mb-4">
            <div
              style={{
                boxShadow:
                  "0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset",
              }}
              className="rounded p-4"
            >
              <h5 className="mb-3 text-primary" style={{ minHeight: "50px" }}>
                TravelCarma exhibiting at SATTE 2016
              </h5>
              <iframe
                width="100%"
                height="360"
                frameborder="0"
                src="https://www.youtube.com/embed/dxA6hOn8DP4"
                className="rounded"
                key="2"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6 text-center mb-4">
            <div
              style={{
                boxShadow:
                  "0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset",
              }}
              className="rounded p-4"
            >
              <h5 className="mb-3 text-primary" style={{ minHeight: "50px" }}>
                TravelCarma, Travel Technlogy Solutions Provider, presenting
                eMarketplace at PhoCusWright
              </h5>
              <iframe
                width="100%"
                height="360"
                frameborder="0"
                src="https://www.youtube.com/embed/wWCxb7FyGaI"
                className="rounded"
                key="3"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6 text-center mb-4">
            <div
              style={{
                boxShadow:
                  "0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset",
              }}
              className="rounded p-4"
            >
              <h5 className="mb-3 text-primary" style={{ minHeight: "50px" }}>
                TRAVDEX Interview with Saurabh Mehta
              </h5>
              <iframe
                width="100%"
                height="360"
                frameborder="0"
                src="https://www.youtube.com/embed/oX17CsHIDFE"
                className="rounded"
                key="4"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6 text-center mb-4">
            <div
              style={{
                boxShadow:
                  "0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset",
              }}
              className="rounded p-4"
            >
              <h5 className="mb-3 text-primary" style={{ minHeight: "50px" }}>
                Interview with Ronald A. Logan, V.P. Business Development
              </h5>
              <iframe
                width="100%"
                height="360"
                frameborder="0"
                src="https://www.youtube.com/embed/M-CvQiBvCUc"
                className="rounded"
                key="6"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6 text-center mb-4">
            <div
              style={{
                boxShadow:
                  "0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset",
              }}
              className="rounded p-4"
            >
              <h5 className="mb-3 text-primary" style={{ minHeight: "50px" }}>
                Travel Technology Solutions - TravelCarma
              </h5>
              <iframe
                width="100%"
                height="360"
                frameborder="0"
                src="https://www.youtube.com/embed/qIunuGopg7c"
                className="rounded"
                key="5"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesVideos;
