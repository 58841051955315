import React from "react";
import PageHeader from "../components/page-header";

const CompanyLeadership = () => {
  return (
    <div>
      <PageHeader page="leadership-team" />
      <div className="container">
        <p className="text-center mb-5">
          Every successful company has a strong team behind it. TravelCarma is
          no exception - we have a team of travel and technology experts with a
          combined experience of close to a century. Our people have expertise
          in Travel Operations, Software Planning, Development and Deployment,
          XML API Integration, Project Management, Client Management, Product
          Marketing and a host of other IT Business Processes.
        </p>

        <div className="leadership-team-box bg-light rounded p-4 shadow-sm mb-4">
          <h5 className="text-primary">Saurabh Mehta</h5>
          <h6 className="text-secondary mb-4">Founder and CEO</h6>
          <p>
            Mr. Saurabh Mehta is the Founder Director and CEO of Avani Cimcon
            Technologies Ltd and the Chief Technology Architect and mentor to
            TravelCarma, the travel technology brand/division of the company. He
            has been instrumental in visualizing technologies and solutions
            which have earned TravelCarma recognition from customers and awards
            from various industry bodies. He has a post graduate degree in
            engineering from the United States and over 20 years experience in
            Planning, Designing and Implementing Information Systems for a
            variety of businesses all around the world. His extensive experience
            spans across projects in a number of countries in Asia-Pacific, EU,
            North America, Latin America and the Caribbean.
          </p>
          <p>
            Through his expert guidance and innovations the TravelCarma brand
            has become a recognized name in the travel space internationally and
            has some of the most unique and trailblazing solutions. He is a
            technology advisor and strategy consultant to a number of travel
            companies for mentoring and framing their online line business
            strategies. He has been an advisor to the Technical Education Boards
            of the state government and member of technical advisory councils of
            industry bodies. He also serves on boards of companies as member
            technology.
          </p>
        </div>

        <div className="leadership-team-box bg-light rounded p-4 shadow-sm mb-4">
          <h5 className="text-primary">Kaushik Panchal</h5>
          <h6 className="text-secondary mb-4">
            Head of Product Engineering, COO
          </h6>
          <p>
            Mr Kaushik Panchal is the Chief Operating Officer at TravelCarma. He
            has over 18 years of experience in the software industry managing
            and leading software development projects and software products.
          </p>
          <p>
            He is responsible for the formulation of operations strategy,
            policies and processes. Through his effective and inspiring
            leadership, he ensures that employees develop deep knowledge of all
            TravelCarma programs and processes and develop products of the
            highest quality.
          </p>
          <p>
            <strong>He has varied experience in:</strong>
          </p>
          <ul>
            <li>IT infrastructure management.</li>
            <li>Project management.</li>
            <li>Product management.</li>
            <li>Operational strategy and policies.</li>
            <li>
              Effective and inspiring leadership and operations management of
              software products and services.
            </li>
          </ul>
        </div>

        <div className="leadership-team-box bg-light rounded p-4 shadow-sm mb-4">
          <h5 className="text-primary">Ashok Mistry</h5>
          <h6 className="text-secondary mb-4">
            Senior Technical Consultant (Product Engineering)
          </h6>
          <p>
            Mr Ashok Mistry heads the product design team for the organization's
            SaaS platform, Licensed Platform and other related tools and
            methodologies. He also holds the leadership for design, development,
            reviewing and testing of the implementation efforts at TravelCarma.
          </p>
          <p>
            <strong>
              Mr Mistry holds a degree in Physics and a Diploma in Systems
              Management. He possesses over 21 years of IT experience:
            </strong>
          </p>
          <ul>
            <li>
              14+ years' experience as a Project Manager cum Technical Lead
              using ASP.Net, VB.Net, C#, ADO.Net, Crystal Reports for .Net, SQL
              Server, XML, JavaScript, HTML, and IIS 5.0/6.0.
            </li>
            <li>
              4+ years' programming experience using Visual FoxPro 3.0/5.0/6.0
              as a development tool with SQL Server.
            </li>
            <li>
              1+ year's programming experience using power Builder 4.0/5.0 as
              development tool with Watcom, SQL Anywhere & SQL Server.
            </li>
            <li>3+ years' programming experience using FoxPro 2.5/DOS.</li>
            <li>
              Extensive work done to link Microsoft Project with Visual FoxPro
              via OLE automation.
            </li>
          </ul>
        </div>

        <div className="leadership-team-box bg-light rounded p-4 shadow-sm mb-4">
          <h5 className="text-primary">Prasad Sant</h5>
          <h6 className="text-secondary mb-4">
            Program Director, Delivery Head
          </h6>
          <p>
            Prasad Sant is a Senior consultant and Program Director for
            Enterprise Implementation at TravelCarma
          </p>
          <p>
            Prasad is an engineering graduate with 34 years work experience in
            the Industrial Automation, Software & Embedded Product Engineering,
            Industrial IOT and Management Consulting space. He has worked with
            companies like Honeywell, Patni Computer Systems, EcoAxis and
            Selectigence.
          </p>
          <p>
            Prasad has extensive experience in managing large projects. At
            Honeywell, he was a certified Project Management trainer and has
            imparted training to Honeywell PM's across the Asia Pacific region.
          </p>
          <p>
            Prasad has managed large outsourcing engagements in the automation
            engineering services, embedded software and enterprise software
            space. He has program managed the New Product Development
            initiatives in Automation and IOT domains.
          </p>
          <p>
            Prasad has worked in multiple geographies including India, Asia
            Pacific, Middle East, North America and Europe.
          </p>
        </div>

        <div className="leadership-team-box bg-light rounded p-4 shadow-sm mb-4">
          <h5 className="text-primary">Shailesh Raval</h5>
          <h6 className="text-secondary mb-4">Senior Database Consultant</h6>
          <p>
            Shailesh has over 25 years of IT experience, which includes 20+
            years of large scale Application / database design, development and
            deployment experience. He is proficient in a wide range of software
            and environments including Siperian CDI Hub, IBM-WCC/WPC, Oracle,
            MS-SQL Server, MySQL, DB2, Teradata, Application Servers, Web
            Servers, Workflow Engine, Rule Engine, CMS, CRM, HP-UX, Sun Solaris,
            IBM AIX, Linux, Clover ETL, Abinitio, SeeBeyond.
          </p>
          <p>
            <strong>He has expertise in:</strong>
          </p>
          <ul>
            <li>
              Conceptualizing, developing and deploying solutions which are
              integrated, scalable, highly configurable
            </li>
            <li>
              Developing and maintaining data models, data architecture, data
              management standards and conventions, data element naming
              standards and metadata standards
            </li>
            <li>
              Leading and guiding teams of project managers, developers,
              business analysts, test engineers, and partners to ensure
              effective implementation of methods and procedures;
            </li>
            <li>
              Working closely with product vendor teams to enhance the product
            </li>
            <li>
              Collecting, compiling, documenting and understanding business
              requirements and mapping them to the enterprise data and process
              models
            </li>
            <li>
              Fostering relationships with clients, product vendors, and all the
              stakeholders
            </li>
          </ul>
        </div>

        <div className="leadership-team-box bg-light rounded p-4 shadow-sm mb-4">
          <h5 className="text-primary">Kavita Khandhadia</h5>
          <h6 className="text-secondary mb-4">
            Senior Manager, Projects and Implementation
          </h6>
          <p>
            Kavita oversees product management, rostering, XML API Supplier
            &amp; Client Relationships at TravelCarma. She is a technocrat with
            over 12 years of experience in Travel Technology. She is a dynamic
            leader who directs complex, cross-functional teams at TravelCarma.
            She holds a Masters in Computer Applications and has been associated
            with TravelCarma since the beginning.
          </p>
          <p>
            <strong>Her specialties are:</strong>
          </p>
          <ul>
            <li>
              GDS and non-GDS Travel XML API / Channel manager connectivity
            </li>
            <li>SaaS-based Product management and development</li>
            <li>
              Travel domain expertise including distribution and booking
              technologies
            </li>
            <li>
              Efficiently facilitating communication between stakeholders,
              documenting early decisions about high-level design, and allowing
              reuse of design components and patterns between projects
            </li>
            <li>
              Dealing with clients, staff and higher management with tact,
              decisiveness, and timeliness
            </li>
            <li>
              Developing and managing all aspects of customization in the
              product from planning, external vendor relationships,
              communications, resources, budget, change, risks and issues
            </li>
            <li>
              People management, mentoring skills with confidence in dealing
              with Clients, PMO, and Architects
            </li>
            <li>
              Actively participation in and contribution to team discussions on
              problem-solving, brainstorming effective approaches to deal with
              project-specific issues, etc.
            </li>
            <li>
              Understanding interdependencies between technology, operations,
              and business needs
            </li>
          </ul>
        </div>

        <div className="leadership-team-box bg-light rounded p-4 shadow-sm mb-4">
          <h5 className="text-primary">Chintan Goswami</h5>
          <h6 className="text-secondary mb-4">Senior Manager, Marketing</h6>
          <p>
            Chintan is a data-driven inbound marketing specialist with a proven
            track record of designing successful Marketing campaigns that
            engage, educate and convert. He has strong skills and experience in
            Lead and Demand Generation through digital channels, Brand-building,
            Market Research, Pricing and Revenue Optimization. He holds an MBA
            in Marketing from a leading UK university.
          </p>
          <p>
            <strong>His responsibilities include:</strong>
          </p>
          <ul>
            <li>
              Strategizing and executing marketing plans including pricing,
              promotions, positioning and messaging that would differentiate the
              company's offerings from the competition and help in creating a
              unique brand identity.
            </li>
            <li>
              Planning various online marketing activities including Email and
              Social Media campaigns, Paid search, Content Marketing and Press
              outreach.
            </li>
            <li>
              Managing the Website, Blog and Social Media Accounts, with the aim
              of generating quality leads as well as establishing thought
              leadership in the travel industry.
            </li>
            <li>
              Working closely with cross-functional teams internally as well as
              with external agencies to achieve the company's marketing
              objectives.
            </li>
            <li>
              Planning and executing participation at domestic and international
              industry trade shows and other key industry events.
            </li>
            <li>
              Marketing collateral management, including Product Brochures,
              Presentations, Manuals, Videos etc.
            </li>
          </ul>
        </div>

        <div className="leadership-team-box bg-light rounded p-4 shadow-sm mb-4">
          <h5 className="text-primary">Amit Kadiya</h5>
          <h6 className="text-secondary mb-4">Head of Design</h6>
          <p>
            As the head of design at TravelCarma, Amit is responsible for
            managing the UI and UX of all TravelCarma products, including our
            B2C Websites, B2B Portals, Mid-office System, CRS etc. He has a
            total experience of over 13 years with a Bachelors Degree in
            Science, a Post Graduate Diploma in Multimedia and a Certification
            in Search Engine Optimization (SEO).
          </p>
          <p>
            <strong>His responsibilities include:</strong>
          </p>
          <ul>
            <li>Identifying the Product / Customer requirements.</li>
            <li>
              Producing graphic sketches, designs, and copy layouts for online
              content.
            </li>
            <li>
              Determining the size and arrangement of illustrative material and
              copy, selecting style and size of type, and arranging the layout
              based upon available space, knowledge of layout principles, and
              aesthetic design concepts.
            </li>
            <li>
              Ensuring top-notch web site quality, user experience and
              reliability.
            </li>
            <li>
              Preparing and defining the project scope of work, its goal and
              deliverables.
            </li>
            <li>
              Technical leadership for design, development and testing of the
              implementation effort for mid-sized customer projects or
              sub-projects on large customer engagements
            </li>
            <li>
              Accessing and managing functional and non-functional requirements
              and ensure that they are addressed with the technical solution.
            </li>
            <li>
              Staying updated with the latest design trends/technologies and
              implementing them in TravelCarma Solutions.
            </li>
            <li>
              Training and guiding colleagues in progression and development of
              their personal and technical skills..
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompanyLeadership;
