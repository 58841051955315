import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import TagManager from "react-gtm-module";
import Index from "./screens";
import CompanyAboutUs from "./screens/company-aboutus";
import CompanyLeadership from "./screens/company-leadership";
import ApiFlightIntegration from "./screens/api-flight-integration";
import ApiHotelIntegration from "./screens/api-hotel-integration";
import ApiSightseeingIntegration from "./screens/api-sightseeing-integration";
import ApiTransferIntegration from "./screens/api-transfer-integration";
import CompanyWhyUs from "./screens/company-whyus";
import CompanyEvents from "./screens/company-events";
import CompanyAwards from "./screens/company-awards";
import ProductsFlightBookingEngine from "./screens/products-flight-booking-engine";
import ProductsHotelBookingEngine from "./screens/products-hotel-booking-engine";
import ProductsCarBookingEngine from "./screens/products-car-booking-engine";
import ProductsTransfersBookingEngine from "./screens/products-transfers-booking-engine";
import ProductsActivityBookingEngine from "./screens/products-activity-booking-engine";
import ProductsB2BBookingSystem from "./screens/products-b2b-booking-system";
import ProductsBackoffice from "./screens/products-backoffice";
import ProductsInventoryManagement from "./screens/products-inventory-management";
import SolutionsTravelAgency from "./screens/solutions-travel-agency";
import SolutionsDmc from "./screens/solutions-dmc";
import SolutionsBusOperator from "./screens/solutions-bus-operator";
import SolutionsHostAgency from "./screens/solutions-host-agency";
import SolutionsTravelDataXchange from "./screens/solutions-travel-data-xchange";
import ResourcesFaqs from "./screens/resources-faqs";
import ResourcesEbooks from "./screens/resources-ebooks";
import ResourcesInfographics from "./screens/resources-infographics";
import SolutionsInventoryDistribution from "./screens/solutions-inventory-distribution";
import SolutionsDevelopmentApi from "./screens/solutions-development-api";
import SolutionsHajjUmrah from "./screens/solutions-hajj-umrah";
import SolutionsMobileTravelApps from "./screens/solutions-mobile-travel-apps";
import Customers from "./screens/customers";
import CustomerTestimonials from "./screens/customer-testimonils";
import CaseStudies from "./screens/case-studies";
import ResourcesVideos from "./screens/resources-videos";
import ContactUs from "./screens/contact-us";
import SolutionsTravelTechConsulting from "./screens/solutions-traveltech-consulting";
import ApiTravelXmlApiIntegrations from "./screens/api-travel-xml-api-integrations";
import SolutionsEnterpriseSoftware from "./screens/solutions-enterprise-software";
import PrivacyPolicy from "./screens/privacy-policy";
import CompanyNews from "./screens/company-news";
import CompanyPartnerProgram from "./screens/company-partner-program";
import CompanyCareers from "./screens/company-careers";

const App = () => {
  const tagManagerArgs = {
    gtmId: "GTM-NTVXLNX",
  };
  TagManager.initialize(tagManagerArgs);

  return (
    <React.Fragment>
      <Header />
      <div style={{ minHeight: "100vh" }}>
        <Routes>
          <Route key={1} path="/" element={<Index />} />
          <Route
            key={2}
            path="/company/about-travelcarma"
            element={<CompanyAboutUs />}
          />
          <Route
            key={3}
            path="/company/leadership-team"
            element={<CompanyLeadership />}
          />
          <Route key={4} path="/company/why-us" element={<CompanyWhyUs />} />
          <Route key={5} path="/company/awards" element={<CompanyAwards />} />
          <Route key={6} path="/company/events" element={<CompanyEvents />} />
          <Route key={7} path="/company/news" element={<CompanyNews />} />
          <Route
            key={8}
            path="/company/travel-technology-partner-program"
            element={<CompanyPartnerProgram />}
          />
          <Route key={9} path="/company/careers" element={<CompanyCareers />} />
          <Route
            key={11}
            path="/products/booking-engines/flight-booking-engine"
            element={<ProductsFlightBookingEngine />}
          />
          <Route
            key={12}
            path="/products/booking-engines/hotel-booking-engine"
            element={<ProductsHotelBookingEngine />}
          />
          <Route
            key={13}
            path="/products/booking-engines/car-booking-engine"
            element={<ProductsCarBookingEngine />}
          />
          <Route
            key={14}
            path="/products/booking-engines/activity-booking-engine"
            element={<ProductsActivityBookingEngine />}
          />
          <Route
            key={15}
            path="/products/booking-engines/transfers-booking-engine"
            element={<ProductsTransfersBookingEngine />}
          />
          <Route
            key={16}
            path="/products/inventory-management-system"
            element={<ProductsInventoryManagement />}
          />
          <Route
            key={17}
            path="/products/back-office"
            element={<ProductsBackoffice />}
          />
          <Route
            key={18}
            path="/products/b2b-booking-system"
            element={<ProductsB2BBookingSystem />}
          />
          <Route
            key={21}
            path="/solutions/travel-agency-software"
            element={<SolutionsTravelAgency />}
          />
          <Route
            key={22}
            path="/solutions/dmc-software"
            element={<SolutionsDmc />}
          />
          <Route
            key={23}
            path="/solutions/bus-operator"
            element={<SolutionsBusOperator />}
          />
          <Route
            key={24}
            path="/solutions/host-agency-solution"
            element={<SolutionsHostAgency />}
          />
          <Route
            key={25}
            path="/solutions/travel-data-xchange-switch"
            element={<SolutionsTravelDataXchange />}
          />
          <Route
            key={26}
            path="/solutions/hajj-umrah-booking-solution"
            element={<SolutionsHajjUmrah />}
          />
          <Route
            key={27}
            path="/solutions/inventory-distribution"
            element={<SolutionsInventoryDistribution />}
          />
          <Route
            key={28}
            path="/solutions/development-api"
            element={<SolutionsDevelopmentApi />}
          />
          <Route
            key={29}
            path="/solutions/travel-tech-consulting"
            element={<SolutionsTravelTechConsulting />}
          />

          <Route
            key={30}
            path="/solutions/mobile-travel-apps"
            element={<SolutionsMobileTravelApps />}
          />
          <Route
            key={31}
            path="/solutions/enterprise-software-for-large-travel-companies"
            element={<SolutionsEnterpriseSoftware />}
          />

          <Route key={41} path="/customers" element={<Customers />} />
          <Route
            key={42}
            path="/customer-testimonials"
            element={<CustomerTestimonials />}
          />
          <Route key={43} path="/case-studies" element={<CaseStudies />} />

          <Route
            key={51}
            path="/suppliers/travel-xml-api-integrations"
            element={<ApiTravelXmlApiIntegrations />}
          />
          <Route
            key={52}
            path="/suppliers/flight-api-integration"
            element={<ApiFlightIntegration />}
          />
          <Route
            key={53}
            path="/suppliers/hotel-api-integration"
            element={<ApiHotelIntegration />}
          />
          <Route
            key={54}
            path="/suppliers/sightseeing-api-integration"
            element={<ApiSightseeingIntegration />}
          />
          <Route
            key={55}
            path="/suppliers/transfer-api-integration"
            element={<ApiTransferIntegration />}
          />

          <Route
            key={61}
            path="/resources/infographics"
            element={<ResourcesInfographics />}
          />
          <Route
            key={62}
            path="/resources/ebooks"
            element={<ResourcesEbooks />}
          />
          <Route
            key={63}
            path="/resources/videos"
            element={<ResourcesVideos />}
          />
          <Route key={64} path="/resources/faqs" element={<ResourcesFaqs />} />
          <Route key={71} path="/contact-us" element={<ContactUs />} />
          <Route key={72} path="/thankyou" element={<ContactUs />} />
          <Route key={73} path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default App;
