import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IG1 from "../assets/images/resources/infographics/Customer-journey-Infographic-thumbnail.png";
import IG2 from "../assets/images/resources/infographics/Back-office-infograpic-thumbnail.png";
import IG3 from "../assets/images/resources/infographics/Advantage-of-HRS-thumbnail.png";
import IG4 from "../assets/images/resources/infographics/Travel-technology-thumbnail.png";
import IG5 from "../assets/images/resources/infographics/thumbnail-essential-tools.png";
import IG6 from "../assets/images/resources/infographics/SaaS-Infographic-Thumbnail.png";
import IG7 from "../assets/images/resources/infographics/How-Digital-Transformation-thumbnail.png";
import IG8 from "../assets/images/resources/infographics/Head-to-Head-Comparison-thumbnail.png";
import IG9 from "../assets/images/resources/infographics/Post-COVID-19-Predictions-For-The-Travel-Industry.png";
import IG10 from "../assets/images/resources/infographics/How-iPaaS-Can-Help-Travel-Firms-Scale-Up-_-Get-More-Cost-Efficient.png";
import IG11 from "../assets/images/resources/infographics/Essential-features-for-flight-booking-system-thumbnail.png";
import IG12 from "../assets/images/resources/infographics/fomo-to-maximise-bookings.png";
import IG13 from "../assets/images/resources/infographics/advantages-of-moving-to-the-cloud-for-travel-_-hospitality-firms.png";
import IG14 from "../assets/images/resources/infographics/Benefits-of-an-Inventory-Management-System.png";
import IG15 from "../assets/images/resources/infographics/Benefits-of-a-Multi-Lingual-Website-for-Travel-Firms.png";
import IG16 from "../assets/images/resources/infographics/API-vs-White-Label.png";
import IG17 from "../assets/images/resources/infographics/Ancillaries.png";
import IG18 from "../assets/images/resources/infographics/Airlines-vs-Agents.png";
import IG19 from "../assets/images/resources/infographics/Advantages-of-using-Email-for-travel-marketing.png";
import IG20 from "../assets/images/resources/infographics/10-Things-to-Consider-Before-Choosing-a-Travel-Technology-Vendor.png";
import IG21 from "../assets/images/resources/infographics/10-Point-SEO-checklist-for-your-travel-website.png";
import IG22 from "../assets/images/resources/infographics/5-Areas-where-Automation-can-help-Travel-companies.png";
import IG23 from "../assets/images/resources/infographics/Anatomy-of-The-Millennial-Traveler.png";
import IG24 from "../assets/images/resources/infographics/How-Travel-Brands-can-engage-Travelers-across-the-customer-journey.png";
import IG25 from "../assets/images/resources/infographics/IoT-in-Travel.png";
import IG26 from "../assets/images/resources/infographics/Instagram-Infographic.png";
import IG27 from "../assets/images/resources/infographics/IATA.png";
import IG28 from "../assets/images/resources/infographics/How-Travel-Brands-can-use-Snapchat-for-Marketing-and-Customer-Engagement.png";
import IG29 from "../assets/images/resources/infographics/How-Travel-Brands-can-benefit-from-user-generated-content.png";
import IG30 from "../assets/images/resources/infographics/How-Travel-Agencies-can-boost-revenue-in-2020.png";
import IG31 from "../assets/images/resources/infographics/How-tourism-companies-can-boost-sales-during-the-off-season.png";
import IG32 from "../assets/images/resources/infographics/How-to-choose-a-payment-gateway.png";
import IG33 from "../assets/images/resources/infographics/How-Mobile-In-Impacting-The-Travel-Indusrty.png";
import IG34 from "../assets/images/resources/infographics/How-an-API-Management-Platform-Can-Help-Travel-Firms.png";
import IG35 from "../assets/images/resources/infographics/Guide-To-Travel-Inventory.png";
import IG36 from "../assets/images/resources/infographics/Guide-to-Travel-APIs.png";
import IG37 from "../assets/images/resources/infographics/Going-Online.png";
import IG38 from "../assets/images/resources/infographics/Global-State-of-online-travel.png";
import IG39 from "../assets/images/resources/infographics/GDPR_Checklist.png";
import IG40 from "../assets/images/resources/infographics/Features-of-Hotel-Booking-System.png";
import IG41 from "../assets/images/resources/infographics/Facebook-Ebook-Infographic.png";
import IG42 from "../assets/images/resources/infographics/Email-Marketing-Tips-for-travel-business.png";
import IG43 from "../assets/images/resources/infographics/Cybersecurity-Threats.png";
import IG44 from "../assets/images/resources/infographics/Components-of-a-Travel-ERP.png";
import IG45 from "../assets/images/resources/infographics/chinese-outbound-tourism.png";
import IG46 from "../assets/images/resources/infographics/Call-Center-Module.png";
import IG47 from "../assets/images/resources/infographics/Build_vs_Buy.png";
import IG48 from "../assets/images/resources/infographics/Blockchain.png";
import IG49 from "../assets/images/resources/infographics/Best-Affliliate-Programs-for-Travel-Agents.png";
import IG50 from "../assets/images/resources/infographics/benefits-of-b2b-booking-software.png";
import IG51 from "../assets/images/resources/infographics/Big-Data-in-Travel.png";
import IG52 from "../assets/images/resources/infographics/Booking-Abandonment.png";
import IG53 from "../assets/images/resources/infographics/Top_Business_Challenges_faced_by_Travel_Agencies_and_How_a_Travel_ERP_can_help.png";
import IG54 from "../assets/images/resources/infographics/Travel-Agency_Business_Tips_2019.png";
import IG55 from "../assets/images/resources/infographics/Trade-Shows-2020.png";
import IG56 from "../assets/images/resources/infographics/Top-Online-Travel-Trends-2020.png";
import IG57 from "../assets/images/resources/infographics/Top_Travel_API_Suppliers_By_Region.png";
import IG58 from "../assets/images/resources/infographics/Top_OTAs.png";
import IG59 from "../assets/images/resources/infographics/Tips-to-Maximize-Conversions-on-your-Travel-Website.png";
import IG60 from "../assets/images/resources/infographics/Things-a-TMC-should-look-for-in-a-corporate-booking-tool.png";
import IG61 from "../assets/images/resources/infographics/Steps_involved_in_API_Integration.png";
import IG62 from "../assets/images/resources/infographics/State-of-Corporate-Travel.png";
import IG63 from "../assets/images/resources/infographics/SaaS_vs_On-premise.png";
import IG64 from "../assets/images/resources/infographics/Payment_Gateways_Integrated_by_TravelCarma.png";
import IG65 from "../assets/images/resources/infographics/Online-Travel-Agency-Software-Components.png";
import IG66 from "../assets/images/resources/infographics/Native-app-vs-Responsive-Site.png";
import IG67 from "../assets/images/resources/infographics/Muslim-Travel.png";
import IG68 from "../assets/images/resources/infographics/Major-trends-shaping-the-hospitality-industry.png";
import IG69 from "../assets/images/resources/infographics/Key-Industry-Statistics-for-Travel-Apps-_-Tips-to-Improve-App-Performance.png";
import IG70 from "../assets/images/resources/infographics/Travel-Agent-2018.png";
import IG71 from "../assets/images/resources/infographics/Travel-ERP_Benefits.png";
import IG72 from "../assets/images/resources/infographics/Travel-ERP-Mistakes.png";
import IG73 from "../assets/images/resources/infographics/TravelThenVsNow.png";
import IG74 from "../assets/images/resources/infographics/Twitter.png";
import IG75 from "../assets/images/resources/infographics/Video-Marketing.png";
import IG76 from "../assets/images/resources/infographics/VR-in-Travel.png";
import IG77 from "../assets/images/resources/infographics/What-To-Look-for-in-travel-ERP.png";
import IG78 from "../assets/images/resources/infographics/Why_Travel_Firms_should_choose_a_SaaS_Travel_ERP.png";
import IG79 from "../assets/images/resources/infographics/Why-Online-Reviews-matter-in-Travel-and-Hospitality.png";
import IG80 from "../assets/images/resources/infographics/Technologies-to-invest-in-2020.png";

import IGPDF1 from "../assets/images/resources/infograpics-download/info-sap.pdf";
import IGPDF2 from "../assets/images/resources/infograpics-download/back-office-system.pdf";
import IGPDF3 from "../assets/images/resources/infograpics-download/Advantages-HRS.pdf";
import IGPDF4 from "../assets/images/resources/infograpics-download/Benefits of Travel Technology Company.pdf";
import IGPDF5 from "../assets/images/resources/infograpics-download/essential-tools-for-travel-agents-infographic.pdf";
import IGPDF6 from "../assets/images/resources/infograpics-download/saas-booking-system-advantages.pdf";
import IGPDF7 from "../assets/images/resources/infograpics-download/digital-transformation.pdf";
import IGPDF8 from "../assets/images/resources/infograpics-download/Booking-system-integration-methods.pdf";
import IGPDF9 from "../assets/images/resources/infograpics-download/Covid-19-Predictions-for-the-Travel-Industry.pdf";
import IGPDF10 from "../assets/images/resources/infograpics-download/Travel-iPaaS.pdf";
import IGPDF11 from "../assets/images/resources/infograpics-download/essential-features-for-flight-booking-system.pdf";
import IGPDF12 from "../assets/images/resources/infograpics-download/fomo-to-maximise-bookings.pdf";
import IGPDF13 from "../assets/images/resources/infograpics-download/advantages-of-moving-to-the-cloud-for-travel-hospitality-firms.pdf";
import IGPDF14 from "../assets/images/resources/infograpics-download/benefits-of-an-inventory-management-system.pdf";
import IGPDF15 from "../assets/images/resources/infograpics-download/benefits-of-a-multi-lingual-website-for-travel-firms.pdf";
import IGPDF16 from "../assets/images/resources/infograpics-download/api-vs-white-label.pdf";
import IGPDF17 from "../assets/images/resources/infograpics-download/ancillaries.pdf";
import IGPDF18 from "../assets/images/resources/infograpics-download/airlines-vs-agents.pdf";
import IGPDF19 from "../assets/images/resources/infograpics-download/advantages-of-using-email-for-travel-marketing.pdf";
import IGPDF20 from "../assets/images/resources/infograpics-download/10-things-to-consider-before-choosing-a-travel-technology-vendor.pdf";
import IGPDF21 from "../assets/images/resources/infograpics-download/10-point-seo-checklist-for-your-travel-website.pdf";
import IGPDF22 from "../assets/images/resources/infograpics-download/5-areas-where-automation-can-help-travel-companies.pdf";
import IGPDF23 from "../assets/images/resources/infograpics-download/anatomy-of-the-millennial-traveler.pdf";
import IGPDF24 from "../assets/images/resources/infograpics-download/how-travel-brands-can-engage-travelers-across-the-customer-journey.pdf";
import IGPDF25 from "../assets/images/resources/infograpics-download/iot-in-travel.pdf";
import IGPDF26 from "../assets/images/resources/infograpics-download/tips-to-help-travel-firms-master-in-instagram-marketing.pdf";
import IGPDF27 from "../assets/images/resources/infograpics-download/iata.pdf";
import IGPDF28 from "../assets/images/resources/infograpics-download/how-travel-brands-can-use-snapchat-for-marketing-and-customer-engagement.pdf";
import IGPDF29 from "../assets/images/resources/infograpics-download/how-travel-brands-can-benefit-from-user-generated-content.pdf";
import IGPDF30 from "../assets/images/resources/infograpics-download/how-travel-agencies-can-boost-revenue-in-2020.pdf";
import IGPDF31 from "../assets/images/resources/infograpics-download/how-tourism-companies-can-boost-sales-during-the-off-season.pdf";
import IGPDF32 from "../assets/images/resources/infograpics-download/how-to-choose-a-payment-gateway.pdf";
import IGPDF33 from "../assets/images/resources/infograpics-download/how-mobile-in-impacting-the-travel-indusrty.pdf";
import IGPDF34 from "../assets/images/resources/infograpics-download/how-an-api-management-platform-can-help-travel-firms.pdf";
import IGPDF35 from "../assets/images/resources/infograpics-download/guide-to-travel-inventory.pdf";
import IGPDF36 from "../assets/images/resources/infograpics-download/guide-to-travel-apis.pdf";
import IGPDF37 from "../assets/images/resources/infograpics-download/advantages-of-going-online-for-agents.pdf";
import IGPDF38 from "../assets/images/resources/infograpics-download/global-state-of-online-travel.pdf";
import IGPDF39 from "../assets/images/resources/infograpics-download/features-of-hotel-booking-system.pdf";
import IGPDF40 from "../assets/images/resources/infograpics-download/features-of-hotel-booking-system.pdf";
import IGPDF41 from "../assets/images/resources/infograpics-download/5-ways-in-which-you-can-use-facebook-to-promote-your-travel-agency.pdf";
import IGPDF42 from "../assets/images/resources/infograpics-download/email-marketing-tips-for-travel-business.pdf";
import IGPDF43 from "../assets/images/resources/infograpics-download/cybersecurity-threats.pdf";
import IGPDF44 from "../assets/images/resources/infograpics-download/components-of-a-travel-erp.pdf";
import IGPDF45 from "../assets/images/resources/infograpics-download/chinese-outbound-tourism.pdf";
import IGPDF46 from "../assets/images/resources/infograpics-download/call-center-module.pdf";
import IGPDF47 from "../assets/images/resources/infograpics-download/build-vs-buy.pdf";
import IGPDF48 from "../assets/images/resources/infograpics-download/blockchain-in-travel.pdf";
import IGPDF49 from "../assets/images/resources/infograpics-download/best-affliliate-programs-for-travel-agents.pdf";
import IGPDF50 from "../assets/images/resources/infograpics-download/benefits-of-b2b-booking-software.pdf";
import IGPDF51 from "../assets/images/resources/infograpics-download/big-data-in-travel.pdf";
import IGPDF52 from "../assets/images/resources/infograpics-download/booking-abandonment.pdf";
import IGPDF53 from "../assets/images/resources/infograpics-download/top-business-challenges-faced-by-travel-agencies-and-how-a-travel-erp-can-help.pdf";
import IGPDF54 from "../assets/images/resources/infograpics-download/travel-agency-business-tips-2019.pdf";
import IGPDF55 from "../assets/images/resources/infograpics-download/trade-shows-2020.pdf";
import IGPDF56 from "../assets/images/resources/infograpics-download/top-travel-api-suppliers-by-region.pdf";
import IGPDF57 from "../assets/images/resources/infograpics-download/top-travel-api-suppliers-by-region.pdf";
import IGPDF58 from "../assets/images/resources/infograpics-download/top-otas.pdf";
import IGPDF59 from "../assets/images/resources/infograpics-download/tips-to-maximize-conversions-on-your-travel-website.pdf";
import IGPDF60 from "../assets/images/resources/infograpics-download/things-a-tmc-should-look-for-in-a-corporate-booking-tool.pdf";
import IGPDF61 from "../assets/images/resources/infograpics-download/steps-involved-in-api-integration.pdf";
import IGPDF62 from "../assets/images/resources/infograpics-download/state-of-corporate-travel.pdf";
import IGPDF63 from "../assets/images/resources/infograpics-download/saas-vs-licensed.pdf";
import IGPDF64 from "../assets/images/resources/infograpics-download/payment-gateways-integrated-by-travelcarma.pdf";
import IGPDF65 from "../assets/images/resources/infograpics-download/online-travel-agency-software-components.pdf";
import IGPDF66 from "../assets/images/resources/infograpics-download/native-app-vs-responsive-site.pdf";
import IGPDF67 from "../assets/images/resources/infograpics-download/muslim-travel-market.pdf";
import IGPDF68 from "../assets/images/resources/infograpics-download/major-trends-shaping-the-hospitality-industry.pdf";
import IGPDF69 from "../assets/images/resources/infograpics-download/key-industry-statistics-for-travel-apps-and-tips-to-improve-app-performance.pdf";
import IGPDF70 from "../assets/images/resources/infograpics-download/travel-agent-2018.pdf";
import IGPDF71 from "../assets/images/resources/infograpics-download/travel-erp-benefits.pdf";
import IGPDF72 from "../assets/images/resources/infograpics-download/travel-erp-mistakes.pdf";
import IGPDF73 from "../assets/images/resources/infograpics-download/how-technology-has-changed-the-face-of-travel.pdf";
import IGPDF74 from "../assets/images/resources/infograpics-download/5-tips-for-twitter-success-for-travel-agencies.pdf";
import IGPDF75 from "../assets/images/resources/infograpics-download/importance-of-video-in-travel-marketing.pdf";
import IGPDF76 from "../assets/images/resources/infograpics-download/vr-in-travel.pdf";
import IGPDF77 from "../assets/images/resources/infograpics-download/what-to-look-for-in-travel-erp.pdf";
import IGPDF78 from "../assets/images/resources/infograpics-download/why-travel-firms-should-choose-a-saas-travel-erp.pdf";
import IGPDF79 from "../assets/images/resources/infograpics-download/why-online-reviews-matter-in-travel-and-hospitality.pdf";
import IGPDF80 from "../assets/images/resources/infograpics-download/technologies-to-invest-in-2020.pdf";

const InfographicsBox = (props) => {
  const [infographics, setInfographics] = useState();
  const data = [
    {
      id: "TravelTech1",
      thumb: IG1,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF1,
    },
    {
      id: "TravelTech2",
      thumb: IG2,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF2,
    },
    {
      id: "TravelTech3",
      thumb: IG3,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF3,
    },
    {
      id: "TravelTech4",
      thumb: IG4,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF4,
    },
    {
      id: "TravelTech5",
      thumb: IG5,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF5,
    },
    {
      id: "TravelTech6",
      thumb: IG6,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF6,
    },
    {
      id: "TravelTech7",
      thumb: IG7,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF7,
    },
    {
      id: "TravelTech8",
      thumb: IG8,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF8,
    },
    {
      id: "TravelTech9",
      thumb: IG9,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF9,
    },
    {
      id: "TravelTech10",
      thumb: IG10,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF10,
    },
    {
      id: "TravelTech11",
      thumb: IG11,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF11,
    },
    {
      id: "TravelTech12",
      thumb: IG12,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF12,
    },
    {
      id: "TravelTech13",
      thumb: IG13,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF13,
    },
    {
      id: "TravelTech14",
      thumb: IG14,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF14,
    },
    {
      id: "TravelTech15",
      thumb: IG15,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF15,
    },
    {
      id: "TravelTech16",
      thumb: IG16,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF16,
    },
    {
      id: "TravelTech17",
      thumb: IG17,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF17,
    },
    {
      id: "TravelTech18",
      thumb: IG18,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF18,
    },
    {
      id: "TravelTech19",
      thumb: IG19,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF19,
    },
    {
      id: "TravelTech20",
      thumb: IG20,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF20,
    },
    {
      id: "TravelTech21",
      thumb: IG21,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF21,
    },
    {
      id: "TravelTech22",
      thumb: IG22,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF22,
    },
    {
      id: "TravelTech23",
      thumb: IG23,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF23,
    },
    {
      id: "TravelTech24",
      thumb: IG24,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF24,
    },
    {
      id: "TravelTech25",
      thumb: IG25,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF25,
    },
    {
      id: "TravelTech26",
      thumb: IG26,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF26,
    },
    {
      id: "TravelTech27",
      thumb: IG27,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF27,
    },
    {
      id: "TravelTech28",
      thumb: IG28,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF28,
    },
    {
      id: "TravelTech29",
      thumb: IG29,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF29,
    },
    {
      id: "TravelTech30",
      thumb: IG30,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF30,
    },
    {
      id: "TravelTech31",
      thumb: IG31,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF31,
    },
    {
      id: "TravelTech32",
      thumb: IG32,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF32,
    },
    {
      id: "TravelTech33",
      thumb: IG33,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF33,
    },
    {
      id: "TravelTech34",
      thumb: IG34,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF34,
    },
    {
      id: "TravelTech35",
      thumb: IG35,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF35,
    },
    {
      id: "TravelTech36",
      thumb: IG36,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF36,
    },
    {
      id: "TravelTech37",
      thumb: IG37,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF37,
    },
    {
      id: "TravelTech38",
      thumb: IG38,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF38,
    },
    {
      id: "TravelTech39",
      thumb: IG39,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF39,
    },
    {
      id: "TravelTech40",
      thumb: IG40,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF40,
    },
    {
      id: "TravelTech41",
      thumb: IG41,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF41,
    },
    {
      id: "TravelTech42",
      thumb: IG42,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF42,
    },
    {
      id: "TravelTech43",
      thumb: IG43,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF43,
    },
    {
      id: "TravelTech44",
      thumb: IG44,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF44,
    },
    {
      id: "TravelTech45",
      thumb: IG45,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF45,
    },
    {
      id: "TravelTech46",
      thumb: IG46,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF46,
    },
    {
      id: "TravelTech47",
      thumb: IG47,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF47,
    },
    {
      id: "TravelTech48",
      thumb: IG48,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF48,
    },
    {
      id: "TravelTech49",
      thumb: IG49,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF49,
    },
    {
      id: "TravelTech50",
      thumb: IG50,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF50,
    },
    {
      id: "TravelTech51",
      thumb: IG51,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF51,
    },
    {
      id: "TravelTech52",
      thumb: IG52,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF52,
    },
    {
      id: "TravelTech53",
      thumb: IG53,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF53,
    },
    {
      id: "TravelTech54",
      thumb: IG54,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF54,
    },
    {
      id: "TravelTech55",
      thumb: IG55,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF55,
    },
    {
      id: "TravelTech56",
      thumb: IG56,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF56,
    },
    {
      id: "TravelTech57",
      thumb: IG57,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF57,
    },
    {
      id: "TravelTech58",
      thumb: IG58,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF58,
    },
    {
      id: "TravelTech59",
      thumb: IG59,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF59,
    },
    {
      id: "TravelTech60",
      thumb: IG60,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF60,
    },
    {
      id: "TravelTech61",
      thumb: IG61,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF61,
    },
    {
      id: "TravelTech62",
      thumb: IG62,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF62,
    },
    {
      id: "TravelTech63",
      thumb: IG63,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF63,
    },
    {
      id: "TravelTech64",
      thumb: IG64,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF64,
    },
    {
      id: "TravelTech65",
      thumb: IG65,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF65,
    },
    {
      id: "TravelTech66",
      thumb: IG66,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF66,
    },
    {
      id: "TravelTech67",
      thumb: IG67,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF67,
    },
    {
      id: "TravelTech68",
      thumb: IG68,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF68,
    },
    {
      id: "TravelTech69",
      thumb: IG69,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF69,
    },
    {
      id: "TravelTech70",
      thumb: IG70,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF70,
    },
    {
      id: "TravelTech71",
      thumb: IG71,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF71,
    },
    {
      id: "TravelTech72",
      thumb: IG72,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF72,
    },
    {
      id: "TravelTech73",
      thumb: IG73,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF73,
    },
    {
      id: "TravelTech74",
      thumb: IG74,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF74,
    },
    {
      id: "TravelTech75",
      thumb: IG75,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF75,
    },
    {
      id: "TravelTech76",
      thumb: IG76,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF76,
    },
    {
      id: "TravelTech77",
      thumb: IG77,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF77,
    },
    {
      id: "TravelTech78",
      thumb: IG78,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF78,
    },
    {
      id: "TravelTech79",
      thumb: IG79,
      title: "Travel Journey Map After Covid-19",
      download: IGPDF79,
    },
    {
      id: "TravelTech80",
      thumb: IG80,
      title: "Top Benefits of Travel Back Office System",
      download: IGPDF80,
    },
  ];

  const getData = () => {
    let box = data.find((x) => x.id === props?.id);

    setInfographics(box);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="text-center">
      <figure>
        <img
          src={infographics?.thumb}
          alt="Travel Journey Map After Covid-19"
        />
      </figure>
      <h5>{infographics?.title}</h5>
      <Link
        className="btn btn-sm btn-primary"
        to={infographics?.download}
        target="_blank"
      >
        Download
      </Link>
    </div>
  );
};

export default InfographicsBox;
