import React from "react";
import TravelCarmaCustomers from "../assets/images/travelcarma-customers.png";
import { Link } from "react-router-dom";

const HomeCustomers = () => {
  return (
    <div className="home-customers mt-5">
      <div className="container text-center">
        <h2 className="text-center mb-5">200+ Satisfied Clients Worldwide</h2>
        <img
          src={TravelCarmaCustomers}
          className="w-100"
          style={{ maxWidth: "1184px" }}
          alt="TravelCarma Customers"
        />
        <Link to="customer-testimonials" className="btn btn-primary mt-3">
          What our Clients say
        </Link>
      </div>
    </div>
  );
};

export default HomeCustomers;
