import React from "react";
import PageHeader from "../components/page-header";
import SvgIcons from "../components/svg-icons";

const CompanyWhyUs = () => {
  return (
    <div className="page-why-us">
      <PageHeader page="why-us" />
      <div className="container">
        <p className="text-center mb-5">
          Our goal is to save you time and money and boost your profitability.
          We aim to offer every facility and convenience expected by your
          customers today and provide them a memorable online experience, so
          that they keep coming back to you and your business continues to grow.
        </p>

        <div className="text-center">
          <div>
            <h2 className="mb-5">Our Experience, Expertise and Knowledge</h2>
            <ul className="shadow-box list-unstyled row">
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons?.domain_expertise}
                  <h3>Domain Expertise</h3>
                  <p className="text-secondary">
                    We have worked with travel and hospitality companies of all
                    types and sizes over a span of over 20 years and we
                    understand the pain points and challenges different travel
                    businesses face in different areas of the business.
                  </p>
                </div>
              </li>
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons?.supplier_flight}
                  <h3>Supplier Integration Expertise</h3>
                  <p className="text-secondary">
                    We have integrated 80+ global API suppliers and have strong
                    relationships with suppliers globally, so we can help you if
                    you need assistance in dealing with any of the suppliers,
                    pre or post integration.
                  </p>
                </div>
              </li>
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons.global_touch}
                  <h3>Global Brand with a Local Touch</h3>
                  <p className="text-secondary">
                    Our system is used by 150+ clients across more than 50
                    countries. We understand diverse cultures, customs and ways
                    of doing business. This is reflected in our software, which
                    supports multiple languages and currencies.
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div className="mt-4">
            <h2 className="mb-5">Robust Technology Architecture</h2>
            <ul className="shadow-box list-unstyled row">
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons?.channel_management}
                  <h3>Strong Channel Management Capabilities</h3>
                  <p className="text-secondary">
                    Our unique middleware engine allows large travel businesses
                    to aggregate data from multiple channels into a single API
                    which they use to distribute inventory and content to their
                    sub-agents and channel partners.
                  </p>
                </div>
              </li>
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons?.flexibility_scale}
                  <h3>Flexibility to Scale</h3>
                  <p className="text-secondary">
                    Our solution is scalable, allowing you to upgrade to API
                    services for other businesses such as Transfers,
                    Sightseeing/Day tours, Car Rental suppliers but without any
                    new interface development as our unified API interface is
                    driven completely by the request structure.
                  </p>
                </div>
              </li>
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons.integrates_technology}
                  <h3>Integrates with your Existing Technology</h3>
                  <p className="text-secondary">
                    Our platform is built on the services architecture and can
                    seamlessly connect to your existing accounting, customer and
                    CRM systems etc, thus preserving your investments into
                    third-party systems.
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div className="mt-4">
            <h2 className="mb-5">Superlative Customer Experience</h2>
            <ul className="shadow-box list-unstyled row">
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons?.mobile_responsive}
                  <h3>Attractive Mobile Responsive Portals</h3>
                  <p className="text-secondary">
                    TravelCarma portals are optimized for mobile devices so your
                    customers will get a rich experience across all devices.
                    This is key in today's mobile world, where a large number of
                    people like to book travel on their smartphones and tablets.
                  </p>
                </div>
              </li>
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons?.dynamic_packaging}
                  <h3>Dynamic Packaging Flexibility</h3>
                  <p className="text-secondary">
                    Your customers can create their own packages by adding
                    different flight, hotel and vehicle combinations and share
                    those options with you via email. You can then discuss their
                    created trips with you, and you can go ahead and book the
                    trip for them.
                  </p>
                </div>
              </li>
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons.payment_flexibility}
                  <h3>Payment Flexibility</h3>
                  <p className="text-secondary">
                    Our Book-now-pay-later allows you to hold bookings for your
                    customers (within the cancellation period) and collect the
                    payment from them later. This feature combines the
                    convenience of online and the flexibility of offline.
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div className="mt-4">
            <h2 className="mb-5">Increased Productivity</h2>
            <ul className="shadow-box list-unstyled row">
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons?.time_cost_savings}
                  <h3>Massive Time and Cost savings</h3>
                  <p className="text-secondary">
                    Our system automates routine administrative tasks, reduce
                    your manpower cost and minimize your turnaround time, which
                    will keep your customers happy and increase your efficiency.
                  </p>
                </div>
              </li>
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons?.package_quote_creation}
                  <h3>Fast Package and Quote creation</h3>
                  <p className="text-secondary">
                    Our trip planner and quotation builder modules allow you to
                    build trips for your customers and create and store price
                    quotes for them in minutes.
                  </p>
                </div>
              </li>
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons.resource_optimization}
                  <h3>Resource Optimization</h3>
                  <p className="text-secondary">
                    The administrative time and cost you'll save through our
                    system would free up your resources and allow you to invest
                    them into more important areas of your business.
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div className="mt-4">
            <h2 className="mb-5">Better Revenue Management</h2>
            <ul className="shadow-box list-unstyled row">
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons?.customer_class_wise_pricing}
                  <h3>Customer class-wise pricing</h3>
                  <p className="text-secondary">
                    You can create different customer classes in the system,
                    assign a class to every registered customer, and apply
                    promotional/special rates in the system based on the
                    customer class.
                  </p>
                </div>
              </li>
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons?.agent_class_wise_pricing}
                  <h3>Agent class-wise pricing</h3>
                  <p className="text-secondary">
                    You can configure different markups as well as exceptions
                    for different downline agents, depending on their
                    performance. You can also set location wise and destination
                    wise markups.
                  </p>
                </div>
              </li>
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons.superior_management}
                  <h3>Superior Feed Management</h3>
                  <p className="text-secondary">
                    If you offer promotions on the feeds, you can switch off the
                    feeds where the balance is less, in order to optimize the
                    use of your deposit and make more money. You can also
                    control airlines in the feeds and even switch to different
                    suppliers automatically for different destinations,
                    minimizing your effort and optimizing your sales.
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div className="mt-4">
            <h2 className="mb-5">Error Free Accounting & Reconciliation</h2>
            <ul className="shadow-box list-unstyled row">
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons?.supplier_management}
                  <h3>Supplier Management</h3>
                  <p className="text-secondary">
                    Easily manage accounting including reconciliation with
                    suppliers, through supplier and product wise drill down
                    ledgers.
                  </p>
                </div>
              </li>
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons?.customer_management}
                  <h3>Customer Management</h3>
                  <p className="text-secondary">
                    Customer invoice tallying, payments against invoice,
                    cancellations, refunds, last minute booking and payment.
                  </p>
                </div>
              </li>
              <li className="col-lg-4 mb-3">
                <div>
                  {SvgIcons.peace_of_mind}
                  <h3>Peace of Mind</h3>
                  <p className="text-secondary">
                    All your manual tasks are handled by the system and you can
                    access business data and reports from anywhere and on any
                    device, making your life very easy.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyWhyUs;
