import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import SuppliersActivity from "../components/suppliers-activity";

const ApiSightseeingIntegration = () => {
  return (
    <div>
      <PageHeader page="sightseeing-api-integration" />
      <div className="container">
        <div className="text-center">
          <p>
            <b>Activities/experiences/sightseeing</b> is one of the fastest
            growing segments in online travel. <b>Activity API Integration</b>{" "}
            helps you aggregate global sightseeing and activity content and
            distribute it via multiple sales channels under your own branding.
          </p>

          <p>
            TravelCarma has integrated all major Activity API suppliers,
            including Viator, Hotelbeds, GTA and Tourico Holidays. We provide an
            end-to-end{" "}
            <Link
              to="/products/booking-engines/activity-booking-engine"
              className="text-decoration-none text-primary"
            >
              activity booking engine
            </Link>{" "}
            which can integrate with any activity supplier of your choice to
            provide your B2C/B2B clients bookable deals on your website with
            live pricing and availability.
          </p>

          <SuppliersActivity />
        </div>
      </div>
    </div>
  );
};

export default ApiSightseeingIntegration;
