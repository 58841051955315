import React from "react";
import HotelbedsLogo1 from "../assets/images/suppliers/Hotelbeds_Logo_1.png";
import CartrawlerLogo2 from "../assets/images/suppliers/Cartrawler_Logo_2.png";
import GTALogo3 from "../assets/images/suppliers/GTA_Logo_3.png";
import TouricoLogo4 from "../assets/images/suppliers/Tourico_Logo_4.png";
import DestiWorldLogo5 from "../assets/images/suppliers/DestiWorld_Logo_5.png";
import SabreLogo6 from "../assets/images/suppliers/Sabre_Logo_6.png";
import HertzLogo7 from "../assets/images/suppliers/Hertz_Logo_7.png";
import iWayLogo8 from "../assets/images/suppliers/iWay_Logo_8.png";
import PricelineLogo from "../assets/images/suppliers/PricelineLogo.png";

const SuppliersCar = () => {
  return (
    <ul className="suppliers-logos mt-5">
      <li>
        <img src={HotelbedsLogo1} alt="Hotelbeds" />
      </li>
      <li>
        <img src={CartrawlerLogo2} alt="Cartrawler" />
      </li>
      <li>
        <img src={GTALogo3} alt="GTA" />
      </li>
      <li>
        <img src={TouricoLogo4} alt="Tourico" />
      </li>
      <li>
        <img src={DestiWorldLogo5} alt="Destinations Of the World" />
      </li>
      <li>
        <img src={SabreLogo6} alt="Sabre" />
      </li>
      <li>
        <img src={HertzLogo7} alt="Hertz" />
      </li>
      <li>
        <img src={iWayLogo8} alt="iWay" />
      </li>
      <li>
        <img src={PricelineLogo} alt="PriceLine" />
      </li>
    </ul>
  );
};

export default SuppliersCar;
