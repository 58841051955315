import React from "react";
import PageHeader from "../components/page-header";
import DevelopmentApiClipart from "../assets/images/solutions/development-api-clipart.png";
import ApiOtaPortal from "../assets/images/solutions/api-ota-portal.png";
import SvgIcons from "../components/svg-icons";
import { Link } from "react-router-dom";

const SolutionsDevelopmentApi = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="development-api" />
      <div className="container">
        <ul className="list-box">
          <li>
            <i className="material-icons"></i>Looking to launch your own travel
            applications with fully customized front ends?
          </li>
          <li>
            <i className="material-icons"></i>Worried that building such a
            solution in-house will cost a huge amount of money and take several
            months to develop?
          </li>
          <li>
            <i className="material-icons"></i>What if you could access
            ready-to-use travel components and have your developers focus only
            on the UI/UX?
          </li>
        </ul>

        <h2 className="text-center mt-5 mb-5">
          Introducing TravelCarma Development APIs
        </h2>

        <div className="row">
          <div className="col-lg-4 text-center">
            <img
              src={DevelopmentApiClipart}
              alt="Development API"
              className="w-100"
              style={{ maxWidth: "456px" }}
            />
          </div>
          <div className="col-lg-8 text-justify">
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              Our Application Development APIs allow travel organizations with
              in-house tech teams to launch their own travel applications in far
              less time and cost than it would take if they developed the entire
              system from scratch.
            </p>
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              We provide ready-to-deploy APIs for various functionalities like
              agency management, booking management, customer management,
              pricing rules, aggregation etc. Think of our Development APIs as
              building blocks - Your developers can simply integrate them and
              build business UIs on top of these components. This way you
              control the look-and-feel of your travel applications but save
              months on building the back end components.
            </p>
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              Our APIs are platform agnostic, providing you the flexibility to
              use them for a wide range of platforms including ASP.NET, PHP,
              Android, iOS etc. So using our APIs you can build a custom UI for
              web and another one for mobile, with the same functionalities in a
              matter of days.
            </p>
          </div>
        </div>
      </div>

      <div
        className="text-center pb-3 pt-4 mb-5 mt-5"
        style={{
          background:
            "linear-gradient(to bottom, rgba(229,229,229,0.5) 0%,rgba(255,255,255,0.5) 51%,rgba(229,229,229,0.5) 100%)",
        }}
      >
        <div className="container">
          <h2 className="text-center mt-3 mb-5">Key Benefits</h2>

          <ul className="shadow-box list-unstyled row text-center mb-5">
            <li className="col-lg-4 mb-3">
              <div>
                {SvgIcons?.history}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Shorter development time as the same API schema can be
                  utilized for all products
                </h3>
              </div>
            </li>
            <li className="col-lg-4 mb-3">
              <div>
                {SvgIcons?.hourglass_empty}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Faster time-to-market as only front ends have to be developed
                </h3>
              </div>
            </li>
            <li className="col-lg-4 mb-3">
              <div>
                {SvgIcons?.mobile_responsive}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Flexibility to design the UX as per your specifications and
                  needs
                </h3>
              </div>
            </li>
            <li className="col-lg-4">
              <div>
                {SvgIcons?.library_add_check}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Our API provides flags for all screens so your developers
                  won't have to write them
                </h3>
              </div>
            </li>
            <li className="col-lg-4">
              <div>
                {SvgIcons?.flexibility_scale}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Easier to expand your offerings and scale your business
                </h3>
              </div>
            </li>
            <li className="col-lg-4">
              <div>
                {SvgIcons?.supplier_flight}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Leverage our expertise in travel technology
                </h3>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="container">
        <h2 className="text-center mt-5 mb-5">Overview</h2>
        <img
          src={ApiOtaPortal}
          className="shadow w-100 rounded"
          alt="API OTA Portal"
        />

        <h2 className="text-center mt-5 mb-5">Ready-to-deploy APIs</h2>

        <div className="row">
          <div className="col-lg-6">
            <h4 className="text-primary mb-3">User Management API</h4>
            <ul className="list-tab-arr">
              <li>
                <i className="material-icons"></i>
                Manage customer/user details
              </li>
              <li>
                <i className="material-icons"></i>
                Support for social media authentication/registration
              </li>
              <li>
                <i className="material-icons"></i>
                Support for OTP based registration via SMS when signing up with
                phone number with preferred gateways
              </li>
            </ul>
            <h4 className="text-primary mb-3 mt-5">
              B2B Customer Management API
            </h4>
            <ul className="list-tab-arr">
              <li>
                <i className="material-icons"></i>
                Provides a list of registered customers for agent
              </li>
              <li>
                <i className="material-icons"></i>
                Allows user to create customer
              </li>
              <li>
                <i className="material-icons"></i>
                Provides customer class
              </li>
              <li>
                <i className="material-icons"></i>
                Allows user to update customer details
              </li>
            </ul>
            <h4 className="text-primary mb-3 mt-5">Search API</h4>
            <ul className="list-tab-arr">
              <li>
                <i className="material-icons"></i>
                Retrieve the list of available inventory for the search criteria
              </li>
              <li>
                <i className="material-icons"></i>
                By passing the details of travelers and dates users can get the
                list of available flights, hotels and activities for the
                specified criteria
              </li>
            </ul>
            <h4 className="text-primary mb-3 mt-5">Fare Breakup API</h4>
            <ul className="list-tab-arr">
              <li>
                <i className="material-icons"></i>
                Different layouts of fare breakups with formatted currencies and
                currency symbols
              </li>
              <li>
                <i className="material-icons"></i>
                Available for various screens like details, policies, cart fare
                breakups, pre booking fare breakups, post booking fare breakups,
                cancellation/modification fare breakups etc.
              </li>
              <li>
                <i className="material-icons"></i>
                UI developers don't have to make calculations and currency
                formatting
              </li>
            </ul>
            <h4 className="text-primary mb-3 mt-5">CMS API</h4>
            <ul className="list-tab-arr">
              <li>
                <i className="material-icons"></i>
                Provides all the functionalities of our Content Management
                System
              </li>
              <li>
                <i className="material-icons"></i>
                Includes a wide variety of modules like hot deals, static
                packages, popular destinations, travel guide, newsletter
                subscription, FAQ, inquiry module
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <h4 className="text-primary mb-3">Traveler Management API</h4>
            <ul className="list-tab-arr">
              <li>
                <i className="material-icons"></i>
                This API provides a list of co-travelers added by the user
              </li>
              <li>
                <i className="material-icons"></i>
                Includes parameters like name, location, contact details,
                nationality, passport details etc
              </li>
            </ul>
            <h4 className="text-primary mb-3 mt-5">Booking Management API</h4>
            <ul className="list-tab-arr">
              <li>
                <i className="material-icons"></i>
                Provides a list of bookings made by the user
              </li>
              <li>
                <i className="material-icons"></i>
                Includes details like booking ID, itinerary no, date, status,
                modification, cancellation etc
              </li>
            </ul>
            <h4 className="text-primary mb-3 mt-5">Unified Cart API</h4>
            <ul className="list-tab-arr">
              <li>
                <i className="material-icons"></i>
                Creation of multiple cart instances, where each cart can have
                different items from different business
              </li>
              <li>
                <i className="material-icons"></i>
                Selectable user inputs like baggage selection, meal preferences,
                special promotions, changeable amenities
              </li>
              <li>
                <i className="material-icons"></i>
                Text based user inputs like additional questions from suppliers,
                promo codes, additional dynamic input fields
              </li>
              <li>
                <i className="material-icons"></i>
                List of add-ons available and applied
              </li>
            </ul>
            <h4 className="text-primary mb-3 mt-5">B2B Ledger API</h4>
            <ul className="list-tab-arr">
              <li>
                <i className="material-icons"></i>
                Provides details about the ledger balance of b2b agents
              </li>
              <li>
                <i className="material-icons"></i>
                Includes parameters like transaction date, transaction type,
                booking ID, debit/credit amount, opening balance, closing
                balance etc
              </li>
            </ul>
            <h4 className="text-primary mb-3 mt-5">Payment Gateway API</h4>
            <ul className="list-tab-arr">
              <li>
                <i className="material-icons"></i>
                Integration with various payment gateways which can be
                configured from back office and supported through API in
                redirection manner, where for API based payment gateways API
                Payment gateway page will ask for payment information and
                redirection will be performed to redirect payment gateways
              </li>
              <li>
                <i className="material-icons"></i>
                For payment gateways integrated via payment gateway's SDKs, the
                API also provides configuration and authentication parameters
                for those SDKs and their integration during the payment process
                flow
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsDevelopmentApi;
