import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import ImageSlider from "../components/image-slider";

const ProductsTransfersBookingEngine = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="transfers-booking-engine" />
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-6 text-center">
            <ImageSlider slider="TBS1" />
          </div>
          <div className="col-lg-6 text-justify">
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              <strong>TravelCarma Transfers booking engine</strong> provides
              agencies and transfers operators the ability to let their B2C, B2B
              and corporate clients book point-to-point transfers online with
              live availability and instant confirmation.
            </p>
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              The booking engine can display live inventory from both your
              direct contracts configured in the{" "}
              <strong>inventory system</strong> as well as through{" "}
              <Link
                to="/suppliers/travel-xml-api-integrations"
                className="text-decoration-none text-primary"
              >
                XML feeds from suppliers
              </Link>{" "}
              like Hotelbeds, iWay transfers, Sabre and Tourico.
            </p>
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              Moreover, it provides you great cross-sell opportunities by
              allowing your clients to book transfers along with flights, hotels
              and other products you have in your inventory.
            </p>

            <Link to="/contact-us" className="btn btn-primary mt-3">
              Request a Demo
            </Link>
          </div>
        </div>
      </div>

      <div className="benefits-section-bg">
        <div className="container">
          <div className="row">
            <h2 className="text-center">
              Benefits of Our Transfers Booking Engine
            </h2>
            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>Available on both web and
                  mobile (responsive and native app)
                </li>
                <li>
                  <i className="material-icons"></i>Supports multiple sales
                  channels - B2C, B2B, B2B2C, B2E and Corporate
                </li>
                <li>
                  <i className="material-icons"></i>Supports multiple languages
                  and currencies with real-time currency conversion
                </li>
                <li>
                  <i className="material-icons"></i>Aggregation of multiple
                  transfers XML feeds along with directly contracted inventory
                  into a single search interface
                </li>
                <li>
                  <i className="material-icons"></i>Multiple search filters and
                  sorting options including price, currency, vehicle type
                </li>
                <li>
                  <i className="material-icons"></i>Ability to display transfer
                  description, images, terms and conditions
                </li>
                <li>
                  <i className="material-icons"></i>Ability to book transfers
                  with flights and hotels
                </li>
                <li>
                  <i className="material-icons"></i>Ability to add transfers to
                  wishlist and favourites
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>Ability to set up inventory
                  with rates, availability, policies and other details in the
                  transfer inventory management system
                </li>
                <li>
                  <i className="material-icons"></i>Ability to show promotions
                  and advertisements in search results
                </li>
                <li>
                  <i className="material-icons"></i>Automatic voucher generation
                  with your own branding
                </li>
                <li>
                  <i className="material-icons"></i>Ability to store frequent
                  co-traveler details to save time during booking
                </li>
                <li>
                  <i className="material-icons"></i>Login with Facebook and
                  Google
                </li>
                <li>
                  <i className="material-icons"></i>User-friendly interface for{" "}
                  <strong>centralized customer and booking management</strong>
                </li>
                <li>
                  <i className="material-icons"></i>Separate markups and
                  commission setup for B2C and B2B
                </li>
                <li>
                  <i className="material-icons"></i>Extensive booking reports
                  for decision making and accounting reconciliation
                </li>
                <li>
                  <i className="material-icons"></i>Auto cancellation of
                  unconfirmed bookings before the cancellation period starts
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsTransfersBookingEngine;
