import React, { useEffect, useState } from "react";
import PageHeader from "../components/page-header";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-inside">
      <PageHeader page="privacypolicy" />
      <div className="container">
        <p>
          This Privacy Policy describes how TravelCarma as a data processor
          collects, uses and discloses information, and what choices you have
          with respect to this.
        </p>
        <h3 className="mt-5 mb-4">
          What and who does this privacy policy apply to?
        </h3>
        <ul className="list-tab-arr">
          <li>
            TravelCarma obtains information from individuals and companies that
            visit our websites, by email, telephone and written documents.
          </li>
          <li>
            Information collected includes names, postal addresses, e-mail
            addresses, telephone numbers, and certain preferences you have
            depending on the services or information you request.
          </li>
          <li>
            Information collected when you visit the TravelCarma website may
            also include personal information in a variety of circumstances.
          </li>
          <li>
            when you register with us, subscribe to our newsletter, enter
            competitions or register for promotions.
          </li>
          <li>when you take part in surveys or provide us with feedback.</li>
        </ul>
        <h3 className="mt-5 mb-4">Why we process your data?</h3>
        <p>We may use your data for the following purposes:</p>
        <ul className="list-tab-arr">
          <li>
            To provide you with the highest possible level of service and to
            help you to obtain the best service from our software and website.
          </li>
          <li>
            To notify you about our products and services, special offers and
            promotions and any other marketing materials which we think may
            interest you.
          </li>
          <li>
            To keep you up to date with and invite you to any of our latest,
            relevant events, training programs, webinars or podcasts we are
            producing
          </li>
          <li>
            To give you access to content and events provided by our approved
            third party partners.
          </li>
          <li>For other administrative purposes and for internal analysis.</li>
          <li>To participate as part of a survey or to get feedback.</li>
        </ul>
        <h3 className="mt-5 mb-4">How we use your data?</h3>
        <p>
          It's in TravelCarma's legitimate business interest to keep its
          customers and contacts informed of our latest content, products and
          services.
        </p>
        <p>
          We process such data and use direct marketing as part of this
          legitimate interest. It's necessary for us to use a variety of direct
          marketing channels and messages to ensure our contacts are kept
          informed, and wherever possible we make sure the messages we send are
          targeted and relevant. Any direct marketing we undertake complies with
          e-privacy rules on consent in relation to business to business
          marketing.
        </p>
        <p>
          We will keep your data safely and securely for as long as necessary or
          according to your instructions. If you'd like more information about
          how we store your data, please contact us directly.
        </p>
        <h3 className="mt-5 mb-4">Use of Cookies?</h3>
        <p>
          TravelCarma uses cookies and other third party tracking technologies
          in our websites and across other websites that help us collect other
          information about our customers' and users' preferences. You can
          choose to permit or restrict the use of these technologies through
          your personal browser settings. TravelCarma Cookies do not contain any
          personally identifying information
        </p>
        <h3 className="mt-5 mb-4">Third Party Data?</h3>
        <p>
          TravelCarma may receive data about organizations, industries, website
          visitors, marketing campaigns and other matters related to our
          business from our partners, affiliates or others, that we use to make
          our own information better or more useful. This data may be combined
          with other information we collect: for example, how well an online
          marketing or email campaign performed.
        </p>
        <h3 className="mt-5 mb-4">How do we secure your data?</h3>
        <p>
          Personal data will be safeguarded from unauthorized use, disclosure,
          destruction and alteration by physical, technical and organizational
          security measures that are appropriate to the risks, such as
          accidental loss or damage or unauthorized access, presented by the
          processing.
        </p>
        <h3 className="mt-5 mb-4">
          Transfer of information and Third Party Processing
        </h3>
        <p>Data will only be passed onto a third party at your request.</p>
        <h3 className="mt-5 mb-4">
          Additional information provided to TravelCarma?
        </h3>
        <p>
          We may receive and store other information when submitted to our
          websites or if you participate in a focus group, contest, activity or
          event, apply for a job, request support, interact with our social
          media accounts or otherwise communicate with TravelCarma.
        </p>
        <h3 className="mt-5 mb-4">
          Statement regarding information from minors
        </h3>
        <p>
          Our services are not intended for use by minors. We do not knowingly
          collect personal information from any minor.
        </p>
        <h3 className="mt-5 mb-4">
          Your rights over the personal data we hold
        </h3>
        <p>
          You can reasonably request access to the personal data we hold on you,
          at any time, and we will provide that information free of charge
          within one month of your request at the latest.
        </p>
        <p>
          You can request inaccurate or incomplete personal data held on you to
          be rectified or completed or for your personal data to be suppressed
          or erased, and we will respond within one month of your request at the
          latest.
        </p>
        <h3 className="mt-5 mb-4">Your right to object</h3>
        <p>
          You have the right to opt out of our direct marketing at any point,
          and we will deal with your request right away.
        </p>
        <p>
          To opt out of our direct mail or telephone marketing please contact
          us.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
