import React from "react";
import ViatorLogo1 from "../assets/images/suppliers/Viator_Logo_1.png";
import HotelbedsLogo3 from "../assets/images/suppliers/Hotelbeds_Logo_3.png";
import GTALogo4 from "../assets/images/suppliers/GTA_Logo_4.png";
import TouricoLogo5 from "../assets/images/suppliers/Tourico_Logo_5.png";

const SuppliersActivity = () => {
  return (
    <ul className="suppliers-logos mt-5">
      <li>
        <img src={ViatorLogo1} alt="Viator" />
      </li>
      <li>
        <img src={HotelbedsLogo3} alt="Hotelbeds" />
      </li>
      <li>
        <img src={GTALogo4} alt="GTA" />
      </li>
      <li>
        <img src={TouricoLogo5} alt="Tourico" />
      </li>
    </ul>
  );
};

export default SuppliersActivity;
