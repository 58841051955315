import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import ImageSlider from "../components/image-slider";

const ProductsActivityBookingEngine = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="activity-booking-engine" />
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-6 text-center">
            <ImageSlider slider="ABS1" />
          </div>
          <div className="col-lg-6 text-justify">
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              With <strong>online activity bookings</strong> on the rise, you
              can't ignore the importance of an activity booking engine. More
              and more travelers are choosing to book excursions online, making{" "}
              <strong>TravelCarma's Activity Reservation System</strong> an
              essential solution for activity providers and agencies looking to
              grow their excursions revenue and offer clients a wide range of
              local activities and experiences under one roof.
            </p>
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              The activity system can be used by itself, or in conjunction with
              a{" "}
              <Link
                to="/products/booking-engines/hotel-booking-engine"
                className="text-decoration-none text-primary"
              >
                hotel booking engine
              </Link>{" "}
              and{" "}
              <Link
                className="text-decoration-none text-primary"
                to="/products/booking-engines/flight-booking-engine"
              >
                flight booking system
              </Link>
              , making it great cross-sell opportunity as you can offer local
              activities to people who use your portal to book a hotel/flight
              for a particular destination.
            </p>

            <Link to="/contact-us" className="btn btn-primary mt-3">
              Request a Demo
            </Link>
          </div>
        </div>
      </div>

      <div className="benefits-section-bg">
        <div className="container">
          <div className="row">
            <h2 className="text-center mt-5">
              Benefits of Activity Booking Engine
            </h2>
            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>Available on both web and
                  mobile (responsive and native app)
                </li>
                <li>
                  <i className="material-icons"></i>Supports multiple sales
                  channels - B2C, B2B, B2B2C, B2E and Corporate
                </li>
                <li>
                  <i className="material-icons"></i>Supports multiple languages
                  and currencies with real-time currency conversion
                </li>
                <li>
                  <i className="material-icons"></i>Aggregation of{" "}
                  <Link
                    to="/suppliers/sightseeing-api-integration"
                    className="text-decoration-none text-primary"
                  >
                    multiple activity XML feeds
                  </Link>{" "}
                  along with directly contracted inventory into a single search
                  interface
                </li>
                <li>
                  <i className="material-icons"></i>Multiple search filters and
                  sorting options including price, currency, activity name,
                  activity type
                </li>
                <li>
                  <i className="material-icons"></i>Ability to display activity
                  description, images, schedule, inclusions and exclusions,
                  terms and conditions
                </li>
                <li>
                  <i className="material-icons"></i>View activities on a map
                </li>
                <li>
                  <i className="material-icons"></i>Ability to add multiple
                  activities to a shopping cart and book them together
                </li>
                <li>
                  <i className="material-icons"></i>Ability to book activities
                  with flight(s) and hotels
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>Ability to set up
                  activities, rates, availability, policies and other details in
                  the activity inventory management system
                </li>
                <li>
                  <i className="material-icons"></i>Ability to show activity
                  promotions and advertisements in search results
                </li>
                <li>
                  <i className="material-icons"></i>Automatic activity voucher
                  generation with your own branding
                </li>
                <li>
                  <i className="material-icons"></i>Ability to store frequent
                  co-traveler details to save time during booking
                </li>
                <li>
                  <i className="material-icons"></i>Login with Facebook and
                  Google
                </li>
                <li>
                  <i className="material-icons"></i>User-friendly interface for
                  centralized customer and booking management
                </li>
                <li>
                  <i className="material-icons"></i>Separate markups and
                  commission setup for B2C and B2B
                </li>
                <li>
                  <i className="material-icons"></i>Extensive booking reports
                  for decision making and accounting reconciliation
                </li>
                <li>
                  <i className="material-icons"></i>Auto cancellation of
                  unconfirmed bookings before the cancellation period starts
                </li>
                <li>
                  <i className="material-icons"></i>Ability to add activities to
                  wishlist and favourites
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsActivityBookingEngine;
