import React from "react";
import PageHeader from "../components/page-header";
import CaseStudyBox from "../components/case-studies-box";

const CaseStudies = () => {
  const caseStudies = [...Array(9).keys()];
  return (
    <div className="page-inside page-ebooks">
      <PageHeader page="case-studies" />
      <div className="container">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-9">
            <ul className="list-unstyled casestudies-box row">
              {caseStudies.map((x, key) => (
                <li key={key} className="col-lg-4 mb-4">
                  <CaseStudyBox id={"CS" + (key + 1)} />
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;
