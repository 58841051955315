import React from "react";
import PageHeader from "../components/page-header";
import BackOffice from "../assets/images/products/back-office-clipart.png";
import IconTravelAgenct from "../assets/images/products/icon-travel-agency.png";
import IconOTAs from "../assets/images/products/icon-otas.png";
import IconTMCs from "../assets/images/products/icon-tmcs.png";
import IconTourOperator from "../assets/images/products/icon-tour-operator.png";
import ImageSlider from "../components/image-slider";
import { Link } from "react-router-dom";

const ProductsBackoffice = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="back-office" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <img
              src={BackOffice}
              alt="TravelCarma Travel Back Office System"
              className="w-100"
              style={{ maxWidth: "412px" }}
            />
          </div>
          <div className="col-lg-6 text-justify">
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              With our back-office system you can manage all your business rules
              based on predefined criteria and automate routine tasks that take
              up a lot of time. It also helps you monitor your business through
              automatic accounting reconciliation and BI reports.
            </p>

            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              Our back-office solution works seamlessly across both web and
              mobile devices, allowing you to manage your business remotely. It
              is 100% cloud-based which means all your data is stored securely
              online and can be accessed from anywhere, anytime.
            </p>

            <Link to="/contact-us" className="btn btn-primary mt-3">
              Request a Demo
            </Link>
          </div>
        </div>

        <h2 className="text-center mt-5" style={{ marginBottom: "48px" }}>
          Who is it for
        </h2>
        <ul className="shadow-box list-unstyled row">
          <li className="col-lg-3">
            <div className="text-center">
              <img
                className="mt-3"
                src={IconTravelAgenct}
                alt="Travel Agencies"
              />
              <h3 className="mb-0" style={{ border: "0px none" }}>
                Travel Agencies
              </h3>
            </div>
          </li>
          <li className="col-lg-3">
            <div className="text-center">
              <img className="mt-3" src={IconOTAs} alt="Travel Agencies" />
              <h3 className="mb-0" style={{ border: "0px none" }}>
                OTAs
              </h3>
            </div>
          </li>
          <li className="col-lg-3">
            <div className="text-center">
              <img className="mt-3" src={IconTMCs} alt="Travel Agencies" />
              <h3 className="mb-0" style={{ border: "0px none" }}>
                TMCs
              </h3>
            </div>
          </li>
          <li className="col-lg-3">
            <div className="text-center">
              <img
                className="mt-3"
                src={IconTourOperator}
                alt="Travel Agencies"
              />
              <h3 className="mb-0" style={{ border: "0px none" }}>
                Tour Operators
              </h3>
            </div>
          </li>
        </ul>
        <br />
        <h2 className="text-center mt-5" style={{ marginBottom: "48px" }}>
          USPs of our Enterprise Back-office Solution
        </h2>
        <ul className="shadow-box list-unstyled row">
          <li className="col-lg-6 mb-4">
            <div className="text-center">
              <h3 className="text-primary">Standalone solution</h3>
              <p className="text-secondary">
                Our solution works with any booking platform. You just need to
                import your booking data via Excel/CSV files or automatically
                via APIs
              </p>
            </div>
          </li>
          <li className="col-lg-6 mb-4">
            <div className="text-center">
              <h3 className="text-primary">
                Cloud-based for easy accessibility
              </h3>
              <p className="text-secondary">
                Unlike a lot of other solutions, our back-office system doesn’t
                require installation on local machines/servers. It can be
                accessed 24x7 and used for multiple divisions/locations
              </p>
            </div>
          </li>
          <li className="col-lg-6">
            <div className="text-center">
              <h3 className="text-primary">
                Benefits of SaaS with the Flexibility of Enterprise
              </h3>
              <p className="text-secondary">
                Our SaaS model allows you to enjoy 99.99% uptime, zero
                maintenance & free upgrades, but with customization & database
                separation for greater control
              </p>
            </div>
          </li>
          <li className="col-lg-6">
            <div className="text-center">
              <h3 className="text-primary">Automatic BSP Reconciliation</h3>
              <p className="text-secondary">
                Our solution can download & convert data files from your GDS(s)
                and compare them with BSP reports to generate one reconciled
                file for a faster, error-free process
              </p>
            </div>
          </li>
        </ul>
        <br />

        <div className="row text-center benefits-section mt-5">
          <div className="col-lg-6">
            <h2>Manage all your reservations in one place</h2>
            <ul>
              <li>
                Centralized tracking of all your online and offline bookings
              </li>
              <li>
                Integration with front-office solutions via file transfer or API
              </li>
              <li>
                Configuration of travel documents such as quotes, vouchers,
                e-tickets
              </li>
              <li>
                Auto-generation of invoices with fully-customizable templates
              </li>
              <li>
                Facility for your B2C, B2B and Corporate customers to manage
                their own bookings &amp; payments
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
          <div className="col-lg-6 d-flex">
            <ImageSlider slider="BOS1" />
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6 d-flex">
            <ImageSlider slider="BOS2" />
          </div>
          <div className="col-lg-6">
            <h2>Set up custom business rules</h2>
            <ul>
              <li>
                Set up B2C customers, sub-agents, corporate clients &amp;
                suppliers with advanced business rules &amp; policies for each
              </li>
              <li>
                Configuration of various types of markups and commissions, based
                on product type(hotel, air, activity etc), region, portal etc
              </li>
              <li>
                Ability to classify agents and assign unique markups to each
                agent class
              </li>
              <li>Configure individual PCC for each corporate client</li>
              <li>
                Management of local taxes and fees applicable on the bookings
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6">
            <h2>Know and look after your customers better</h2>
            <ul>
              <li>
                Unique ability for B2C, B2B and corporate clients to make online
                payments against their invoices
              </li>
              <li>
                Ability to setup and manage 'n' number of B2C/B2B clients within
                the system
              </li>
              <li>
                Classification of B2C customers for specific booking promotions
                and rates
              </li>
              <li>
                Management of credit limits and deposits for B2B customers
                (sub-agents)
              </li>
              <li>Integration with your existing CRM system</li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
          <div className="col-lg-6 d-flex">
            <ImageSlider slider="BOS3" />
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6 d-flex">
            <ImageSlider slider="BOS4" />
          </div>
          <div className="col-lg-6">
            <h2>Create attractive marketing offers and campaigns</h2>
            <ul>
              <li>Configuration of promo codes and discounts</li>
              <li>
                Personalized emails with their own branding, unlike a white
                label
              </li>
              <li>
                Integration with third-party email marketing tools for running
                email campaigns
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6">
            <h2>Automate your Accounting & Reconciliation</h2>
            <ul>
              <li>
                Automated BSP/ARC reconciliation for multiple regions (file
                downloads, uploads from GDS &amp; BSP, transformations etc)
              </li>
              <li>Customer and supplier reconciliation (including LCCs)</li>
              <li>
                Automatically push the reconciled data into your existing
                accounting system for invoicing
              </li>
              <li>
                Customer and supplier ledgers for both online and offline
                bookings
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
          <div className="col-lg-6 d-flex">
            <ImageSlider slider="BOS5" />
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6 d-flex">
            <ImageSlider slider="BOS6" />
          </div>
          <div className="col-lg-6">
            <h2>Track your Business Performance</h2>
            <ul>
              <li>
                Track your sales, commissions and sub-agent performance with a
                customizable reporting module
              </li>
              <li>
                Get consolidated as well as regional reports for both online
                bookings as well as bookings made by your employees/B2B agents
                offline
              </li>
              <li>
                Ability to customize the reports in any way you want through our
                unique front-end APIs
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
        </div>

        <div className="section-separator mt-5"></div>

        <h2 className="text-center">Top Benefits for your Business</h2>

        <ul className="product-highlights list-unstyled p-0 m-0 row">
          <li className="col-lg-6 d-flex align-items-center">
            <figure>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 25 25"
              >
                <path
                  d="M19 5v14H5V5h14m1.1-2H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM11 7h6v2h-6V7zm0 4h6v2h-6v-2zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7z"
                  fill="#444444"
                ></path>
                <path fill="none" d="M0 0h24v24H0z"></path>
              </svg>
            </figure>

            <h4 className="mb-0">Eliminates costly human errors</h4>
          </li>
          <li className="col-lg-6 d-flex align-items-center">
            <figure>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 25 25"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
                  fill="#444444"
                ></path>
              </svg>
            </figure>
            <h4 className="mb-0">
              Improves staff efficiency &amp; productivity
            </h4>
          </li>
          <li className="col-lg-6 d-flex align-items-center">
            <figure>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="dollar-sign"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 288 512"
                className="svg-inline--fa fa-dollar-sign fa-w-9 fa-g"
                style={{ width: "20px" }}
              >
                <path
                  fill="currentColor"
                  d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z"
                  className=""
                ></path>
              </svg>
            </figure>
            <h4 className="mb-0">Allows you to focus on selling more</h4>
          </li>
          <li className="col-lg-6 d-flex align-items-center">
            <figure>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 20 20"
              >
                <path
                  d="M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z"
                  fill="#444444"
                ></path>
                <path fill="none" d="M0 0h24v24H0z"></path>
              </svg>
            </figure>
            <h4 className="mb-0">Helps you make better business decisions</h4>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProductsBackoffice;
