import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import InfographicsBox from "../components/infographics-box";

const ResourcesInfographics = () => {
  const travelTechArr = [...Array(80).keys()];
  return (
    <div className="page-inside page-infographics">
      <PageHeader page="infographics" />
      <div className="container">
        <ul className="list-unstyled infographics-box">
          {travelTechArr.map((x, key) => (
            <li key={key}>
              <InfographicsBox id={"TravelTech" + (key + 1)} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ResourcesInfographics;
