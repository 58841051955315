import React from "react";
import { Link } from "react-router-dom";
import Ebrochure from "../assets/resources/TravelCarma_ebrochure.pdf";
import COVIDSurveyReport from "../assets/resources/COVID-Survey-Report.pdf";

const Menu = () => {
  return (
    <div className="navbar">
      <ul className="list-unstyled p-0 m-0 d-flex align-items-center">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link style={{ cursor: "default" }}>Company</Link>
          <ul className="row list-unstyled">
            <li className="p-0">
              <Link to="/company/about-travelcarma">About Us</Link>
            </li>
            <li className="p-0">
              <Link to="/company/leadership-team">Leadership Team</Link>
            </li>
            <li className="p-0">
              <Link to="/company/why-us">Why Us</Link>
            </li>
            <li className="p-0">
              <Link to="/company/awards">Travel Awards</Link>
            </li>
            <li className="p-0">
              <Link to="/company/events">Events</Link>
            </li>
            <li className="p-0">
              <Link to="/company/news">News</Link>
            </li>
            {/* <li className="p-0">
              <Link to="/">Partner Program</Link>
            </li>
            <li className="p-0">
              <Link to="/">Careers</Link>
            </li> */}
          </ul>
        </li>
        <li>
          <Link style={{ cursor: "default" }}>Products</Link>
          <ul className="row list-unstyled">
            <li className="p-0">
              <Link to="/products/booking-engines/flight-booking-engine">
                Flight Booking Engine
              </Link>
            </li>
            <li className="p-0">
              <Link to="/products/booking-engines/hotel-booking-engine">
                Hotel Booking Engine
              </Link>
            </li>
            <li className="p-0">
              <Link to="/products/booking-engines/car-booking-engine">
                Car Booking Engine
              </Link>
            </li>
            <li className="p-0">
              <Link to="/products/booking-engines/activity-booking-engine">
                Activity Booking Engine
              </Link>
            </li>
            <li className="p-0">
              <Link to="/products/booking-engines/transfers-booking-engine">
                Transfers Booking Engine
              </Link>
            </li>
            <li className="p-0">
              <Link to="/products/inventory-management-system">
                Inventory Management System
              </Link>
            </li>
            <li className="p-0">
              <Link to="/products/back-office">Back Office</Link>
            </li>
            <li className="p-0">
              <Link to="/products/b2b-booking-system">B2B Booking System</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link style={{ cursor: "default" }}>Solutions</Link>
          <ul className="row list-unstyled">
            <li className="p-0">
              <Link to="/solutions/enterprise-software-for-large-travel-companies">
                Enterprise Software
              </Link>
            </li>
            <li className="p-0">
              <Link to="/solutions/travel-agency-software">Travel Agency</Link>
            </li>
            <li className="p-0">
              <Link to="/solutions/dmc-software">DMC</Link>
            </li>
            <li className="p-0">
              <Link to="/solutions/host-agency-solution">
                Host Agency Solution
              </Link>
            </li>
            <li className="p-0">
              <Link to="/solutions/bus-operator">Bus Operator</Link>
            </li>
            <li className="p-0">
              <Link to="/solutions/travel-data-xchange-switch">
                Travel Data Xchange Switch
              </Link>
            </li>
            <li className="p-0">
              <Link to="/solutions/hajj-umrah-booking-solution">
                Hajj Umrah Booking Solution
              </Link>
            </li>
            <li className="p-0">
              <Link to="/solutions/inventory-distribution">
                Inventory Distribution
              </Link>
            </li>
            <li className="p-0">
              <Link to="/solutions/development-api">Development API</Link>
            </li>
            <li className="p-0">
              <Link to="/solutions/mobile-travel-apps">Mobile Travel Apps</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link style={{ cursor: "default" }}>Customers</Link>
          <ul className="row list-unstyled">
            <li className="p-0">
              <Link to="/customers">Customers</Link>
            </li>
            <li className="p-0">
              <Link to="/customer-testimonials">Testimonials</Link>
            </li>
            <li className="p-0">
              <Link to="/case-studies">Case Studies</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link style={{ cursor: "default" }}>Suppliers</Link>
          <ul className="row list-unstyled">
            <li className="p-0">
              <Link to="/suppliers/travel-xml-api-integrations">
                Travel API Integration
              </Link>
            </li>

            <li className="p-0">
              <Link to="/suppliers/flight-api-integration">
                Flight API Integration
              </Link>
            </li>
            <li className="p-0">
              <Link to="/suppliers/hotel-api-integration">
                Hotel API Integration
              </Link>
            </li>
            <li className="p-0">
              <Link to="/suppliers/sightseeing-api-integration">
                Activities API Integration
              </Link>
            </li>
            <li className="p-0">
              <Link to="/suppliers/transfer-api-integration">
                Car Rental API Integration
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link style={{ cursor: "default" }}>Resources</Link>
          <ul className="row list-unstyled">
            <li className="p-0">
              <Link to="https://blog.travelcarma.com" target="_blank">
                Blog
              </Link>
            </li>
            <li className="p-0">
              <Link to="/resources/ebooks">Ebooks</Link>
            </li>
            <li className="p-0">
              <Link to="/resources/infographics">Infographics</Link>
            </li>
            <li className="p-0">
              <Link to={Ebrochure} target="_blank">
                Ebrochure
              </Link>
            </li>
            <li className="p-0">
              <Link
                to="https://beta.travelcarma.com/TravelCarma-Corporate-Overview/TravelCarma-Corporate-Overview.html"
                target="_blank"
              >
                Corporate Presentation
              </Link>
            </li>
            <li className="p-0">
              <Link to={COVIDSurveyReport} target="_blank">
                COVID Survey Report
              </Link>
            </li>
            <li className="p-0">
              <Link to="/resources/videos">Videos</Link>
            </li>
            <li className="p-0">
              <Link to="/resources/faqs">FAQs</Link>
            </li>
          </ul>
        </li>
        {/* <li>
          <Link to="/">Pricing Models</Link>
        </li> */}
        <li>
          <Link
            className="btn btn-sm btn-primary text-white ms-2"
            style={{ padding: "6px 8px", fontWeight: "600" }}
            to="/contact-us"
          >
            Contact Us
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
