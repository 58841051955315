import React from "react";
import PageHeader from "../components/page-header";
import AboutUs from "../assets/images/travelcarma-about-us.png";
import Milestone from "../assets/images/travelcarma-milestones.png";
import Ebrochure from "../assets/images/travelcarma-ebrochure.jpg";
import EbrochurePDF from "../assets/resources/TravelCarma_ebrochure.pdf";
import { Link } from "react-router-dom";

const CompanyAboutUs = () => {
  return (
    <div className="page-inside">
      <PageHeader page="about-travelcarma" />

      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <img
              src={AboutUs}
              alt="About Us"
              title="About Us"
              className="w-100 mb-3"
              style={{ maxWidth: "450px" }}
            />
          </div>
          <div className="col-lg-6 text-justify">
            <p>
              <strong>TravelCarma</strong> is a global{" "}
              <strong>travel technology</strong> brand owned by Avani Cimcon
              Technologies Ltd, a technology company with over 20 years of
              software delivery experience. We have helped{" "}
              <strong>tourism companies</strong> worldwide achieve a significant
              online presence by providing state-of-the-art, mobile enabled
              e-commerce platforms with their own branding, diverse content and
              access to global inventory.
            </p>
            <p>
              TravelCarma is promoted and managed by highly skilled and
              experienced technocrats. The Founder and Managing Director is a
              technology strategist, agile manager and entrepreneurial innovator
              skilled at managing international relationships, with 20 years of
              IT outsourcing industry experience.
            </p>
            <p>
              TravelCarma's comprehensive suite of travel technology solutions
              is powering more than 200 portals in over 50 countries. Our system
              is available in multiple currencies and languages (including
              Chinese, Vietnamese, Arabic, Spanish, Portuguese, French and
              Persian).
            </p>
          </div>
        </div>

        <div>
          <div
            className="shadow-sm p-4 mt-5 rounded"
            style={{ border: "solid 1px rgba(0,0,0,0.05)" }}
          >
            <h5 className="text-primary mb-3">
              TravelCarma caters to end-to-end IT needs of all the businesses in
              the travel Eco-system, including:
            </h5>
            <ul className="row">
              <li className="col-lg-6 mt-2">
                Offline and Online Travel Agencies (B2C, B2B, B2B2C)
              </li>
              <li className="col-lg-6 mt-2">
                Travel Wholesalers / Aggregators / Consolidators / Distributors
              </li>
              <li className="col-lg-6 mt-2">
                Tour Operators / Excursion and Activities Providers
              </li>
              <li className="col-lg-6 mt-2">
                Destination Management Companies / Tourism Associations
              </li>
              <li className="col-lg-6 mt-2">
                Accommodations (Hotels Chains, Resorts, Apartments, Villas,
                Vacation Rentals, Timeshare etc.)
              </li>
              <li className="col-lg-6 mt-2">
                Ground Transportation (Bus/Coach, Rental Cars, Limo, Taxi etc.)
              </li>
            </ul>
          </div>
          <p className="mt-4">
            TravelCarma is now a recognized brand in the business of{" "}
            <strong>travel technology</strong> and has received numerous awards
            and accolades, including “One of the Top ten SaaS applications"​
            from NASSCOM in 2011. Its core technology earned the company a place
            in "top 100 IT innovators"​ in India and was selected to showcase
            its Innovations at PhocusWright’s{" "}
            <strong>“Travel Innovation Summit”</strong> in the United States.
          </p>

          <p>
            We have a clear vision and mission which is to become the largest
            and the most preferred{" "}
            <strong>online travel technology provider</strong> in the world.
          </p>
        </div>

        <div className="text-center mt-5">
          <h2 className="mb-5">TravelCarma Milestones</h2>
          <img
            src={Milestone}
            alt="TravelCarma Milestones"
            title="TravelCarma Milestones"
            className="w-100 shadow-sm rounded p-5"
            style={{ border: "solid 1px rgba(0,0,0,0.05)" }}
          />
        </div>

        <div className="text-center mt-5">
          <h2 className="mb-5">E-brochure</h2>
          <div>
            <img
              src={Ebrochure}
              alt="TravelCarma eBroucher"
              title="TravelCarma eBroucher"
              className="shadow-sm rounded"
              style={{ border: "solid 1px rgba(0,0,0,0.05)", width: "50%" }}
            />
          </div>
          <Link
            to={EbrochurePDF}
            target="_blank"
            className="btn btn-primary mt-4"
          >
            Click to Download Brochure
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CompanyAboutUs;
