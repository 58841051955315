import React from "react";
import PageHeader from "../components/page-header";

const SolutionsTravelTechConsulting = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="travel-tech-consulting" />
      <div className="container">
        <div>
          <p>
            In addition to providing software solutions, TravelCarma also offers
            consulting services relating to various aspects of travel &amp;
            technology, including distribution, operations, sales &amp;
            marketing and digital transformation. We have over 25 years of
            experience in providing software development and consulting IT
            services to clients in the travel domain.
          </p>
          <p>
            We utilize our domain expertise and proven technologies to solve our
            clients' problems and help them achieve their business goals. Our
            experience and development capabilities help create a robust digital
            transformation framework for our clients to modernize their IT
            systems and optimize key business processes. Having worked closely
            with travel companies from various parts of the world, we understand
            the specific needs and challenges faced by companies of all types
            and sizes in each region.
          </p>
          <p>
            We have strong relationships with XML API suppliers and a thorough
            understanding of the processes that are involved in contracting,
            development and certification. Our extensive supplier integration
            experience allows us to recommend our clients the right suppliers in
            each region to get the best possible inventory for their B2C and B2B
            customers.
          </p>
          <p>
            <strong>
              Looking at the current situation, we're offering personalized
              digital transformation consultations to help travel businesses get
              ready for the post COVID-19 world. Just fill out the form to get
              started.
            </strong>
          </p>

          <div className="shadow rounded p-4 mt-5">
            <h3 className="text-primary mb-4">
              Our Travel Tech Consulting Framework:
            </h3>
            <ul>
              <li className="mb-3">
                Holistic analysis of your business processes before kick-off
              </li>
              <li className="mb-3">
                Identification of areas of improvement and strategic changes
                required
              </li>
              <li className="mb-3">
                Assistance with building a clear and effective road map aligned
                with your goals
              </li>
              <li className="mb-3">
                Hands-on guidance during development and roll out
              </li>
              <li>
                Active engagement after going live with personalized workshops
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsTravelTechConsulting;
