import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";

const ResourcesFaqs = () => {
  return (
    <div className="page-inside page-faqs">
      <PageHeader page="faqs" />
      <div className="container">
        <div>
          <h5 className="text-primary">
            <i className="material-icons"></i>What type of businesses are
            TravelCarma solutions designed for?
          </h5>
          <p>
            TravelCarma solutions are built for mid-sized and Large B2C/B2B
            Agencies, OTAs, and Wholesalers and DMCs having either negotiated
            rates or third-party XML APIs and at least 200 monthly bookings.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>Why should I choose TravelCarma?
          </h5>
          <p>
            TravelCarma offers a robust, modular and scalable platform that has
            a wide range of ready-to-deploy travel functionalities plus seamless
            integration with third-party/legacy systems for short time-to-market
            and ability to handle a large volume of transactions.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>Which kind of travel agency
            software is better: off-the-shelf or custom?
          </h5>
          <p>
            It depends on a number of factors such as the size of your business,
            feature requirements, budget and IT infrastructure.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>How is TravelCarma platform
            different from white label solutions in the market?
          </h5>
          <p>
            Unlike a white label which merely provides search and book
            functionality for customers, TravelCarma's booking platform is an
            end-to-end system with <Link to="">ERP capabilities</Link> like
            inventory management, booking management, agency management,
            back-office, accounting, reports and so on.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>Will I be able to integrate my
            existing systems with your platform?
          </h5>
          <p>
            Of course! TravelCarma's platform is based on Services Oriented
            Architecture, allowing you to integrate your existing cloud systems
            like accounting, CRM and marketing applications. It also integrates
            with your legacy systems built on COBOL, IBM etc.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>How can I book a demo of the
            software?
          </h5>
          <p>
            You can schedule a demo by <Link to="">filling out this form</Link>{" "}
            or getting in touch with us on{" "}
            <a href="mailto:sales@travelcarma.com"> sales@travelcarma.com</a>
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>Does your software work on mobile?
          </h5>
          <p>
            Yes, all our solutions are mobile responsive. We also provide mobile
            apps for Android and iOS.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>Do I need technical knowledge to
            use your software?
          </h5>
          <p>
            Not necessarily. We provide fully responsive themes and a CMS to
            help you easily manage your portal. However, if you need additional
            functionalities or want to make drastic changes to the UI/UX, you
            would need a development team or get it done through us.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>Is your software customizable?
          </h5>
          <p>
            Our software has all the features and booking workflows a typical
            travel agency would require. However, if you have specific workflows
            or UI/UX requirements, they can be customized at an additional cost.
            We also provide development APIs and SDKs that your developers you
            can use to build your own front-ends.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>What payment modes does your
            booking platform support?
          </h5>
          <p>
            Our booking platform supports multiple payment modes - credit &amp;
            debit cards, net banking, agent balance (in B2B), digital wallets
            and reward points. We also offer a unique 'book-now-pay-later' mode
            which allows you to hold bookings for your customers (within the
            cancellation period) and collect the payment from them later in cash
            or any other form.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>Can I have my own branding?
          </h5>
          <p>
            Absolutely! You will have your own branding on your portal, admin
            system, emails, quotes, vouchers and invoices. Even your sub-agents
            can have their own branding if you provide them a B2B2C portal.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>How can TravelCarma help us in
            scaling our business?
          </h5>
          <p>
            TravelCarma offers a wide range of solutions that can help you
            expand your sales &amp; marketing channels, reach more clients,
            generate more bookings, seamlessly integrate third-party systems to
            augment your offerings and build integrated workflows, as well as
            roll out new products quickly.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>We are an offline business. How
            quickly can we go online with your product?
          </h5>
          <p>
            Assuming you have your API and direct contracts in place, you can go
            live within 3-4 weeks.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>Does your system have basic
            accounting and CRM modules?
          </h5>
          <p>
            Yes, we provide a finance module with ledger reports to track
            accounts payable and receivables. We also provide a customer
            management module which gives you &amp; your employees access to
            details of all your registered customers and their bookings from a
            central interface. Our platform can also integrate with your own CRM
            and Accounting systems seamlessly with custom workflows.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>How do you manage the security of
            our data on your system?
          </h5>
          <p>
            Our platform is hosted on Amazon Web Services (AWS), which is built
            on an extremely secure cloud infrastructure offering the highest
            standards of privacy and security. We also provide in-built firewall
            security, not to mention that our databases are accessible only
            through web services.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>What sort of a technology stack do
            you use for your products?
          </h5>
          <p>
            Our technology stack includes but isn't limited to .Net, Jquery, MS
            SQL, Java, HTML, React, XML/JSON, AWS hosting.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>How quickly can we launch our
            business on your platform if we buy your system as is?
          </h5>
          <p>
            Unless there are customizations or new integrations/certification,
            we can set up your system in 1-2 weeks.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>How many agents or agencies can we
            manage on your back office systems?
          </h5>
          <p>
            You can set up 'n' number of agents on our B2B platform and manage
            them centrally.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>What kind of ROI can I expect from
            your product?
          </h5>
          <p>
            Please use the free{" "}
            <Link href="/resources/roi-calculator.htm" target="_blank">
              ROI calculator
            </Link>{" "}
            on our website to get an estimate of the savings and additional
            revenue you can generate using our platform.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>Can I have my own payment gateway
            integrated?
          </h5>
          <p>
            Yes, we can integrate your own payment gateways. We have already
            integrated over 30 global payment gateways.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>Do you provide inventory?
          </h5>
          <p>
            No, we don't provide inventory as we're a pure technology provider.
            You need to have your own API contracts or negotiated rates with
            suppliers which we can integrate into our platform.
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>I have my direct contracts. How do
            I manage them?
          </h5>
          <p>
            We can provide you with inventory systems (CRS) for managing your
            direct contracts. We offer CRSs for hotels, activities/sightseeing,
            car rentals and fixed packages. You can also redistribute your
            directly contracted inventory to third-parties using our{" "}
            <a
              href="/EnterpriseSolutions/InventoryDistribution.aspx"
              target="_blank"
            >
              distribution platform
            </a>
            .
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>Can I integrate local LCCs?
          </h5>
          <p>
            Yes you can. We have integrated over{" "}
            <Link to="">130 LCCs worldwide.</Link>
          </p>
          <h5 className="text-primary">
            <i className="material-icons"></i>Can I use my existing website/CMS?
          </h5>
          <p>
            Of course! We can integrate our products into your own website/CMS
            and even customize the look-and-feel of the booking engine to match
            your website.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResourcesFaqs;
