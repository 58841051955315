import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import SuppliersFlight from "../components/suppliers-flight";
import LowCostAirlines from "../assets/resources/Low-Cost-Airlines-Integrated-by-TravelCarma.pdf";

const ApiFlightIntegration = () => {
  return (
    <div>
      <PageHeader page="flight-api-integration" />
      <div className="container">
        <div className="text-center">
          <p>
            TravelCarma provides <b>Flight API integration</b> with all the
            major flight suppliers. We have integrated{" "}
            <b>Flight APIs for GDSs</b> like Amadeus, Sabre and TravelPort as
            well as Flight Consolidators like Mystifly, Iati and Multireisen.
            With TravelCarma you can integrate multiple air suppliers into a
            single{" "}
            <Link
              to="/products/booking-engines/flight-booking-engine"
              className="text-decoration-none text-primary"
            >
              flight booking engine.
            </Link>
          </p>
          <p>
            By integrating Flight APIs into your online travel portal, you can
            provide your B2C customers and agents the ability to book flight
            tickets online using real-time fares and availability. Flight API
            integration also eliminates the hassle of logging into multiple
            supplier systems to get the best fares for your clients, as global
            flight content is available on a single interface.
          </p>
          <p>
            We have also integrated over{" "}
            <Link
              to={LowCostAirlines}
              className="text-decoration-none text-primary"
              target="_blank"
            >
              130 Low Cost Airlines
            </Link>{" "}
            globally to allow you to sell <b>LCC content</b> along with full
            service carriers.
          </p>

          <SuppliersFlight />
        </div>
      </div>
    </div>
  );
};

export default ApiFlightIntegration;
