import React from "react";
import { Link } from "react-router-dom";
import IconWhyUs1 from "../assets/images/icon-whyus-1.png";
import IconWhyUs2 from "../assets/images/icon-whyus-2.png";
import IconWhyUs3 from "../assets/images/icon-whyus-3.png";
import IconWhyUs4 from "../assets/images/icon-whyus-4.png";
import IconWhyUs5 from "../assets/images/icon-whyus-5.png";
import IconWhyUs6 from "../assets/images/icon-whyus-6.png";

const HomeWhatDifferent = () => {
  return (
    <div className="home-what-different mt-5">
      <div className=" container">
        <div className="text-center position-relative" style={{ zIndex: "2" }}>
          <h2 className="text-center mb-5 text-white">
            What Makes Us Different
          </h2>

          <div className="row text-center">
            <div className="col-lg-4 mb-4">
              <div className="home-what-different-box">
                <img src={IconWhyUs1} alt="Domain Expertise icon" />
                <h4>
                  Domain
                  <br />
                  Expertise
                </h4>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="home-what-different-box">
                <img src={IconWhyUs2} alt="Flexibility icon" />
                <h4>
                  Scalable
                  <br />
                  Solutions
                </h4>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="home-what-different-box">
                <img src={IconWhyUs3} alt="channel management icon" />
                <h4>Strong Channel Management Capabilities</h4>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="home-what-different-box">
                <img src={IconWhyUs4} alt="supplier integration icon" />
                <h4>Supplier Integration Expertise</h4>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="home-what-different-box">
                <img src={IconWhyUs5} alt="time icon" />
                <h4>
                  Fast
                  <br />
                  Time-to-Market
                </h4>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="home-what-different-box">
                <img src={IconWhyUs6} alt="technology icon" />
                <h4>Integrates with your Existing Technology</h4>
              </div>
            </div>
          </div>
          <Link to="/company/why-us" className="btn btn-primary mt-2">
            Know More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeWhatDifferent;
