import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import ImageSlider from "../components/image-slider";

const ProductsCarBookingEngine = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="car-booking-engine" />
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-6 text-center">
            <ImageSlider slider="CBS1" />
          </div>
          <div className="col-lg-6 text-justify">
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              <strong>TravelCarma</strong> offers an end-to-end{" "}
              <strong>car rental booking engine</strong> which allows rental car
              providers as well as agencies to sell rental cars online through
              various business models such as B2C, B2B and B2E.
            </p>

            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              The <strong>car booking engine</strong> is platform agnostic and
              can be easily integrated into your existing website. It can be
              integrated with{" "}
              <Link
                to="/suppliers/transfer-api-integration"
                className="text-decoration-none text-primary"
              >
                multiple XML Car suppliers
              </Link>{" "}
              and can also fetch your own inventory uploaded in our car
              inventory management system.
            </p>

            <Link to="/contact-us" className="btn btn-primary mt-3">
              Request a Demo
            </Link>
          </div>
        </div>
      </div>

      <div className="benefits-section-bg">
        <div className="container">
          <div className="row">
            <h2 className="text-center mt-5">
              Benefits of Our Car Booking Engine
            </h2>
            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>Available on both web and
                  mobile (responsive and native app)
                </li>
                <li>
                  <i className="material-icons"></i>Supports multiple sales
                  channels - B2C, B2B, B2B2C, B2E and Corporate
                </li>
                <li>
                  <i className="material-icons"></i>Supports multiple languages
                  and currencies with real-time currency conversion
                </li>
                <li>
                  <i className="material-icons"></i>Aggregation of multiple{" "}
                  <strong>car rental XML feeds</strong> along with directly
                  contracted inventory into a single search interface
                </li>
                <li>
                  <i className="material-icons"></i>Advanced Search and Filter
                  options
                </li>
                <li>
                  <i className="material-icons"></i>Customer registration and
                  management
                </li>
                <li>
                  <i className="material-icons"></i>Ability to set up vehicle
                  types, rates, availability, promotions and other details in
                  the vehicle inventory management system
                </li>
                <li>
                  <i className="material-icons"></i>Instant confirmation,
                  modification and cancellation of bookings
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>Shopping cart to book cars
                  along with flights, hotels and other products together
                </li>
                <li>
                  <i className="material-icons"></i>Automatic activity voucher
                  generation with your own branding
                </li>
                <li>
                  <i className="material-icons"></i>Ability to store frequent
                  co-traveler details to save time during booking
                </li>
                <li>
                  <i className="material-icons"></i>Login with Facebook and
                  Google
                </li>
                <li>
                  <i className="material-icons"></i>User-friendly interface for
                  centralized customer and booking management
                </li>
                <li>
                  <i className="material-icons"></i>Separate markups and
                  commission setup for B2C and B2B
                </li>
                <li>
                  <i className="material-icons"></i>Extensive booking reports
                  for decision making and accounting reconciliation
                </li>
                <li>
                  <i className="material-icons"></i>Auto cancellation of
                  unconfirmed bookings before the cancellation period starts
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsCarBookingEngine;
