import React from "react";
import PageHeader from "../components/page-header";
import FlightBookingEngineClip from "../assets/images/products/flight-booking-engine-clipart.png";
import ImageSlider from "../components/image-slider";
import { Link } from "react-router-dom";

const ProductsFlightBookingEngine = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="flight-booking-engine" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <img
              src={FlightBookingEngineClip}
              alt="TravelCarma Flight Booking Engine"
              className="w-100"
              style={{ maxWidth: "412px" }}
            />
          </div>
          <div className="col-lg-6 text-justify">
            <p style={{ fontSize: "1.16rem", lineHeight: "36px" }}>
              Most customers today prefer to compare flight options and book
              flight tickets online. TravelCarma provides a powerful flight
              booking software that allows you to sell flight inventory to your
              B2C/B2B customers with real-time fares and availability.
            </p>
            <p style={{ fontSize: "1.16rem", lineHeight: "36px" }}>
              Our airline reservation system integrates with GDSs, Consolidators
              and LCCs via XML API, allowing your customers to search and book a
              wide range of domestic and international flights at the best rates
              in the comfort of their homes or on-the-go!
            </p>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
        </div>

        <h2 className="text-center mt-5">Top Benefits for your Business</h2>

        <div className="row text-center benefits-section">
          <div className="col-lg-6">
            <h2>The only Flight Booking System you'll ever need</h2>
            <ul className="list-sm-arr">
              <li>
                Available on both web and mobile (responsive and native app)
              </li>
              <li>
                Supports multiple sales channels - B2C, B2B, B2B2C, B2E and
                Corporate
              </li>
              <li>
                Extensive reports for tracking and accounting reconciliation
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
          <div className="col-lg-6 d-flex">
            <ImageSlider slider="FBS1" />
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6 d-flex">
            <ImageSlider slider="FBS2" />
          </div>
          <div className="col-lg-6">
            <h2>Provide your clients Multiple Search Options</h2>
            <ul className="list-sm-arr">
              <li>
                Multiple trip options including single trip, round-trip and
                multi-city trip
              </li>
              <li>Search widget in different themes/layouts</li>
              <li>Ability to search by airline and class of service</li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6">
            <h2>Show Detailed Search Results</h2>
            <ul className="list-sm-arr">
              <li>
                Several filters and sorting options including departure and
                arrival time, airline, price, no of stops, duration etc
              </li>
              <li>
                Flexi-search showing airline fares +/- 3 days around the
                selected date
              </li>
              <li>
                Ability to specifically see the lowest fare on the selected
                date(s) for various Airlines
              </li>
              <li>Display fare and baggage rules</li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
          <div className="col-lg-6 d-flex">
            <ImageSlider slider="FBS3" />
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6 d-flex">
            <ImageSlider slider="FBS4" />
          </div>
          <div className="col-lg-6">
            <h2>Put Bookings on Autopilot</h2>
            <ul className="list-sm-arr">
              <li>
                Auto e-ticket and voucher generation with your own branding
              </li>
              <li>
                Auto cancellation of unconfirmed bookings before the
                cancellation period starts
              </li>
              <li>My Bookings to easily track and manage reservations</li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6">
            <h2>Offer Flexible Payment Options</h2>
            <ul className="list-sm-arr">
              <li>
                Support for multiple currencies with real-time currency
                conversion
              </li>
              <li>Multiple payment modes at checkout</li>
              <li>
                Book-now-pay-later feature to let customers make a reservation
                online but pay offline at a later date
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
          <div className="col-lg-6 d-flex">
            <ImageSlider slider="FBS5" />
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6 d-flex">
            <ImageSlider slider="FBS6" />
          </div>
          <div className="col-lg-6">
            <h2>Provide a Faster Booking Experience</h2>
            <ul className="list-sm-arr">
              <li>Allow customers to sign in with Facebook and Google</li>
              <li>
                Ability to store frequent co-traveler details to save time
                during bookings
              </li>
              <li>Add multiple flights to the cart and book them together</li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6">
            <h2>Integrate third-party Suppliers</h2>
            <ul className="list-sm-arr">
              <li>
                Aggregation of both GDS and Non-GDS content into a single
                booking engine
              </li>
              <li>Real-time Integration with 130+ Low Cost Carriers</li>
              <li>
                Ability to configure different suppliers for domestic and
                international flights
              </li>
              <li>
                Ability to turn on and turn off feed depending on your supplier
                balance to manage revenue
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
          <div className="col-lg-6 d-flex">
            <ImageSlider slider="FBS7" />
          </div>

          <div className="col-lg-12 section-separator"></div>

          <div className="col-lg-6 d-flex">
            <ImageSlider slider="FBS8" />
          </div>
          <div className="col-lg-6">
            <h2>Maximize your Revenue Potential</h2>
            <ul className="list-sm-arr">
              <li>Show flight promotions/offers on the search results page</li>
              <li>
                Ability to book add-ons such as extra baggage, insurance and
                meals
              </li>
              <li>
                Dynamic packaging - Flight + Hotel as well as Flight + Fixed
                Package
              </li>
            </ul>
            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsFlightBookingEngine;
