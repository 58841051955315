import React from "react";
import PageHeader from "../components/page-header";
import eventlogo1 from "../assets/images/events/tc/itb-asia-summit-2019.png";
import eventlogo2 from "../assets/images/events/tc/travel-technology-summit-2018.png";
import eventlogo3 from "../assets/images/events/tc/arabian -travel-market.jpg";
import eventlogo4 from "../assets/images/events/tc/wtm-2016.jpg";
import eventlogo5 from "../assets/images/events/tc/SATTE-2016.jpg";
import eventlogo6 from "../assets/images/events/tc/ITB_berlin_2012.jpg";
import eventlogo7 from "../assets/images/events/tc/travel-technology-europe-2012.jpg";
import eventlogo8 from "../assets/images/events/tc/the-travel-innovation-summit.jpg";
import eventlogo9 from "../assets/images/events/tc/wtm_london_2011.jpg";
import eventlogo10 from "../assets/images/events/tc/ITB-asia-2010.jpg";
import eventlogo11 from "../assets/images/events/tc/ITB_berlin_2009.jpg";
import eventlogo12 from "../assets/images/events/tc/WTM-london-2008.gif";
import eventlogo13 from "../assets/images/events/tc/Itb-asia-2008.gif";
import eventlogo14 from "../assets/images/events/tc/travel-distribution-summit-europe.gif";
import eventlogo15 from "../assets/images/events/tc/travel-distribution-summit-asia.gif";
import eventlogo16 from "../assets/images/events/tc/travel-distribution-summit-north-america.gif";
import eventlogo17 from "../assets/images/events/tc/wtm-2007.gif";
import eventlogo18 from "../assets/images/events/tc/eyefortravel.gif";

const CompanyEvents = () => {
  return (
    <div className="page-events">
      <PageHeader page="events" />
      <div className="container">
        <ul className="event-box list-unstyled row d-flex justify-content-center">
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo1}
                width="216px"
                alt="ITB Asia Summit 2019"
                title="ITB Asia Summit 2019"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo2}
                width="206px"
                alt="Travel Technology Summit 2018"
                title="Travel Technology Summit 2018"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo3}
                width="162px"
                alt="Arabian Travel Market"
                title="Arabian Travel Market"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo4}
                alt="World Travel Market"
                title="World Travel Market"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img src={eventlogo5} alt="SATTE" title="SATTE" />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo6}
                alt="ITB Berlin 2012"
                title="ITB Berlin 2012"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo7}
                style={{ borderRadius: "8px" }}
                alt="Travel Technology Europe 2012"
                title="Travel Technology Europe 2012"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo8}
                style={{ borderRadius: "8px" }}
                alt="The Travel Innovation Summit"
                title="The Travel Innovation Summit"
              />
            </div>
          </li>

          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo9}
                width="192px"
                alt="World Travel Market London"
                title="World Travel Market London"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo10}
                width="162px"
                alt="ITB Asia 2010"
                title="ITB Asia 2010"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo11}
                alt="ITB Berlin 2009"
                title="ITB Berlin 2009"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo12}
                alt="WTM London 2008"
                title="WTM London 2008"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo13}
                alt="ITB Asia 2008"
                title="ITB Asia 2008"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo14}
                alt="Travel Distribution Summit Europe"
                title="Travel Distribution Summit Europe"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo15}
                alt="Travel Distribution Summit Asia"
                title="Travel Distribution Summit Asia"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo16}
                alt="Travel Distribution Summit North America"
                title="Travel Distribution Summit North America"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo17}
                alt="WTM London 2007"
                title="WTM London 2007"
              />
            </div>
          </li>
          <li className="col-lg-3 mb-3">
            <div>
              <img
                src={eventlogo18}
                alt="Eye For Travel"
                title="Eye For Travel"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CompanyEvents;
