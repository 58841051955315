import React, { useEffect, useState } from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Icon1 from "../assets/images/contact-form-email.png";
import Icon2 from "../assets/images/contact-form-phone.png";
import Icon3 from "../assets/images/contact-form-skype.png";
import Icon4 from "../assets/images/contact-form-address.png";

import Review1 from "../assets/images/review-capterra.png";
import Review2 from "../assets/images/review-crozdesk.png";
import Review3 from "../assets/images/review-supreme.png";
import Review4 from "../assets/images/review-user-recommended.png";
import Review5 from "../assets/images/review-best-value.png";

import SM3 from "../assets/images/icn_linkedIn.png";
import SM4 from "../assets/images/icn_facebook.png";
import SM5 from "../assets/images/icn_twiter.png";

const ContactUs = () => {
  const page =
    window.location.pathname === "/contact-us" ? "contact" : "thanks";

  const navigate = useNavigate();

  const [data, setData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    website: "",
    skype: "",
    inventory: "",
    comments: "",
    isSubscribed: "",
  });
  const [validation, setValidation] = useState({
    name: false,
    company: false,
    email: false,
    phone: false,
    website: false,
    skype: false,
    inventory: false,
    comments: false,
    isSubscribed: false,
  });
  const [isSubmited, setSubmited] = useState(false);
  const [updateKey, setUpdateKey] = useState(1);
  const [isShowLoading, setLoading] = useState(false);

  const onChange = (e) => {
    if (e.target.name === "name") {
      data.name = e.target.value;
    }
    if (e.target.name === "company") {
      data.company = e.target.value;
    }
    if (e.target.name === "email") {
      data.email = e.target.value;
    }
    if (e.target.name === "phone") {
      data.phone = e.target.value;
    }
    if (e.target.name === "website") {
      data.website = e.target.value;
    }
    if (e.target.name === "skype") {
      data.skype = e.target.value;
    }
    if (e.target.name === "inventory") {
      data.inventory = e.target.value;
    }
    if (e.target.name === "comments") {
      data.comments = e.target.value;
    }
    if (e.target.name === "isSubscribed") {
      data.isSubscribed = e.target.value;
    }
    setData(data);
  };

  const handleSubmit = () => {
    setLoading(true);
    let url = "https://iapi.travelcarma.com/default.php";
    //let url = "http://localhost/inquiry-management/default.php";

    let obj = {
      path: "add-inquiry",
      inquiry: data,
    };
    var xhttp;
    xhttp = new XMLHttpRequest();
    xhttp.open("POST", url, true);
    xhttp.send(JSON.stringify(obj));
    xhttp.onreadystatechange = function () {
      setLoading(false);
      if (this.readyState === 4 && this.status === 200) {
        navigate("/thankyou");
        window.scrollTo(0, 0);
      }
    };
  };

  const handleValidation = () => {
    if (
      !data?.name ||
      !data?.company ||
      !data?.email ||
      !data?.phone ||
      !data?.inventory ||
      !data?.comments
    ) {
      console.log("in");
    } else {
      if (!data?.name) {
        validation.name = true;
      }
      if (!data?.company) {
        validation.company = true;
      }
      if (!data?.email) {
        validation.email = true;
      }
      if (!data?.phone) {
        validation.phone = true;
      }
      if (!data?.inventory) {
        validation.inventory = true;
      }
      if (!data?.comments) {
        validation.comments = true;
      }
      setValidation(validation);
      setUpdateKey(updateKey + 1);
    }
  };

  // useEffect(() => {
  //   setPage(window.location.pathname === "/contact-us" ? "contact" : "thanks");
  // });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-inside">
      <PageHeader page="contact-us" />

      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            {page === "thanks" && (
              <div className="shadow p-5 text-center">
                <h4 className="text-primary">
                  Your inquiry has been submitted successfully. We will get in
                  touch with you soon.
                </h4>
                <p className="mt-4">
                  If you have any other queries in the meantime, please don't
                  hesitate to get in touch with us at{" "}
                  <Link title="Email Me" to="mailto:sales@travelcarma.com">
                    sales@travelcarma.com
                  </Link>
                </p>
                <h5 className="text-primary mt-5">
                  Follow us on social media to stay updated
                </h5>
                <div className="SocialIcons pt-3 d-flex justify-content-center">
                  <ul className="list-unstyled d-flex ">
                    <li>
                      <Link
                        title="TravelCarma on LinkedIn"
                        target="_blank"
                        to="https://www.linkedin.com/company/travelcarma.com"
                      >
                        <img
                          height="24"
                          width="24"
                          alt="TravelCarma on LinkedIn"
                          src={SM3}
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        title="TravelCarma on Facebook"
                        target="_blank"
                        to="https://www.facebook.com/TravelCarma"
                      >
                        <img
                          height="24"
                          width="24"
                          alt="TravelCarma on Facebook"
                          src={SM4}
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        title="follow @TravelCarma"
                        target="_blank"
                        to="https://twitter.com/travelcarma"
                      >
                        <img height="24" width="24" alt="Twitter" src={SM5} />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {page === "contact" && (
              <div className="shadow p-5" key={updateKey}>
                <p>
                  Kindly fill out this short form. It will help us understand
                  your business better and offer you the ideal solution.
                </p>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name *
                  </label>
                  <input
                    name="name"
                    id="name"
                    className="form-control"
                    onChange={onChange}
                  />
                  {validation?.name && (
                    <p className="error-red">Name Required</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="company" className="form-label">
                    Company *
                  </label>
                  <input
                    name="company"
                    id="company"
                    className="form-control"
                    onChange={onChange}
                  />
                  {validation?.company && (
                    <p className="error-red">Company Required</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Business Email *
                  </label>
                  <input
                    name="email"
                    id="email"
                    className="form-control"
                    onChange={onChange}
                  />
                  {validation?.email && (
                    <p className="error-red">Business Email Required</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone *
                  </label>
                  <input
                    name="phone"
                    id="phone"
                    className="form-control"
                    onChange={onChange}
                  />
                  {validation?.phone && (
                    <p className="error-red">Phone Required</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="website " className="form-label">
                    Website
                  </label>
                  <input
                    name="website"
                    id="website"
                    className="form-control"
                    onChange={onChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="skype" className="form-label">
                    Skype ID
                  </label>
                  <input
                    name="skype"
                    id="skype"
                    className="form-control"
                    onChange={onChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="inventory" className="form-label">
                    Do you have Inventory? *
                  </label>

                  <select
                    name="inventory"
                    id="inventory"
                    className="form-control form-select"
                    onChange={onChange}
                  >
                    <option value="">Please Select</option>
                    <option value="Yes, I have XML API contract(s)">
                      Yes, I have XML API contract(s)
                    </option>
                    <option value="Yes, I have Direct Contracts(negotiated rates)">
                      Yes, I have Direct Contracts (negotiated rates)
                    </option>
                    <option value="Yes, I have both XML API and Direct Contracts">
                      Yes, I have both XML API and Direct Contracts
                    </option>
                    <option value="No, I don't have inventory">
                      No, I don't have inventory
                    </option>
                  </select>

                  {validation?.inventory && (
                    <p className="error-red">API Contracts Required</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="comments" className="form-label">
                    How May We Help You? *
                  </label>
                  <textarea
                    name="comments"
                    id="comments"
                    className="form-control"
                    onChange={onChange}
                  />
                  {validation?.comments && (
                    <p className="error-red">Comment Required</p>
                  )}
                </div>

                <div className="form-check mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="isSubscribed"
                    id="isSubscribed"
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="isSubscribed">
                    Please send me your newsletter and other relevant updates.
                  </label>
                </div>

                <p className="mt-3 text-secondary">
                  You may unsubscribe from these communications at any time. For
                  more information, please review our{" "}
                  <Link to="/privacypolicy" className="text-primary">
                    Privacy Policy
                  </Link>
                  .
                </p>

                <button
                  className="btn btn-primary btn-lg mt-3"
                  onClick={handleSubmit}
                >
                  {isShowLoading && (
                    <div class="spinner-border spinner-border-sm text-light me-2"></div>
                  )}
                  Submit
                </button>
              </div>
            )}
          </div>
          <div className="col-lg-6">
            <div className="shadow p-5">
              <div>
                <div className="row">
                  <div className="col-lg-2 text-center">
                    <img src={Icon1} alt="Email" />
                  </div>
                  <div className="col-lg-2">
                    <b>Email:</b>
                  </div>
                  <div className="col-lg-8">
                    <Link
                      className="text-primary"
                      title="Email Me"
                      to="mailto:sales@travelcarma.com"
                    >
                      sales@travelcarma.com
                    </Link>
                  </div>

                  <div className="col-lg-12">
                    <hr
                      style={{
                        height: "1px",
                        background: "rgba(0,0,0,0.3)",
                        border: "0px none",
                      }}
                    />
                  </div>

                  <div className="col-lg-2 text-center">
                    <img src={Icon2} alt="Phone" />
                  </div>
                  <div className="col-lg-2">
                    <b>Phone:</b>
                  </div>
                  <div className="col-lg-8">
                    US: +1 904 328 3090, +1 904 236 4868
                    <br />
                    UK: +44 203 051 6811
                    <br />
                    India: +91 79 2687 2171
                  </div>

                  <div className="col-lg-12">
                    <hr
                      style={{
                        height: "1px",
                        background: "rgba(0,0,0,0.3)",
                        border: "0px none",
                      }}
                    />
                  </div>

                  <div className="col-lg-2 text-center">
                    <img src={Icon3} alt="Skype" />
                  </div>
                  <div className="col-lg-2">
                    <b>Skype:</b>
                  </div>
                  <div className="col-lg-8">
                    <Link
                      className="text-primary"
                      title="Skype Me"
                      to="skype:marketing.avanicimcon?chat"
                    >
                      TravelCarma
                    </Link>
                  </div>

                  <div className="col-lg-12">
                    <hr
                      style={{
                        height: "1px",
                        background: "rgba(0,0,0,0.3)",
                        border: "0px none",
                      }}
                    />
                  </div>

                  <div className="col-lg-2 text-center">
                    <img src={Icon4} alt="Address" />
                  </div>
                  <div className="col-lg-2">
                    <b>Address:</b>
                  </div>
                  <div className="col-lg-8">
                    US: 12482 Turnberry Drive, Jacksonville,
                    <br /> Florida 32225. USA
                    <br />
                    <br />
                    India: 301, Balleshwar Avenue,
                    <br /> Opp. Rajpath Club, S.G. Road, Bodakdev, Ahmedabad-380
                    015, Gujarat, India
                  </div>
                  <div className="col-lg-12">
                    <hr
                      style={{
                        height: "1px",
                        background: "rgba(0,0,0,0.3)",
                        border: "0px none",
                      }}
                    />
                  </div>
                </div>

                <div className="mt-2">
                  <h3 className="text-primary mb-3 text-center">
                    Testimonials
                  </h3>
                  <div>
                    <b>Kristjan Vilic, Croatia</b>
                    <p className="text-secondary">
                      TravelCarma have extremely client-centric and highly
                      professional personnel who always stand by your side. We
                      highly recommend TravelCarma as they're amongst the best
                      in travel technology.
                    </p>
                  </div>
                  <div>
                    <b>Mohammad Azmy, Kuwait</b>
                    <p className="text-secondary">
                      Travelcarma is a modern Travel Agency Software with all
                      the features a Travel Agency needs for driving their
                      online business smoothly and efficiently.
                    </p>
                  </div>
                  <Link
                    className="text-primary small"
                    to="/customer-testimonials"
                  >
                    Read More
                  </Link>
                </div>

                <div>
                  <hr
                    style={{
                      height: "1px",
                      background: "rgba(0,0,0,0.3)",
                      border: "0px none",
                    }}
                  />
                </div>

                <div>
                  <div className="row">
                    <div className="col-lg-4">
                      <Link
                        to="https://www.softwaresuggest.com/travelcarma-travel-agency?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                        target="_blank"
                      >
                        <img src={Review3} className="w-100" alt="" />
                      </Link>
                    </div>
                    <div className="col-lg-4">
                      <Link
                        to="https://www.softwaresuggest.com/travelcarma-travel-agency?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                        target="_blank"
                      >
                        <img src={Review4} className="w-100" alt="" />
                      </Link>
                    </div>
                    <div className="col-lg-4">
                      <Link
                        to="https://www.softwaresuggest.com/travelcarma-travel-agency"
                        target="_blank"
                      >
                        <img src={Review5} className="w-100" alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-4 d-flex align-items-center">
                      <Link
                        to="https://www.capterra.com/travel-agency-software/reviews/74648/TravelCarma/TravelCarma?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                        target="_blank"
                      >
                        <img src={Review1} className="w-100" alt="" />
                      </Link>
                    </div>
                    <div className="col-lg-4">
                      <Link
                        to="https://crozdesk.com/customer-service-crm/reservations-online-bookings-software/travelcarma"
                        target="_blank"
                      >
                        <img src={Review2} className="w-100" alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
