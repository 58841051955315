import React from "react";
import PageHeader from "../components/page-header";
import EmperiaClipart from "../assets/images/solutions/emperia-clipart.png";
import EmperiaBox from "../assets/images/solutions/emperia-box.png";
import EmperiaBrochure from "../assets/images/solutions/emperia-brochure.png";
import { Link } from "react-router-dom";
import EmperiaPdf from "../assets/resources/EmperiaBrochure.pdf";
import ImageSlider from "../components/image-slider";

const SolutionsTravelAgency = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="travel-agency-software" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <img
              src={EmperiaClipart}
              alt="Travel ERP System"
              className="w-100"
              style={{ maxWidth: "412px" }}
            />
          </div>
          <div className="col-lg-6 text-justify">
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              TravelCarma Emperia brings everything on a single cloud-based
              platform to help you automate sales and easily manage your
              business from anywhere, on any device.
            </p>

            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              Sell your travel products online via multiple channels,
              efficiently manage your operations with our extensive back-office
              automation module and track your agency's performance through
              in-depth business reports.
            </p>

            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
        </div>

        <h2 className="text-center mt-5 mb-5">
          Get Ready to Empower your Travel Agency
        </h2>

        <div className="solution-highlights mb-5 pb-5">
          <div className="row">
            <div className="col-lg-5">
              <div className="d-flex align-items-center mt-3 mb-4 pb-4 border-bottom">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 25 25"
                  >
                    <path
                      d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
                      fill="#444444"
                    ></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </figure>

                <h4 className="mb-0">Complete Booking Automation</h4>
              </div>
              <div className="d-flex align-items-center mb-4 pb-4">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 25 25"
                  >
                    <path
                      d="M19 5v14H5V5h14m1.1-2H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM11 7h6v2h-6V7zm0 4h6v2h-6v-2zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7z"
                      fill="#444444"
                    ></path>
                    <path fill="none" d="M0 0h24v24H0z"></path>
                  </svg>
                </figure>
                <h4 className="mb-0">Error Free Accounting</h4>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <img
                style={{ maxWidth: "84%" }}
                src={EmperiaBox}
                alt="Travel Agency System"
              />
              <Link to="/contact-us" className="btn btn-primary mt-3">
                Request a Demo
              </Link>
            </div>
            <div className="col-lg-5">
              <div className="d-flex align-items-center mt-3 mb-4 pb-4 border-bottom">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 20 20"
                    style={{ marginTop: "-10px", marginLeft: "-5px" }}
                  >
                    <path
                      d="M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z"
                      fill="#444444"
                    ></path>
                    <path fill="none" d="M0 0h24v24H0z"></path>
                  </svg>
                </figure>
                <h4 className="mb-0">Faster Quote Generation</h4>
              </div>
              <div className="d-flex align-items-center mb-4 pb-4">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 25 25"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
                      fill="#444444"
                    ></path>
                  </svg>
                </figure>
                <h4 className="mb-0">Higher Staff Productivity</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row text-center solutions-section">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-5">
                <h2>Take Bookings 24x7</h2>
                <p>
                  TravelCarma's <strong>travel booking software</strong> allows
                  your B2C and B2B customers to book flights, hotels, packages,
                  activities and ground transportation online with real-time
                  rates and availability. What's more, with our{" "}
                  <strong>Travel Agency Software</strong> you can sell both
                  third-party inventory of your choice as well as your directly
                  contracted inventory on the same interface.
                </p>
                <Link to="/contact-us" className="btn btn-primary">
                  Request a Demo
                </Link>
              </div>
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="TAS1" />
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Integrates easily with your existing website and branding
                  </li>
                  <li>
                    Offers a seamless booking experience on both web and mobile
                  </li>
                  <li>
                    Aggregate both your{" "}
                    <Link
                      to="/suppliers/travel-xml-api-integrations"
                      className="text-decoration-none text-primary"
                    >
                      XML API suppliers
                    </Link>{" "}
                    and negotiated fares
                  </li>
                  <li>
                    Auto-generate e-tickets and vouchers with your own logo
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Provide co-branded vouchers and emails to your sub-agents
                  </li>
                  <li>
                    Allow customers to add multiple items to a cart and book
                    them in one transaction
                  </li>
                  <li>Display a wide variety of filters and search options</li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>Display rates in multiple currencies</li>
                  <li>
                    Set rules on the fly to offer variable pricing and
                    incentives to your clients
                  </li>
                  <li>
                    Capture customer information for loyalty programs and
                    incentives
                  </li>
                  <li>Auto cancellation of unconfirmed bookings</li>
                </ul>
              </div>
            </div>
            <div className="solutions-separator"></div>

            <div className="row">
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="TAS2" />
              </div>
              <div className="col-lg-5">
                <h2>Simplify Back-Office Operations</h2>
                <p>
                  <strong>Travel agency software</strong> can help you manage
                  various aspects of your agency anywhere, anytime. Automate
                  routine admin tasks to improve overall staff efficiency and
                  minimize costly human errors. Experience cost savings of upto
                  60%
                </p>
                <Link to="/contact-us" className="btn btn-primary">
                  Request a Demo
                </Link>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Centrally manage web and mobile bookings, including
                    cancellations and modifications
                  </li>
                  <li>Configure system wide user roles and logins</li>
                  <li>
                    Set up markups and margins based on product, sales channel,
                    branch, region, supplier etc
                  </li>
                  <li>
                    Set up both static and dynamic promotions and advertisements
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Allow staff to make credit-based bookings on behalf of
                    customers
                  </li>
                  <li>
                    Simplify reconciliation - find out exactly how much is
                    receivable from suppliers, how much you owe your sub-agents
                    etc
                  </li>
                  <li>
                    Track employee bookings and the limits given to each
                    employee
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Access advanced reports and analytics on the activities
                    happening through the portal, including bookings,
                    commissions, sales, revenues etc
                  </li>
                  <li>
                    Manage customers and their booking records within the system
                    or connect your existing CRM system into the booking
                    platform seamlessly
                  </li>
                </ul>
              </div>
            </div>
            <div className="solutions-separator"></div>
            <div className="row">
              <div className="col-lg-5">
                <h2>Centrally Manage your B2B Agents</h2>
                <p>
                  Our <strong>Travel Agency Booking Software</strong> allows
                  your sub-agents to securely access your live inventories, make
                  reservations and issue instant confirmation for their end
                  customers. Manage agent markups, margins, credit limits,
                  commissions and other business rules on one platform.
                </p>
                <Link to="/contact-us" className="btn btn-primary">
                  Request a Demo
                </Link>
              </div>
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="TAS3" />
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Set up 'n' number of downline agents with secured access for
                    each agent
                  </li>
                  <li>
                    Classify agents and assign unique markups to each agent
                    class
                  </li>
                  <li> Set up prepaid and postpaid agents separately</li>
                  <li>Set up and manage agent-wise credit limits</li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Provide private label booking portals to your agents (B2B2C)
                  </li>
                  <li>Get agent-wise online recharge facility</li>
                  <li>View and manage bookings made by downline agents</li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>Issue vouchers on behalf of downline agents</li>
                  <li>
                    Let your downline agents apply real-time markups on B2B
                    bookings
                  </li>
                  <li>Get agent-wise booking reports</li>
                </ul>
              </div>
            </div>
            <div className="solutions-separator"></div>
            <div className="row">
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="TAS4" />
              </div>
              <div className="col-lg-5">
                <h2>Add & Distribute your Travel Products</h2>
                <p>
                  Load all your directly contracted inventory of hotels,
                  packages, activities and car rentals in the CRS and manage
                  pricing, availability and booking policies from a central
                  location with the help of our Travel Agency Software. Allow
                  your hotel partners to log in via the extranet and update
                  their inventory in the system.
                </p>
                <Link to="/contact-us" className="btn btn-primary">
                  Request a Demo
                </Link>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Add hotels and other vendors you have direct contracts with
                    and manage their rates and availabilities
                  </li>
                  <li>Control pricing and configure commissions and markups</li>
                  <li>
                    Sell contracted inventory along with third-party content on
                    your website
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Manage allocations and promotions for a season or a specific
                    period, eg. Christmas/New Year
                  </li>
                  <li>
                    Configure amenities at macro and micro levels to take care
                    of special requests
                  </li>
                  <li>
                    View business statistics at a glance through the dashboard
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Eliminate the hassle of managing your content and processing
                    manual confirmation for each booking
                  </li>
                  <li>
                    Provide individual login for each hotel and let the hotel
                    manage their rates and availabilities through the extranet
                  </li>
                  <li>
                    Distribute contracted inventory to resellers/affiliates
                    through an outbound API
                  </li>
                </ul>
              </div>
            </div>
            <div className="solutions-separator"></div>
            <div className="row">
              <div className="col-lg-5">
                <h2>Integrate Third-party Suppliers</h2>
                <p>
                  Have your own <strong>XML API contracts</strong>? Great!
                  Integrate multiple third-party suppliers into your website to
                  offer a wide range of content to your customers at the best
                  possible rates. No more logging into each supplier's system
                  for rates and availability.
                </p>
                <Link to="/contact-us" className="btn btn-primary">
                  Request a Demo
                </Link>
              </div>
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="TAS5" />
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Choose from <strong>over 80 existing integrations</strong>{" "}
                    and/or bring in new ones
                  </li>
                  <li>
                    Offer your customers global inventory of hotels, flights,
                    car rentals, tours and more
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Add your own markups to the supplier rates for optimal
                    revenue management
                  </li>
                  <li>
                    Platform Agnostic - Connect to diverse platforms and data
                    sources
                  </li>
                  <li>
                    Compatible with Industry standard XML specifications (OTA
                    and TTI)
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    <strong>Unified XML interface</strong> - initiate a single
                    search against multiple suppliers and receive a single
                    integrated response
                  </li>
                  <li>
                    Sell unique and best priced hotel inventory on your portal
                    using our de-duplication and mapping process
                  </li>
                </ul>
              </div>
            </div>
            <div className="solutions-separator"></div>
            <div className="row">
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="TAS6" />
              </div>
              <div className="col-lg-5">
                <h2>Build Complex Quotes in Minutes</h2>
                <p>
                  Preparing quotations is one of the most time-consuming areas
                  for a Travel Agency. TravelCarma's{" "}
                  <strong>Online Travel Agency software</strong> helps you
                  generate branded itineraries and quotes with real-time pricing
                  in one-third the time it would take you manually.
                </p>
                <Link to="/contact-us" className="btn btn-primary">
                  Request a Demo
                </Link>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Select multiple inventory items from the database and build
                    fully customized multi-day itineraries for customers
                  </li>
                  <li>
                    Save the itinerary with its validity &amp; book at a later
                    date with real-time rates and availability checks
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Build quotes with the desired products and send them to
                    multiple customers via email
                  </li>
                  <li>
                    Book all the items in the quote in a single transaction
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul>
                  <li>
                    Experience higher productivity as your staff won't have to
                    juggle between multiple supplier systems and spreadsheets
                  </li>
                  <li>
                    Copy, merge, save and reuse quotes which saves a phenomenal
                    amount of time
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-center mt-5 mb-5">Available in Two Models</h2>
        <div className="row available-model-box">
          <div className="col-lg-2"></div>
          <div className="col-lg-4">
            <div className="shadow rounded am-box-blue">
              <div className="rounded p-4 text-white">
                <h3 className="fw-bold">SaaS</h3>
                <h5>
                  Ideal for agencies that don't have a tech team or own hosting
                </h5>
              </div>

              <ul className="list-unstyled p-4">
                <li>
                  <i className="material-icons"></i>The application is hosted on
                  a shared cloud server
                </li>
                <li>
                  <i className="material-icons"></i>Basic Customization (colors,
                  branding etc)
                </li>
                <li>
                  <i className="material-icons"></i>Pricing: One-time setup fee
                  + monthly subscription that covers hosting, maintenance,
                  upgrades and basic support
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="shadow rounded am-box-green">
              <div className="rounded p-4 text-white">
                <h3 className="fw-bold">Enterprise</h3>
                <h5>
                  Ideal for agencies that have a tech team and own hosting
                </h5>
              </div>

              <ul className="list-unstyled p-4">
                <li>
                  <i className="material-icons"></i>We'll deploy the application
                  on your server
                </li>
                <li>
                  <i className="material-icons"></i>We can provide front-end
                  APIs to help you build custom front-ends
                </li>
                <li>
                  <i className="material-icons"></i>Pricing: Licence fee +
                  annual maintenance
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>

        <h2 className="text-center mt-5 mb-5">Emperia Brochure</h2>
        <div className="text-center">
          <img src={EmperiaBrochure} alt="Travel ERP" />
          <br />
          <Link
            className="btn btn-primary mt-4"
            to={EmperiaPdf}
            target="_blank"
          >
            Click to Download Brochure
          </Link>
        </div>

        <h2 className="text-center mt-5 mb-5">FAQs</h2>
        <div className="solutions-faqs">
          <ul className="list-unstyled">
            <li>
              <h5>What is the cost of the solution?</h5>
              <div>
                <div>
                  <div>
                    <p>
                      We charge a one-time setup fee plus a fixed monthly
                      subscription that includes hosting, maintenance and basic
                      support. The setup fee and subscription amount depends on
                      factors such as the modules you want, the APIs you bring,
                      expected search/traffic volume(the load our system will
                      have to manage) etc.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <h5>What are the available modules in the solution?</h5>
              <div>
                <div>
                  <div>
                    <p>
                      As part of our travel agency solution we offer a CMS-based
                      website(with your own branding), B2C/B2B booking engine
                      (for flights, hotels, activities, car rental, transfers),
                      a back-office/agency management module, call center module
                      (for on-behalf bookings). If you have direct
                      contracts/negotiated rates, we also provide an inventory
                      management module, or a CRS (for hotels, activities, car
                      rental, fixed packages).
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <h5>
                I already have a website. Can your booking engine integrate with
                my existing website?
              </h5>
              <div>
                <div>
                  <div>
                    <p>
                      Yes, we can integrate the booking engine into your
                      existing website with your own suppliers.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <h5>Do you provide customization?</h5>
              <div>
                <div>
                  <div>
                    <p>
                      We provide minimal customization in the SaaS model as the
                      application is hosted on a common cloud shared by multiple
                      users. You can expect basic customization like your own
                      branding across the portal and emails and theme colors. If
                      you buy a CMS website from us you can customize the theme
                      the way you want from the CMS itself. You will not be able
                      to customize the booking engine in the SaaS module. If you
                      want advanced customization you will need to buy a
                      licensed version and the customizations will be
                      chargeable.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <h5>How long will it take for me to go live?</h5>
              <div>
                <div>
                  <div>
                    <p>
                      Depending on the no of suppliers and the level of
                      customization, it can take anywhere between a week to a
                      couple of months.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <h5>Does your solution include inventory?</h5>
              <div>
                <div>
                  <div>
                    <p>
                      No, our travel agency solution doesn't include inventory.
                      To be able to use this solution, you need to bring your
                      own APIs or contracted inventory and we will integrate
                      them into your portal.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <h5>How can we integrate our own APIs/direct contracts?</h5>
              <div>
                <div>
                  <div>
                    <p>
                      You will need to provide us a list of APIs you want to
                      integrate along with the API documentation and live
                      credentials using which we will activate live feeds on
                      your portal(s). We have already integrated over 80
                      suppliers. We can also integrate any new suppliers that
                      you bring.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <h5>What booking modes does your solution provide?</h5>
              <div>
                <div>
                  <div>
                    <p>
                      We provide multiple booking modes under this solution:
                    </p>
                    <ul>
                      <li>
                        Adaptability Call center where bookings can be made by
                        your employees on behalf of customers.
                      </li>
                      <li>
                        B2C where your retail customers can directly book from
                        the portal.
                      </li>
                      <li>
                        B2B where your sub-agents can log in and make bookings.
                      </li>
                    </ul>
                    <p>
                      You can choose any of these depending on your business
                      model.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <h5>How does your Platform process Payments?</h5>
              <div>
                <div>
                  <div>
                    <p>
                      It depends on the booking mode you select. In the call
                      center mode, your employees can book with your integrated
                      suppliers using your deposits with them.
                    </p>
                    <p>
                      For B2C, we can integrate your own choice payment
                      gateway(s) to take card payments from your customers. We
                      also offer a book-now-pay-later option where you can hold
                      bookings (within the cancellation period) and collect
                      payment from the client later.
                    </p>
                    <p>
                      In the B2B mode you can create an online wallet to manage
                      sub-agent deposits and allow them to make bookings against
                      the deposit. You can also maintain credit limits for your
                      agents or corporate clients in the system.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <h5>Which currencies does your software support?</h5>
              <div>
                <div>
                  <div>
                    <p>
                      Our software supports multiple currencies. You can easily
                      configure your portal's base currency through the
                      back-office module. Our booking engine also provides
                      real-time currency conversion to display the prices in
                      your customer or agent's preferred currency on the
                      front-end.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <h5>Where is your software hosted?</h5>
              <div>
                <div>
                  <div>
                    <p>
                      This is a SaaS(Software-as-a-Service) solution by default,
                      wherein the solution is hosted on a common cloud used by
                      several clients on a subscription basis to keep your costs
                      low. If you want to host it on your own server you will
                      need to go for on-premise hosting, which will cost
                      significantly more.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <h5>How many people in my office can use the system?</h5>
              <div>
                <div>
                  <div>
                    <p>
                      There's no limit on the number of employees or sub-agents
                      in the system.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <h5>
                Can I connect my existing accounting tool with your software?
              </h5>
              <div>
                <div>
                  <div>
                    <p>
                      You can import or export data between our software and
                      your accounting software through CSV.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <h5>Will you provide training and support?</h5>
              <div>
                <div>
                  <div>
                    <p>
                      We will provide training as part of our onboarding. Video
                      recordings of training sessions are available for
                      reference later on. We provide basic support via our
                      ticketing system free of cost with a 24-hour turnaround.
                      For phone support and faster turnaround you can purchase
                      our support packages.
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SolutionsTravelAgency;
