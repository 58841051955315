import React from "react";
import { Helmet } from "react-helmet";
import HomeSlider from "../components/home-slider";
import HomeSolutions from "../components/home-solutions";
import HomeTravelcarmaFor from "../components/home-travelcarma-for";
import HomePlatform from "../components/home-platform";
import HomeSuppliers from "../components/home-suppliers";
import HomeCustomers from "../components/home-customers";
import HomeWhatDifferent from "../components/home-what-different";
import HomeBlog from "../components/home-blog";

const Index = () => {
  return (
    <div>
      <Helmet>
        <title>
          Travel Technology Company | Travel Technology Solutions | TravelCarma
        </title>
        <meta
          name="keywords"
          content="Travel Agency Software, Travel Technology Solutions, Travel Softwares, Travel Software Solution"
        />
        <meta
          name="description"
          content="TravelCarma provides complete travel technology solutions like travel agency software, B2C/B2B Booking Engines, Back Office, Travel API Integration for travel agencies, tour operators & wholesalers.
"
        />
      </Helmet>
      <HomeSlider />
      <div className="home-body">
        <div>
          <HomeSolutions />
          <HomeTravelcarmaFor />
          <HomePlatform />
          <HomeSuppliers />
          <HomeCustomers />
          <HomeWhatDifferent />
          <HomeBlog />
        </div>
      </div>
    </div>
  );
};

export default Index;
