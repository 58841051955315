import FBS1I1 from "../assets/images/slider/flight-booking-engine/flight-booking-engine.png";
import FBS2I1 from "../assets/images/slider/flight-booking-engine/flight-booking-widget.png";
import FBS2I2 from "../assets/images/slider/flight-booking-engine/flight-booking-search.png";
import FBS3I1 from "../assets/images/slider/flight-booking-engine/flight-booking-results.png";
import FBS3I2 from "../assets/images/slider/flight-booking-engine/flight-flexible-search.png";
import FBS3I3 from "../assets/images/slider/flight-booking-engine/flight-booking-details.png";
import FBS4I1 from "../assets/images/slider/flight-booking-engine/back-office.png";
import FBS5I1 from "../assets/images/slider/flight-booking-engine/flexible-payment-options.png";
import FBS6I1 from "../assets/images/slider/flight-booking-engine/login-portal.png";
import FBS7I1 from "../assets/images/slider/flight-booking-engine/flight-suppliers.png";
import FBS8I1 from "../assets/images/slider/flight-booking-engine/flight-fixed-package.png";
import FBS8I2 from "../assets/images/slider/flight-booking-engine/flight-transfers.png";

import HBS1I1 from "../assets/images/slider/hotel-booking-engine/hotel-booking-engine.png";
import HBS2I1 from "../assets/images/slider/hotel-booking-engine/hotel-results-1.png";
import HBS2I2 from "../assets/images/slider/hotel-booking-engine/hotel-results-2.png";
import HBS2I3 from "../assets/images/slider/hotel-booking-engine/hotel-map-view.png";
import HBS3I1 from "../assets/images/slider/hotel-booking-engine/room-selection.png";
import HBS3I2 from "../assets/images/slider/hotel-booking-engine/shopping-cart.png";
import HBS3I3 from "../assets/images/slider/hotel-booking-engine/add-co-traveller.png";
import HBS4I1 from "../assets/images/slider/hotel-booking-engine/hotel-voucher.png";
import HBS5I1 from "../assets/images/slider/hotel-booking-engine/checkout.png";
import HBS6I1 from "../assets/images/slider/hotel-booking-engine/hotel-suppliers.png";
import HBS7I1 from "../assets/images/slider/hotel-booking-engine/back-office-promotion.png";
import HBS7I2 from "../assets/images/slider/hotel-booking-engine/back-office-markup.png";
import HBS7I3 from "../assets/images/slider/hotel-booking-engine/dynamic-packaging.png";

import CBS1I1 from "../assets/images/slider/car-booking-engine/car-rental-list.jpg";
import CBS1I2 from "../assets/images/slider/car-booking-engine/car-rental-details.jpg";
import CBS1I3 from "../assets/images/slider/car-booking-engine/car-rental-checkout.jpg";

import ABS1I1 from "../assets/images/slider/activity-booking-engine/activity-list.jpg";
import ABS1I2 from "../assets/images/slider/activity-booking-engine/activity-list-grid.jpg";
import ABS1I3 from "../assets/images/slider/activity-booking-engine/activity-details.jpg";
import ABS1I4 from "../assets/images/slider/activity-booking-engine/activity-details-2.jpg";
import ABS1I5 from "../assets/images/slider/activity-booking-engine/activity-details-3.jpg";
import ABS1I6 from "../assets/images/slider/activity-booking-engine/activity-checkout.jpg";
import ABS1I7 from "../assets/images/slider/activity-booking-engine/activity-checkout-2.jpg";

import TBS1I1 from "../assets/images/slider/transfers-booking-engine/transfers-list.jpg";
import TBS1I2 from "../assets/images/slider/transfers-booking-engine/transfers-details.jpg";
import TBS1I3 from "../assets/images/slider/transfers-booking-engine/transfers-checkout.jpg";

import HCS1I1 from "../assets/images/slider/hotel-crs/hotel-dashboard.png";
import HCS1I2 from "../assets/images/slider/hotel-crs/edit-property.png";
import HCS1I3 from "../assets/images/slider/hotel-crs/hotel-rates-and-allocations.jpg";
import HCS1I4 from "../assets/images/slider/hotel-crs/property-list.jpg";
import HCS1I5 from "../assets/images/slider/hotel-crs/hotel-promotions-list.jpg";

import ACS1I1 from "../assets/images/slider/activity-crs/package-details.png";
import ACS1I2 from "../assets/images/slider/activity-crs/activity-rates.png";
import ACS1I3 from "../assets/images/slider/activity-crs/activity-list.jpg";
import ACS1I4 from "../assets/images/slider/activity-crs/activity-member-details.jpg";
import ACS1I5 from "../assets/images/slider/activity-crs/activity-policy.png";
import ACS1I6 from "../assets/images/slider/activity-crs/activity-report-dashboard.png";
import ACS1I7 from "../assets/images/slider/activity-crs/activity-booking-ledger.png";

import TCS1I1 from "../assets/images/slider/transfers-crs/transfer-member-details.jpg";
import TCS1I2 from "../assets/images/slider/transfers-crs/transfers-crs.jpg";
import TCS1I3 from "../assets/images/slider/transfers-crs/transfers-list.jpg";
import TCS1I4 from "../assets/images/slider/transfers-crs/transfer-vehicle-details.jpg";

import CCS1I1 from "../assets/images/slider/car-crs/vehicle-member-list.jpg";
import CCS1I2 from "../assets/images/slider/car-crs/vehicle-member-details.jpg";
import CCS1I3 from "../assets/images/slider/car-crs/vehicle-rates.jpg";
import CCS1I4 from "../assets/images/slider/car-crs/vehicle-types.jpg";
import CCS1I5 from "../assets/images/slider/car-crs/vehicle-promotion-setup.jpg";

import BOS1I1 from "../assets/images/slider/back-office/my-bookings.png";
import BOS1I2 from "../assets/images/slider/back-office/add-my-bookings.png";
import BOS2I1 from "../assets/images/slider/back-office/markup-setup-hotel.jpg";
import BOS2I2 from "../assets/images/slider/back-office/markup-set-up.png";
import BOS2I3 from "../assets/images/slider/back-office/add-employee.png";
import BOS3I1 from "../assets/images/slider/back-office/add-agent.png";
import BOS3I2 from "../assets/images/slider/back-office/agent-balance.png";
import BOS4I1 from "../assets/images/slider/back-office/promotions-setup.jpg";
import BOS5I1 from "../assets/images/slider/back-office/super-reconciliation-detail.png";
import BOS6I1 from "../assets/images/slider/back-office/bi-report.png";

import BBS1I1 from "../assets/images/slider/b2b-booking-engine/filter-agent-info.png";
import BBS2I1 from "../assets/images/slider/b2b-booking-engine/logos.png";
import BBS3I1 from "../assets/images/slider/b2b-booking-engine/add-Agent.png";
import BBS3I2 from "../assets/images/slider/b2b-booking-engine/list-agent.png";
import BBS3I3 from "../assets/images/slider/b2b-booking-engine/edit-agent.png";
import BBS3I4 from "../assets/images/slider/b2b-booking-engine/agent-balance.png";
import BBS3I5 from "../assets/images/slider/b2b-booking-engine/agent-class.png";
import BBS4I1 from "../assets/images/slider/b2b-booking-engine/dashboard-agent.png";
import BBS4I2 from "../assets/images/slider/b2b-booking-engine/agent-markup.png";

import TAS1I1 from "../assets/images/slider/travel-agency/flight-booking-widget.jpg";
import TAS1I2 from "../assets/images/slider/travel-agency/flight-list.jpg";
import TAS1I3 from "../assets/images/slider/travel-agency/hotel-list.jpg";
import TAS1I4 from "../assets/images/slider/travel-agency/hotel-room-selection.jpg";

import TAS2I1 from "../assets/images/slider/travel-agency/my-bookings.png";
import TAS2I2 from "../assets/images/slider/travel-agency/markup-setup-hotel.jpg";
import TAS2I3 from "../assets/images/slider/travel-agency/promotions-setup.jpg";
import TAS2I4 from "../assets/images/slider/travel-agency/suppliers.png";

import TAS3I1 from "../assets/images/slider/travel-agency/agent-dashboard.png";
import TAS3I2 from "../assets/images/slider/travel-agency/add-agent.png";
import TAS3I3 from "../assets/images/slider/travel-agency/agent-markup.jpg";
import TAS3I4 from "../assets/images/slider/travel-agency/agent-balance.jpg";
import TAS3I5 from "../assets/images/slider/travel-agency/agent-class.jpg";

import TAS4I1 from "../assets/images/slider/travel-agency/hotel-dashboard.png";
import TAS4I2 from "../assets/images/slider/travel-agency/edit-property.png";
import TAS4I3 from "../assets/images/slider/travel-agency/property-list.jpg";
import TAS4I4 from "../assets/images/slider/travel-agency/activity-rates.png";

import TAS5I1 from "../assets/images/slider/travel-agency/suppliers-logo.png";
import TAS5I2 from "../assets/images/slider/travel-agency/suppliers-setup.png";
import TAS5I3 from "../assets/images/slider/travel-agency/supplierwise-booking-reports.png";

import TAS6I1 from "../assets/images/slider/travel-agency/quote-sharing-via-email-simple-workflow.png";
import TAS6I2 from "../assets/images/slider/travel-agency/quotation-email-send.png";

import DMS1I1 from "../assets/images/slider/dmc/cms-portal-1.png";
import DMS1I2 from "../assets/images/slider/dmc/cms-portal-2.png";
import DMS1I3 from "../assets/images/slider/dmc/cms-portal-3.png";

import DMS2I1 from "../assets/images/slider/dmc/package-1.png";
import DMS2I2 from "../assets/images/slider/dmc/package-2.png";
import DMS2I3 from "../assets/images/slider/dmc/package-3.png";

import DMS3I1 from "../assets/images/slider/dmc/crs-ims-1.png";
import DMS3I2 from "../assets/images/slider/dmc/crs-ims-2.png";
import DMS3I3 from "../assets/images/slider/dmc/crs-ims-3.png";

import DMS4I1 from "../assets/images/slider/dmc/quote-sharing-via-email-simple-workflow.png";
import DMS4I2 from "../assets/images/slider/dmc/quotation-email-send.png";

import DMS5I1 from "../assets/images/slider/dmc/agency-my-bookings.png";
import DMS5I2 from "../assets/images/slider/dmc/manage-revenue.png";
import DMS5I3 from "../assets/images/slider/dmc/backoffice-suppliers.png";

import DMS6I1 from "../assets/images/slider/dmc/suppliers-logos.png";
import DMS6I2 from "../assets/images/slider/dmc/supplier-setup.png";

import BSS1I1 from "../assets/images/slider/bus-operator/bus-portal-1.png";
import BSS1I2 from "../assets/images/slider/bus-operator/bus-portal-2.png";

import BSS2I1 from "../assets/images/slider/bus-operator/search-result.png";
import BSS2I2 from "../assets/images/slider/bus-operator/seat-selection-information.png";

import BSS3I1 from "../assets/images/slider/bus-operator/edit-list-route.png";
import BSS3I2 from "../assets/images/slider/bus-operator/list-edit-schedule.png";
import BSS3I3 from "../assets/images/slider/bus-operator/list-edit-fare.png";
import BSS3I4 from "../assets/images/slider/bus-operator/edit-seating-temp.png";

import BSS4I1 from "../assets/images/slider/bus-operator/edit-agent.png";
import BSS4I2 from "../assets/images/slider/bus-operator/edit-agentcommision-cobrand.png";

import HUS1I1 from "../assets/images/slider/hajj-umrah/b2b-hotel.png";
import HUS1I2 from "../assets/images/slider/hajj-umrah/b2b-itinerary.png";
import HUS1I3 from "../assets/images/slider/hajj-umrah/b2c-transportation.png";
import HUS1I4 from "../assets/images/slider/hajj-umrah/b2c-umrah-services.png";
import HUS1I5 from "../assets/images/slider/hajj-umrah/b2b-visa-fees.png";

import HUS2I1 from "../assets/images/slider/hajj-umrah/b2b-booking-details.png";
import HUS2I2 from "../assets/images/slider/hajj-umrah/b2b-booking-list.png";
import HUS2I3 from "../assets/images/slider/hajj-umrah/b2b-customer-information.png";
import HUS2I4 from "../assets/images/slider/hajj-umrah/b2b-e-wallet.png";
import HUS2I5 from "../assets/images/slider/hajj-umrah/b2b-hotel-2.png";
import HUS2I6 from "../assets/images/slider/hajj-umrah/b2b-manage-customer.png";

import HUS3I1 from "../assets/images/slider/hajj-umrah/back-office-add-agent.png";
import HUS3I2 from "../assets/images/slider/hajj-umrah/back-office-agent-balance.png";
import HUS3I3 from "../assets/images/slider/hajj-umrah/back-office-branch-management.png";
import HUS3I4 from "../assets/images/slider/hajj-umrah/back-office-email-template-management.png";
import HUS3I5 from "../assets/images/slider/hajj-umrah/back-office-markup-management.png";

import HAS1I1 from "../assets/images/solutions/host-agency/ota-booking-engine.png";
import HAS2I1 from "../assets/images/solutions/host-agency/my-bookings.png";
import HAS3I1 from "../assets/images/solutions/host-agency/manage-revenue.png";
import HAS4I1 from "../assets/images/solutions/host-agency/crs.png";

import MAS1I1 from "../assets/images/solutions/mobile-app/screen-1.png";
import MAS1I2 from "../assets/images/solutions/mobile-app/screen-2.png";
import MAS1I3 from "../assets/images/solutions/mobile-app/screen-3.png";
import MAS1I4 from "../assets/images/solutions/mobile-app/screen-4.png";
import MAS1I5 from "../assets/images/solutions/mobile-app/screen-5.png";
import MAS1I6 from "../assets/images/solutions/mobile-app/screen-6.png";
import MAS1I7 from "../assets/images/solutions/mobile-app/screen-7.png";
import MAS1I8 from "../assets/images/solutions/mobile-app/screen-8.png";

import MAS2I1 from "../assets/images/solutions/mobile-app/B2B-1.png";
import MAS2I2 from "../assets/images/solutions/mobile-app/B2B-2.png";
import MAS2I3 from "../assets/images/solutions/mobile-app/B2B-3.png";
import MAS2I4 from "../assets/images/solutions/mobile-app/B2B-4.png";
import MAS2I5 from "../assets/images/solutions/mobile-app/B2B-5.png";
import MAS2I6 from "../assets/images/solutions/mobile-app/B2B-6.png";
import MAS2I7 from "../assets/images/solutions/mobile-app/B2B-7.png";

const SliderData = [
  {
    name: "FBS1",
    imgs: [
      {
        img: FBS1I1,
        title: "Flight Booking Engine 1",
      },
    ],
  },
  {
    name: "FBS2",
    imgs: [
      {
        img: FBS2I1,
        title: "Flight Booking Engine 2",
      },
      {
        img: FBS2I2,
        title: "Flight Booking Engine 3",
      },
    ],
  },
  {
    name: "FBS3",
    imgs: [
      {
        img: FBS3I1,
        title: "Flight Booking Engine 2",
      },
      {
        img: FBS3I2,
        title: "Flight Booking Engine 3",
      },
      {
        img: FBS3I3,
        title: "Flight Booking Engine 3",
      },
    ],
  },
  {
    name: "FBS4",
    imgs: [
      {
        img: FBS4I1,
        title: "Flight Booking Engine 2",
      },
    ],
  },
  {
    name: "FBS5",
    imgs: [
      {
        img: FBS5I1,
        title: "Flight Booking Engine 2",
      },
    ],
  },
  {
    name: "FBS6",
    imgs: [
      {
        img: FBS6I1,
        title: "Flight Booking Engine 2",
      },
    ],
  },
  {
    name: "FBS7",
    imgs: [
      {
        img: FBS7I1,
        title: "Flight Booking Engine 2",
      },
    ],
  },
  {
    name: "FBS8",
    imgs: [
      {
        img: FBS8I1,
        title: "Flight Booking Engine 2",
      },
      {
        img: FBS8I2,
        title: "Flight Booking Engine 2",
      },
    ],
  },
  {
    name: "HBS1",
    imgs: [
      {
        img: HBS1I1,
        title: "Hotel Booking Engine 2",
      },
    ],
  },
  {
    name: "HBS2",
    imgs: [
      {
        img: HBS2I1,
        title: "Hotel Booking Engine 2",
      },
      {
        img: HBS2I2,
        title: "Hotel Booking Engine 2",
      },
      {
        img: HBS2I3,
        title: "Hotel Booking Engine 2",
      },
    ],
  },
  {
    name: "HBS3",
    imgs: [
      {
        img: HBS3I1,
        title: "Hotel Booking Engine 2",
      },
      {
        img: HBS3I2,
        title: "Hotel Booking Engine 2",
      },
      {
        img: HBS3I3,
        title: "Hotel Booking Engine 2",
      },
    ],
  },
  {
    name: "HBS4",
    imgs: [
      {
        img: HBS4I1,
        title: "Hotel Booking Engine 2",
      },
    ],
  },
  {
    name: "HBS5",
    imgs: [
      {
        img: HBS5I1,
        title: "Hotel Booking Engine 2",
      },
    ],
  },
  {
    name: "HBS6",
    imgs: [
      {
        img: HBS6I1,
        title: "Hotel Booking Engine 2",
      },
    ],
  },
  {
    name: "HBS7",
    imgs: [
      {
        img: HBS7I1,
        title: "Hotel Booking Engine 2",
      },
      {
        img: HBS7I2,
        title: "Hotel Booking Engine 2",
      },
      {
        img: HBS7I3,
        title: "Hotel Booking Engine 2",
      },
    ],
  },
  {
    name: "CBS1",
    imgs: [
      {
        img: CBS1I1,
        title: "Car Booking Engine",
      },
      {
        img: CBS1I2,
        title: "Car Booking Engine",
      },
      {
        img: CBS1I3,
        title: "Car Booking Engine",
      },
    ],
  },
  {
    name: "ABS1",
    imgs: [
      {
        img: ABS1I1,
        title: "Activity Booking Engine",
      },
      {
        img: ABS1I2,
        title: "Activity Booking Engine",
      },
      {
        img: ABS1I3,
        title: "Activity Booking Engine",
      },
      {
        img: ABS1I4,
        title: "Activity Booking Engine",
      },
      {
        img: ABS1I5,
        title: "Activity Booking Engine",
      },
      {
        img: ABS1I6,
        title: "Activity Booking Engine",
      },
      {
        img: ABS1I7,
        title: "Activity Booking Engine",
      },
    ],
  },
  {
    name: "TBS1",
    imgs: [
      {
        img: TBS1I1,
        title: "Transfers Booking Engine",
      },
      {
        img: TBS1I2,
        title: "Transfers Booking Engine",
      },
      {
        img: TBS1I3,
        title: "Transfers Booking Engine",
      },
    ],
  },
  {
    name: "HCS1",
    imgs: [
      {
        img: HCS1I1,
        title: "Hotel CRS",
      },
      {
        img: HCS1I2,
        title: "Hotel CRS",
      },
      {
        img: HCS1I3,
        title: "Hotel CRS",
      },
      {
        img: HCS1I4,
        title: "Hotel CRS",
      },
      {
        img: HCS1I5,
        title: "Hotel CRS",
      },
    ],
  },
  {
    name: "ACS1",
    imgs: [
      {
        img: ACS1I1,
        title: "Activity CRS",
      },
      {
        img: ACS1I2,
        title: "Activity CRS",
      },
      {
        img: ACS1I3,
        title: "Activity CRS",
      },
      {
        img: ACS1I4,
        title: "Activity CRS",
      },
      {
        img: ACS1I5,
        title: "Activity CRS",
      },
      {
        img: ACS1I6,
        title: "Activity CRS",
      },
      {
        img: ACS1I7,
        title: "Activity CRS",
      },
    ],
  },
  {
    name: "TCS1",
    imgs: [
      {
        img: TCS1I1,
        title: "Transfers CRS",
      },
      {
        img: TCS1I2,
        title: "Transfers CRS",
      },
      {
        img: TCS1I3,
        title: "Transfers CRS",
      },
      {
        img: TCS1I4,
        title: "Transfers CRS",
      },
    ],
  },
  {
    name: "CCS1",
    imgs: [
      {
        img: CCS1I1,
        title: "Vehicle CRS",
      },
      {
        img: CCS1I2,
        title: "Vehicle CRS",
      },
      {
        img: CCS1I3,
        title: "Vehicle CRS",
      },
      {
        img: CCS1I4,
        title: "Vehicle CRS",
      },
      {
        img: CCS1I5,
        title: "Vehicle CRS",
      },
    ],
  },
  {
    name: "BOS1",
    imgs: [
      {
        img: BOS1I1,
        title: "Back Office",
      },
      {
        img: BOS1I2,
        title: "Back Office",
      },
    ],
  },
  {
    name: "BOS2",
    imgs: [
      {
        img: BOS2I1,
        title: "Back Office",
      },
      {
        img: BOS2I2,
        title: "Back Office",
      },
      {
        img: BOS2I3,
        title: "Back Office",
      },
    ],
  },
  {
    name: "BOS3",
    imgs: [
      {
        img: BOS3I1,
        title: "Back Office",
      },
      {
        img: BOS3I2,
        title: "Back Office",
      },
    ],
  },
  {
    name: "BOS4",
    imgs: [
      {
        img: BOS4I1,
        title: "Back Office",
      },
    ],
  },
  {
    name: "BOS5",
    imgs: [
      {
        img: BOS5I1,
        title: "Back Office",
      },
    ],
  },
  {
    name: "BOS6",
    imgs: [
      {
        img: BOS6I1,
        title: "Back Office",
      },
    ],
  },
  {
    name: "BBS1",
    imgs: [
      {
        img: BBS1I1,
        title: "B2B Booking Engine",
      },
    ],
  },
  {
    name: "BBS2",
    imgs: [
      {
        img: BBS2I1,
        title: "B2B Booking Engine",
      },
    ],
  },
  {
    name: "BBS3",
    imgs: [
      {
        img: BBS3I1,
        title: "B2B Booking Engine",
      },
      {
        img: BBS3I2,
        title: "B2B Booking Engine",
      },
      {
        img: BBS3I3,
        title: "B2B Booking Engine",
      },
      {
        img: BBS3I4,
        title: "B2B Booking Engine",
      },
      {
        img: BBS3I5,
        title: "B2B Booking Engine",
      },
    ],
  },
  {
    name: "BBS4",
    imgs: [
      {
        img: BBS4I1,
        title: "B2B Booking Engine",
      },
      {
        img: BBS4I2,
        title: "B2B Booking Engine",
      },
    ],
  },
  {
    name: "TAS1",
    imgs: [
      {
        img: TAS1I1,
        title: "Travel Agency Software",
      },
      {
        img: TAS1I2,
        title: "Travel Agency Software",
      },
      {
        img: TAS1I3,
        title: "Travel Agency Software",
      },
      {
        img: TAS1I4,
        title: "Travel Agency Software",
      },
    ],
  },
  {
    name: "TAS2",
    imgs: [
      {
        img: TAS2I1,
        title: "Travel Agency Software",
      },
      {
        img: TAS2I2,
        title: "Travel Agency Software",
      },
      {
        img: TAS2I3,
        title: "Travel Agency Software",
      },
      {
        img: TAS2I4,
        title: "Travel Agency Software",
      },
    ],
  },
  {
    name: "TAS3",
    imgs: [
      {
        img: TAS3I1,
        title: "Travel Agency Software",
      },
      {
        img: TAS3I2,
        title: "Travel Agency Software",
      },
      {
        img: TAS3I3,
        title: "Travel Agency Software",
      },
      {
        img: TAS3I4,
        title: "Travel Agency Software",
      },
      {
        img: TAS3I5,
        title: "Travel Agency Software",
      },
    ],
  },
  {
    name: "TAS4",
    imgs: [
      {
        img: TAS4I1,
        title: "Travel Agency Software",
      },
      {
        img: TAS4I2,
        title: "Travel Agency Software",
      },
      {
        img: TAS4I3,
        title: "Travel Agency Software",
      },
      {
        img: TAS4I4,
        title: "Travel Agency Software",
      },
    ],
  },
  {
    name: "TAS5",
    imgs: [
      {
        img: TAS5I1,
        title: "Travel Agency Software",
      },
      {
        img: TAS5I2,
        title: "Travel Agency Software",
      },
      {
        img: TAS5I3,
        title: "Travel Agency Software",
      },
    ],
  },
  {
    name: "TAS6",
    imgs: [
      {
        img: TAS6I1,
        title: "Travel Agency Software",
      },
      {
        img: TAS6I2,
        title: "Travel Agency Software",
      },
    ],
  },
  {
    name: "DMS1",
    imgs: [
      {
        img: DMS1I1,
        title: "DMC",
      },
      {
        img: DMS1I2,
        title: "DMC",
      },
      {
        img: DMS1I3,
        title: "DMC",
      },
    ],
  },
  {
    name: "DMS2",
    imgs: [
      {
        img: DMS2I1,
        title: "DMC",
      },
      {
        img: DMS2I2,
        title: "DMC",
      },
      {
        img: DMS2I3,
        title: "DMC",
      },
    ],
  },
  {
    name: "DMS3",
    imgs: [
      {
        img: DMS3I1,
        title: "DMC",
      },
      {
        img: DMS3I2,
        title: "DMC",
      },
      {
        img: DMS3I3,
        title: "DMC",
      },
    ],
  },
  {
    name: "DMS4",
    imgs: [
      {
        img: DMS4I1,
        title: "DMC",
      },
      {
        img: DMS4I2,
        title: "DMC",
      },
    ],
  },
  {
    name: "DMS5",
    imgs: [
      {
        img: DMS5I1,
        title: "DMC",
      },
      {
        img: DMS5I2,
        title: "DMC",
      },
      {
        img: DMS5I3,
        title: "DMC",
      },
    ],
  },
  {
    name: "DMS6",
    imgs: [
      {
        img: DMS6I1,
        title: "DMC",
      },
      {
        img: DMS6I2,
        title: "DMC",
      },
    ],
  },
  {
    name: "BSS1",
    imgs: [
      {
        img: BSS1I1,
        title: "Bus Operator Software",
      },
      {
        img: BSS1I2,
        title: "Bus Operator Software",
      },
    ],
  },
  {
    name: "BSS2",
    imgs: [
      {
        img: BSS2I1,
        title: "Bus Operator Software",
      },
      {
        img: BSS2I2,
        title: "Bus Operator Software",
      },
    ],
  },
  {
    name: "BSS3",
    imgs: [
      {
        img: BSS3I1,
        title: "Bus Operator Software",
      },
      {
        img: BSS3I2,
        title: "Bus Operator Software",
      },
      {
        img: BSS3I3,
        title: "Bus Operator Software",
      },
      {
        img: BSS3I4,
        title: "Bus Operator Software",
      },
    ],
  },
  {
    name: "BSS4",
    imgs: [
      {
        img: BSS4I1,
        title: "Bus Operator Software",
      },
      {
        img: BSS4I2,
        title: "Bus Operator Software",
      },
    ],
  },
  {
    name: "HUS1",
    imgs: [
      {
        img: HUS1I1,
        title: "Hajj and Umrah",
      },
      {
        img: HUS1I2,
        title: "Hajj and Umrah",
      },
      {
        img: HUS1I3,
        title: "Hajj and Umrah",
      },
      {
        img: HUS1I4,
        title: "Hajj and Umrah",
      },
      {
        img: HUS1I5,
        title: "Hajj and Umrah",
      },
    ],
  },
  {
    name: "HUS2",
    imgs: [
      {
        img: HUS2I1,
        title: "Hajj and Umrah",
      },
      {
        img: HUS2I2,
        title: "Hajj and Umrah",
      },
      {
        img: HUS2I3,
        title: "Hajj and Umrah",
      },
      {
        img: HUS2I4,
        title: "Hajj and Umrah",
      },
      {
        img: HUS2I5,
        title: "Hajj and Umrah",
      },
      {
        img: HUS2I6,
        title: "Hajj and Umrah",
      },
    ],
  },
  {
    name: "HUS3",
    imgs: [
      {
        img: HUS3I1,
        title: "Hajj and Umrah",
      },
      {
        img: HUS3I2,
        title: "Hajj and Umrah",
      },
      {
        img: HUS3I3,
        title: "Hajj and Umrah",
      },
      {
        img: HUS3I4,
        title: "Hajj and Umrah",
      },
      {
        img: HUS3I5,
        title: "Hajj and Umrah",
      },
    ],
  },
  {
    name: "HAS1",
    imgs: [
      {
        img: HAS1I1,
        title: "Host Agency",
      },
    ],
  },
  {
    name: "HAS2",
    imgs: [
      {
        img: HAS2I1,
        title: "Host Agency",
      },
    ],
  },
  {
    name: "HAS3",
    imgs: [
      {
        img: HAS3I1,
        title: "Host Agency",
      },
    ],
  },
  {
    name: "HAS4",
    imgs: [
      {
        img: HAS4I1,
        title: "Host Agency",
      },
    ],
  },
  {
    name: "MAS1",
    imgs: [
      {
        img: MAS1I1,
        title: "Mobile App",
      },
      {
        img: MAS1I2,
        title: "Mobile App",
      },
      {
        img: MAS1I3,
        title: "Mobile App",
      },
      {
        img: MAS1I4,
        title: "Mobile App",
      },
      {
        img: MAS1I5,
        title: "Mobile App",
      },
      {
        img: MAS1I6,
        title: "Mobile App",
      },
      {
        img: MAS1I7,
        title: "Mobile App",
      },
      {
        img: MAS1I8,
        title: "Mobile App",
      },
    ],
  },
  {
    name: "MAS2",
    imgs: [
      {
        img: MAS2I1,
        title: "Mobile App",
      },
      {
        img: MAS2I2,
        title: "Mobile App",
      },
      {
        img: MAS2I3,
        title: "Mobile App",
      },
      {
        img: MAS2I4,
        title: "Mobile App",
      },
      {
        img: MAS2I5,
        title: "Mobile App",
      },
      {
        img: MAS2I6,
        title: "Mobile App",
      },
      {
        img: MAS2I7,
        title: "Mobile App",
      },
    ],
  },
];

export default SliderData;
