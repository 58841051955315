import React from "react";
import SolEmperia from "../assets/images/sol-emperia.png";
import SolAdventia from "../assets/images/sol-adventia.png";
import SolEimdp from "../assets/images/sol-eimdp.png";
import SolTdx from "../assets/images/sol-tdx.png";
import { Link } from "react-router-dom";

const HomeSolutions = () => {
  return (
    <div className="home-technology-solutions mt-5">
      <div className="container">
        <h2 className="text-center mb-5">
          End-to-End Travel Technology Solutions
        </h2>
        <div className="row">
          <div className="col-lg-3 mb-3">
            <div className="p-3 text-center ">
              <img
                src={SolEmperia}
                alt="Emperia"
                className="w-100"
                style={{ maxWidth: "121px" }}
              />
              <h5 className="mt-3" style={{ fontWeight: "600" }}>
                Travel Agency ERP
              </h5>
              <p className="text-secondary">
                Integrate suppliers, automate reservations and accounting,
                streamline back office processes and manage B2B agents on one
                platform
              </p>
              <Link
                to="/solutions/travel-agency-software"
                className="btn btn-sm btn-primary"
              >
                Details
              </Link>
            </div>
          </div>
          <div className="col-lg-3 mb-3">
            <div className="p-3 text-center">
              <img
                src={SolAdventia}
                alt="Adventia"
                className="w-100"
                style={{ maxWidth: "121px" }}
              />
              <h5 className="mt-3" style={{ fontWeight: "600" }}>
                DMC/Tour Operators
              </h5>
              <p className="text-secondary">
                Manage your contracted inventory, design tour packages, apply
                advanced pricing rules and sell your tours across multiple
                channels
              </p>
              <Link
                to="/solutions/dmc-software"
                className="btn btn-sm btn-primary"
              >
                Details
              </Link>
            </div>
          </div>
          <div className="col-lg-3 mb-3">
            <div className="p-3 text-center">
              <img
                src={SolEimdp}
                alt="E-IMDP"
                className="w-100"
                style={{ maxWidth: "121px" }}
              />
              <h5 className="mt-3" style={{ fontWeight: "600" }}>
                Wholesaler Solution
              </h5>
              <p className="text-secondary">
                Channel Management Solution allowing wholesalers to manage &
                distribute contracted inventories across multiple online
                channels
              </p>
              <Link
                to="/solutions/inventory-distribution"
                className="btn btn-sm btn-primary"
              >
                Details
              </Link>
            </div>
          </div>
          <div className="col-lg-3 mb-3">
            <div className="p-3 text-center">
              <img
                src={SolTdx}
                alt="Travel Aggregation"
                className="w-100"
                style={{ maxWidth: "121px" }}
              />
              <h5 className="mt-3" style={{ fontWeight: "600" }}>
                Travel Aggregation
              </h5>
              <p className="text-secondary">
                Cloud-based Aggregation platform that lets you integrate
                multiple supplier APIs and consume the same through a unified
                API
              </p>
              <Link
                to="/solutions/travel-data-xchange-switch"
                className="btn btn-sm btn-primary"
              >
                Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSolutions;
