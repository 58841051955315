import React, { useState } from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import SolSymbion from "../assets/images/sol-symbion.png";
import HostAgencyWorkflow from "../assets/images/solutions/host-agency-workflow.png";
import SvgIcons from "../components/svg-icons";
import ImageSlider from "../components/image-slider";
import SymbionBrochure from "../assets/images/solutions/host-agency/symbion-brochure.png";
import SymbionBrochurePDF from "../assets/resources/TravelCarma_Symbion.pdf";

const SolutionsHostAgency = () => {
  const [activeTab, setActive] = useState("tab1");
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="host-agency-solution" />
      <div className="container">
        <p className="text-center" style={{ fontSize: "1.15rem" }}>
          For <strong>Host Agencies</strong> working with thousands of
          sub-agents/affiliates spread across a region, managing their{" "}
          <Link
            to="/solutions/inventory-distribution"
            className="text-decoration-none text-primary"
          >
            inventory distribution
          </Link>{" "}
          across such a large network and optimizing revenue from affiliates
          poses a huge challenge
        </p>

        <h3 className="text-center mt-5 mb-4">
          Let us ask you some quick questions
        </h3>

        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <ul className="list-box">
              <li>
                <i className="material-icons"></i>Do you have inventory
                contracts from multiple suppliers and find it challenging to
                consolidate them?
              </li>
              <li>
                <i className="material-icons"></i>Do you have trouble working
                out exactly much you need to receive from suppliers and how much
                you need to pay your affiliates every month, quarter or a year?
              </li>
              <li>
                <i className="material-icons"></i>Are you worried that your
                affiliates are not generating enough booking revenue?
              </li>
              <li>
                <i className="material-icons"></i>Are your affiliates asking for
                their own co-branded portals?
              </li>
              <li>
                <i className="material-icons"></i>Do you want to increase
                loyalty among your affiliates?
              </li>
            </ul>
          </div>
          <div className="col-lg-1"></div>
        </div>

        <h5 className="text-center mt-4">
          If the answer to the above questions is yes, we have a great solution
          for you.
        </h5>
      </div>

      <div
        className="mt-5 p-4"
        style={{
          background:
            "linear-gradient(to bottom, rgba(229,229,229,0.5) 0%,rgba(255,255,255,0.5) 51%,rgba(229,229,229,0.5) 100%)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-2">
              <img src={SolSymbion} alt="Host Agency" />
            </div>
            <div className="col-lg-6 text-center">
              <h2 className="mb-4" style={{ fontSize: "2rem" }}>
                Introducing Symbion
              </h2>
              <h5 className="pt-2">
                Symbion is the ultimate distribution and affiliate management
                solution for Host agencies, providing hosts a powerful
                technology platform to maximize inventory distribution and boost
                revenue through their affiliate network.
              </h5>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="mt-5">
          <h2 className="text-center mb-5">How it works</h2>
          <div className="text-center">
            <img
              src={HostAgencyWorkflow}
              className="w-100"
              style={{ maxWidth: "1108px" }}
              alt="Host Agency Workflow"
            />
          </div>
          <p className="text-center mt-4">
            Let's see how much revenue a <strong>host agency</strong> can make
            through this model. Let's assume you have 5000 affiliates working
            with you. Let's also assume we charge each affiliate a minimum of
            say, $100/month for the benefits we provide, and offer you $30/month
            out of that
          </p>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <ul className="list-box">
                <li>
                  If 1000 of these affiliates sign up, you would earn a monthly
                  revenue of $30 x 1000, or <b>$30,000</b>
                </li>
                <li>
                  If 2000 of these affiliates sign up, you would earn a monthly
                  revenue of <b>$60,000</b>
                </li>
                <li>
                  If 4000 of these affiliates sign up, you would earn a monthly
                  revenue of <b>$120,000</b>
                </li>
                <li>
                  If all 5000 affiliates sign up, you would earn monthly revenue
                  of $150,000, or <b>$1.8 million</b> annually!
                </li>
              </ul>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>

      <div
        className="text-center pb-3 pt-4 mt-5"
        style={{
          background:
            "linear-gradient(to bottom, rgba(229,229,229,0.5) 0%,rgba(255,255,255,0.5) 51%,rgba(229,229,229,0.5) 100%)",
        }}
      >
        <div className="container">
          <h2 className="text-center mt-4 mb-5">Benefits for Hosts</h2>

          <ul className="shadow-box list-unstyled row text-center mb-5">
            <li className="col-lg-3 mb-4">
              <div>
                {SvgIcons?.flexibility_scale}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Higher bookings and revenue
                </h3>
              </div>
            </li>
            <li className="col-lg-3 mb-4">
              <div>
                {SvgIcons?.mobile_responsive}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Access to a robust travel technology platform with managed
                  hosting
                </h3>
              </div>
            </li>
            <li className="col-lg-3 mb-4">
              <div>
                {SvgIcons?.channel_management}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Hosts can recoup their initial investment through affiliate
                  revenue
                </h3>
              </div>
            </li>
            <li className="col-lg-3 mb-4">
              <div>
                {SvgIcons?.agent_class_wise_pricing}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Greater agent loyalty
                </h3>
              </div>
            </li>
            <li className="col-lg-3">
              <div>
                {SvgIcons?.public}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  No need to worry about technology
                </h3>
              </div>
            </li>
            <li className="col-lg-3">
              <div>
                {SvgIcons?.cloud_done}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Easy signup and subscription process
                </h3>
              </div>
            </li>
            <li className="col-lg-3">
              <div>
                {SvgIcons?.lock}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Greater control on sales and revenues
                </h3>
              </div>
            </li>
            <li className="col-lg-3">
              <div>
                {SvgIcons?.payment_flexibility}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Reduces accounting and commission distribution efforts
                </h3>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="text-center pb-3 pt-4"
        style={{
          background: "#fff",
        }}
      >
        <div className="container">
          <h2 className="text-center mt-4 mb-5">
            Affiliate challenges addressed by our solution
          </h2>

          <ul className="shadow-box list-unstyled row text-center mb-3">
            <li className="col-lg-4 mb-4">
              <div>
                {SvgIcons?.touch_app}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Inability to contract with suppliers for content
                </h3>
              </div>
            </li>
            <li className="col-lg-4 mb-4">
              <div>
                {SvgIcons?.trending_down}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Tough competition from OTAs
                </h3>
              </div>
            </li>
            <li className="col-lg-4 mb-4">
              <div>
                {SvgIcons?.phonelink_off}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Lack of proper online booking and marketing tools
                </h3>
              </div>
            </li>
          </ul>

          <h4 className="text-primary mb-4">
            With this solution, you can improve not only the engagement among
            existing affiliates, but also attract new members and expand your
            network.
          </h4>
        </div>
      </div>

      <div
        className="text-center pb-3 pt-4"
        style={{
          background:
            "linear-gradient(to bottom, rgba(229,229,229,0.5) 0%,rgba(255,255,255,0.5) 51%,rgba(229,229,229,0.5) 100%)",
        }}
      >
        <div className="container">
          <h2 className="text-center mt-4 mb-5">
            Benefits for your Affiliates
          </h2>

          <ul className="shadow-box list-unstyled row text-center">
            <li className="col-lg-4 mb-4">
              <div>
                {SvgIcons?.devices}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  They get a great looking travel website with their own
                  branding and a full featured e-commerce engine in just a few
                  clicks
                </h3>
              </div>
            </li>
            <li className="col-lg-4 mb-4">
              <div>
                {SvgIcons?.assignment_turned_in}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  They also get built-in tools to manage bookings and customers
                  at no extra cost
                </h3>
              </div>
            </li>
            <li className="col-lg-4 mb-4">
              <div>
                {SvgIcons?.store}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  They can sell a wide range of travel products under one roof
                  to their clients without getting into the financial and
                  operational hassles of contracting with suppliers
                </h3>
              </div>
            </li>
            <li className="col-lg-4 mb-4">
              <div>
                {SvgIcons?.peace_of_mind}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  The solution would increase their efficiency and also loyalty
                  & engagement among their end users
                </h3>
              </div>
            </li>
            <li className="col-lg-4 mb-4">
              <div>
                {SvgIcons?.time_cost_savings}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  An online presence would help them reach a wider audience and
                  generate more bookings which would ultimately benefit the host
                  agency
                </h3>
              </div>
            </li>
            <li className="col-lg-4 mb-4">
              <div>
                {SvgIcons?.local_offer}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  They get full control over pricing and promotions through our
                  built-in revenue management tools
                </h3>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="container">
        <div className="mt-5">
          <h2 className="text-center">
            Benefits of our Technology Platform for Hosts
          </h2>

          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={"nav-link " + (activeTab === "tab1" ? "active" : "")}
                onClick={() => setActive("tab1")}
              >
                Sales & Distribution
              </button>
            </li>
            <li className="nav-item">
              <button
                className={"nav-link " + (activeTab === "tab2" ? "active" : "")}
                onClick={() => setActive("tab2")}
              >
                Mid-office
              </button>
            </li>
            <li className="nav-item">
              <button
                className={"nav-link " + (activeTab === "tab3" ? "active" : "")}
                onClick={() => setActive("tab3")}
              >
                Back Office and Reporting
              </button>
            </li>
            <li className="nav-item">
              <button
                className={"nav-link " + (activeTab === "tab4" ? "active" : "")}
                onClick={() => setActive("tab4")}
              >
                Contracted Inventory Management
              </button>
            </li>
          </ul>

          <div className="p-2 mt-3">
            {activeTab === "tab1" && (
              <div className="row">
                <div className="col-lg-9">
                  <ul className="list-tab-arr">
                    <li>
                      Real-time connectivity with 80+{" "}
                      <Link
                        to="/suppliers/travel-xml-api-integrations"
                        className="text-decoration-none text-primary"
                      >
                        XML API suppliers of air, hotels, activities and cars
                      </Link>
                    </li>
                    <li>
                      Aggregation of content from multiple third-party suppliers
                      along with your direct contracts into a single
                      distribution API
                    </li>
                    <li>
                      Multi-currency IBEs for flights, hotels, vacations,
                      activities, ground transportation and cruise
                    </li>
                    <li>
                      A host of unique features such as shopping cart,
                      book-now-pay-later, deep linking for featured products,
                      promotions on search results page and more
                    </li>
                    <li>
                      Multi-lingual portals to capture booking across diverse
                      markets and rank higher on search engines for queries
                      originating in target regions
                    </li>
                    <li>Cross-sell module to maximize revenue</li>
                    <li>Multiple Payment Gateway integration</li>
                    <li>
                      Ability to distribute your inventory across multiple
                      channels, including B2B, call center,{" "}
                      <strong>co-branded affiliate portals(B2B2C)</strong>,
                      Third-party IBEs, <strong>White Labels</strong> and Mobile
                      apps
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <ImageSlider slider="HAS1" />
                </div>
              </div>
            )}

            {activeTab === "tab2" && (
              <div className="row">
                <div className="col-lg-9">
                  <ul className="list-tab-arr">
                    <li>
                      Centralized management of both online and offline bookings
                    </li>
                    <li>
                      Management of credit limits and deposits for sub-agents
                    </li>
                    <li>
                      Ability to create bookable quotes in just a few clicks.
                      Quotes can be saved, copied and reused to save time
                    </li>
                    <li>
                      Auto-generation of travel documents such as quotes,
                      vouchers, e-tickets as well as financial documents like
                      client and supplier invoices and payments with
                      fully-customizable templates for each type of document
                    </li>
                    <li>
                      Ability to manage customers and their booking records
                      within the system or connect your existing CRM system into
                      the booking platform seamlessly
                    </li>
                    <li>
                      Integration with third-party email marketing tools for
                      running email campaigns
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <ImageSlider slider="HAS2" />
                </div>
              </div>
            )}

            {activeTab === "tab3" && (
              <div className="row">
                <div className="col-lg-9">
                  <ul className="list-tab-arr">
                    <li>
                      Single admin interface for web and mobile transactions
                    </li>
                    <li>
                      Configuration of advanced business rules and policies for
                      B2B and B2C channels
                    </li>
                    <li>
                      Management of local taxes and fees applicable on the
                      bookings
                    </li>
                    <li>
                      Configuration of various types of markups and commissions,
                      based on product type(hotel, air, activity etc), region,
                      portal etc
                    </li>
                    <li>Configuration of promo codes and discounts</li>
                    <li>
                      A comprehensive reporting module to help you keep track of
                      sales, commissions and sub-agent performance
                    </li>
                    <li>
                      Accounting ledgers for reconciliation with suppliers and
                      agents to help track profitability
                    </li>
                    <li>
                      All the reports and ledgers are able to show consolidated
                      data for both <strong>online bookings</strong> as well as
                      bookings made by your employees/agents offline
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <ImageSlider slider="HAS3" />
                </div>
              </div>
            )}
            {activeTab === "tab4" && (
              <div className="row">
                <div className="col-lg-9">
                  <ul className="list-tab-arr">
                    <li>
                      Central interface to setup product types, amenities,
                      rates, allocations, stop sell, etc
                    </li>
                    <li>
                      Ability to manage content, including descriptions, upload
                      images, enter details of special deals/offers, setup
                      various policies &amp; much more
                    </li>
                    <li>
                      Ability to set up inclusions, exclusions, terms and
                      conditions, promotions, blackout dates etc
                    </li>
                    <li>
                      Detailed Business Reports including product wise revenue
                      reports, billing reports, daily arrival reports,
                      reservation and cancellation reports, billing, inventory
                      reports, business rate reports and nationality rate
                      reports etc.
                    </li>
                    <li>
                      Extranet that allows suppliers access into the inventory
                      system and update their inventory on their own
                    </li>
                    <li>
                      Ability to distribute the inventory stored in the
                      inventory system to affiliates via outbound API
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <ImageSlider slider="HAS4" />
                </div>
              </div>
            )}
          </div>

          <h2 className="text-center mt-5 mb-5">Symbion Brochure</h2>
          <div className="text-center">
            <img src={SymbionBrochure} alt="EIMDP Brochure" />
            <br />
            <Link
              className="btn btn-primary mt-4"
              to={SymbionBrochurePDF}
              target="_blank"
            >
              Click to Download Brochure
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsHostAgency;
