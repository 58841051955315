import React from "react";

const HomeTravelcarmaFor = () => {
  return (
    <div className="home-travelcarma-for mt-5">
      <div className="container">
        <h2 className="text-center mb-5">Who TravelCarma is for</h2>
        <div className="row">
          <div className="col-lg-5">
            <h4 className="mb-4 mt-5" style={{ fontWeight: "600" }}>
              Our solutions are ideal for mid-sized to large travel companies
              with:
            </h4>
            <ul className="list-unstyled m-0 p-0">
              <li>An established industry presence</li>
              <li>APIs or direct contracts with suppliers</li>
              <li>500+ monthly bookings</li>
              <li>$5M+ gross annual booking revenue</li>
              <li>100+ Sub-agents if B2B</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTravelcarmaFor;
