import React from "react";
import PageHeader from "../components/page-header";
import TravelMobileAppClipart from "../assets/images/solutions/travel-mobile-app-development-clipart.png";
import ImageSlider from "../components/image-slider";
import SvgIcons from "../components/svg-icons";
import { Link } from "react-router-dom";

const SolutionsMobileTravelApps = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="mobile-travel-apps" />
      <div className="container">
        <p className="text-center mb-5">
          Travel Mobile Apps provide a fast and frictionless booking experience
          for travelers. That's why 58% people prefer to use apps to search for
          Travel. If your travel company doesn't have a mobile app, chances are
          your competitors do, and that means they have a higher chance of
          winning customers. In fact, mobile is going to become more crucial in
          the 'New Normal' as physical interactions will reduce and people will
          prefer to book travel online. With most people, especially
          'millennials' using mobile apps for everything these days, mobile apps
          will be their first choice when planning and booking travel.
        </p>

        <h2 className="text-center mt-5 mb-5">
          Supercharge your Business with us
        </h2>

        <div className="row">
          <div className="col-lg-5 text-center">
            <img
              src={TravelMobileAppClipart}
              alt="Travel Mobile App Development"
              className="w-100"
              style={{ maxWidth: "412px" }}
            />
          </div>
          <div className="col-lg-7 text-justify">
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              TravelCarma provides world-class travel mobile apps allowing your
              customers to search and book flights, hotels, sightseeing, car
              rental and transfers on their smartphones and tablets. Our mobile
              apps are available for both B2C and B2B and support multiple
              languages and currencies.
            </p>
            <p
              className="mb-5"
              style={{ fontSize: "1.1rem", lineHeight: "30px" }}
            >
              Our travel mobile app development is done on React, providing
              excellent speed and performance. They are readily available for
              Android and iOS, but thanks to our cross-platform development they
              can be built for any platform. With our mobile apps your retail
              clients and B2B agents will have access to global inventory at
              their fingertips and allow you to capture booking securely on the
              go - 24x7!
            </p>
          </div>
        </div>
      </div>

      <div
        className="text-center pb-3 pt-4 mb-5"
        style={{
          background:
            "linear-gradient(to bottom, rgba(229,229,229,0.5) 0%,rgba(255,255,255,0.5) 51%,rgba(229,229,229,0.5) 100%)",
        }}
      >
        <div className="container">
          <h2 className="text-center mt-3 mb-5">Benefits of our Mobile Apps</h2>

          <ul className="shadow-box list-unstyled row text-center mb-5">
            <li className="col-lg-4 mb-3">
              <div>
                {SvgIcons?.package_quote_creation}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Cross-Platform Development
                </h3>
              </div>
            </li>
            <li className="col-lg-4 mb-3">
              <div>
                {SvgIcons?.channel_management}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Available for both B2C & B2B
                </h3>
              </div>
            </li>
            <li className="col-lg-4 mb-3">
              <div>
                {SvgIcons?.time_cost_savings}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Powerful Marketing Tool
                </h3>
              </div>
            </li>
            <li className="col-lg-4">
              <div>
                {SvgIcons?.global_touch}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Multi-currency, Multi-language
                </h3>
              </div>
            </li>
            <li className="col-lg-4">
              <div>
                {SvgIcons?.global_touch}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Secure Cloud Hosting - AWS
                </h3>
              </div>
            </li>
            <li className="col-lg-4">
              <div>
                {SvgIcons?.global_touch}
                <h3
                  style={{
                    fontSize: "1.15rem",
                    padding: "0px",
                    margin: "16px 0px 0px 0px",
                    border: "0px none",
                  }}
                >
                  Low Development & Maintenance Cost
                </h3>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="pb-3 pt-4 mb-5 mt-5"
        style={{
          background: "#766CD3",
        }}
      >
        <div className="container">
          <h2 className="text-center mt-3 mb-5 text-white">B2C Mobile App</h2>
          <h4 className="text-center text-white mb-5">
            Deliver a superlative mobile booking experience to your B2C
            customers that matches global OTAs to help you maximize your sales
          </h4>
          <div className="row">
            <div className="col-lg-7">
              <ul className="text-white list-tab-arr list-tab-arr-white">
                <li>
                  <i className="material-icons"></i>
                  Intuitive booking interface for flight, hotel, ground
                  transportation and sightseeing to match the experience
                  provided by global OTAs
                </li>
                <li>
                  <i className="material-icons"></i>
                  Integrates with your own supplier contracts (GDSs, Bedbanks,
                  LCCs, Aggregators, or Negotiated Rates)
                </li>
                <li>
                  <i className="material-icons"></i>
                  Your branding(logo, colors, fonts etc) across the app and
                  booking emails
                </li>
                <li>
                  <i className="material-icons"></i>
                  Secure credit card payments through the app
                </li>
                <li>
                  <i className="material-icons"></i>
                  Available in over 15 languages to localize the experience
                </li>
                <li>
                  <i className="material-icons"></i>
                  Push special offers and alerts to end users through the app
                </li>
                <li>
                  <i className="material-icons"></i>
                  Users can add items to favorites and wishlists
                </li>
                <li>
                  <i className="material-icons"></i>
                  App-specific promo codes and discounts while booking
                </li>
                <li>
                  <i className="material-icons"></i>
                  Multiple search filters and sorting options
                </li>
                <li>
                  <i className="material-icons"></i>
                  Dynamic packaging (Flight+Hotel)
                </li>
                <li>
                  <i className="material-icons"></i>
                  Single sign-on allowing users to log in with Google/Facebook
                </li>
                <li>
                  <i className="material-icons"></i>
                  Ability to see hotels on a map
                </li>
                <li>
                  <i className="material-icons"></i>
                  My bookings for booking details &amp; management
                </li>
              </ul>
              <Link to="/contact-us" className="btn btn-primary mt-3 mb-4">
                Request a Demo
              </Link>
            </div>
            <div className="col-lg-5">
              <ImageSlider slider="MAS1" sliderType="mobile" />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container">
          <h2 className="text-center mt-3 mb-5">B2B Agent Mobile App</h2>
          <h4 className="text-center mb-5">
            Provide your B2B agents/SME corporate clients with a mobile solution
            to book your inventory and manage bookings anywhere, anytime. Ideal
            for B2B Agencies, TMCs and DMCs.
          </h4>
          <div className="row">
            <div className="col-lg-5">
              <ImageSlider slider="MAS2" sliderType="mobile" />
            </div>
            <div className="col-lg-7">
              <ul className="list-tab-arr">
                <li>
                  <i className="material-icons"></i>
                  Sub-agents/corporates can search &amp; book your products
                  through the app without querying rates/availabilities with you
                </li>
                <li>
                  <i className="material-icons"></i>
                  You can push special offers and notifications to your agents
                  through the app
                </li>
                <li>
                  <i className="material-icons"></i>
                  Connected to all your GDS/LCC/API suppliers in the back-end
                  along with your contracted inventory
                </li>
                <li>
                  <i className="material-icons"></i>
                  Back-office module for agent registration, credit limits and
                  markup setup
                </li>
                <li>
                  <i className="material-icons"></i>
                  Markups can be defined as per agent class
                </li>
                <li>
                  <i className="material-icons"></i>
                  Multi-currency and multi-lingual to localize the experience
                  for agents/corporates
                </li>
              </ul>
              <Link to="/contact-us" className="btn btn-primary mt-3 mb-4">
                Request a Demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsMobileTravelApps;
