import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import BannerAboutUs from "../assets/images/banner-images/banner-company-aboutus.jpg";
import BannerLeadership from "../assets/images/banner-images/banner-company-leadership.jpg";
import BannerWhyUs from "../assets/images/banner-images/banner-why-us.jpg";
import BannerEvents from "../assets/images/banner-images/banner-company-events.jpg";
import BannerNews from "../assets/images/banner-images/banner-news.jpg";
import BannerPartner from "../assets/images/banner-images/banner-partner.jpg";
import BannerCareers from "../assets/images/banner-images/banners-careers.jpg";

import BannerBeFlight from "../assets/images/banner-images/banner-be-flight.jpg";
import BannerBeHotel from "../assets/images/banner-images/banner-be-hotel.jpg";
import BannerBeActivity from "../assets/images/banner-images/banner-be-activity.jpg";
import BannerBeCar from "../assets/images/banner-images/banner-be-car.jpg";
import BannerBeTransfers from "../assets/images/banner-images/banner-be-transfers.jpg";
import BannerInventoryDistribution from "../assets/images/banner-images/banner-inventory-distribution.jpg";
import BannerBackoffice from "../assets/images/banner-images/banner-backoffice.jpg";
import BannerB2bBooking from "../assets/images/banner-images/banner-b2b-booking.jpg";

import BannerTravelAgency from "../assets/images/banner-images/banner-travel-agency.jpg";
import BannerTourOperator from "../assets/images/banner-images/banner-tour-operator.jpg";
import BannerHostAgency from "../assets/images/banner-images/banner-host-agency.jpg";
import BannerBusReservation from "../assets/images/banner-images/banner-bus-reservation.jpg";
import BannerDistribution from "../assets/images/banner-images/banner-distribution.jpg";
import BannerDevelopmentApi from "../assets/images/banner-images/banner-development-api.jpg";
import BannerTravelMobile from "../assets/images/banner-images/banner-travel-mobile-app.jpg";
import BannerTDX from "../assets/images/banner-images/banner-tdx.jpg";
import BannerEnterprise from "../assets/images/banner-images/banner-enterprise.jpg";

import BannerApiFlight from "../assets/images/banner-images/banner-api-flight.jpg";
import BannerApiHotel from "../assets/images/banner-images/banner-api-hotel.jpg";
import BannerApiActivities from "../assets/images/banner-images/banner-api-activities.jpg";
import BannerApiTransfer from "../assets/images/banner-images/banner-api-transfer.jpg";

import BannerEbooks from "../assets/images/banner-images/banner-ebooks.jpg";
import BannerInfographics from "../assets/images/banner-images/banner-infographics.jpg";
import BannerTestimonials from "../assets/images/banner-images/banner-testimonials.jpg";
import BannerCaseStudy from "../assets/images/banner-images/banner-case-study.jpg";
import BannerCustomers from "../assets/images/banner-images/banner-customers.jpg";

import BannerContactUs from "../assets/images/banner-images/banner-contact-us.jpg";
import BannerPrivacyPolicy from "../assets/images/banner-images/banner-privacy-policy.jpg";

const PageHeader = ({ page }) => {
  const [data, setData] = useState();

  const pageData = [
    {
      page: "about-travelcarma",
      pageTitle: "Travel Technology that delivers Profit",
      bannerImage: BannerAboutUs,
      title: "Leading Travel Technology Provider Company | TravelCarma",
      keywords: "About Us, About TravelCarma",
      desscription:
        "TravelCarma is a leading Travel Technology provider company for all travel technology needs of Travel Agents, Tour Operators, DMCs, Wholesalers and Vehicle Rental Companies.",
    },
    {
      page: "leadership-team",
      pageTitle: "Leadership Team",
      bannerImage: BannerLeadership,
      title: "TravelCarma | Travel Technology Company | Leadership Team",
      keywords: "Leadership Team",
      desscription:
        "TravelCarma is one of world's foremost Travel Technology companies lead by experts in both Travel and Software Development. TravelCarma's management team has a combined experience of over hundred years.",
    },
    {
      page: "why-us",
      pageTitle: "The TravelCarma Advantage",
      pageSubTitle:
        "Our goal is to save you time and money and boost your profitability.",
      bannerImage: BannerWhyUs,
      title:
        "Why TravelCarma - The Key Differentiators of our Travel Technology Solutions",
      keywords:
        "Travel technology, Consulting, software development, India, online booking engine, XML Integration, reservation system, SaaS, NASSCOM, Top 8 SaaS-AppS,Travel Cloud, Cloud Computing, Travel Software solutions, Software as a service, travel exchange, travel trade platform, Internet Booking Engine and reservation system on SaaS, XML Middleware",
      desscription:
        "TravelCarma is one of the most experienced travel technology companies out there, with several unique and innovative online travel solutions that are more affordable, offer a faster time to market and give a higher ROI.",
    },
    {
      page: "events",
      pageTitle: "Events",
      bannerImage: BannerEvents,
      title:
        "Travel Industry Events, Travel Technology Consulting & Solutions - TravelCarma",
      keywords:
        "Events at TravelCarma, News, participation in Travel Events, exhibits, travel events, travel technology events",
      desscription:
        "Travel Industry Events, Travel Technology Events - TravelCarma is a Unique Blend of Cloud-based Points-Of-Sales solutions, Inventory & Exchange Solutions and Business Networking Components for Travel Companies and Agents.",
    },
    {
      page: "awards",
      pageTitle: "Recognition / Awards ",
      bannerImage: BannerEvents,
      title:
        "TravelCarma Awards - Top SaaS-AppS for Travel Industry, Top 100 IT Innovators",
      keywords:
        "Top 8 SaaS AppS | Travel Technology Innovators | Awards | NASSCOM",
      desscription:
        "TravelCarma awarded of Top 8 SaaS-AppS at NASSCOM EMERGEOUT Conclave, India for Travel Technology. Also, selected as “Top 100 IT Innovation” by NASSCOM.",
    },
    {
      page: "news",
      pageTitle: "TravelCarma News",
      pageSubTitle:
        "Follow this page for latest travel technology news and announcements from TravelCarma",
      bannerImage: BannerNews,
      title: "Latest Travel Technology News and Announcements | TravelCarma",
      keywords: "Latest Travel Technology News and Announcements | TravelCarma",
      desscription:
        "Stay updated with our latest travel technology news and announcement including GDSs, technology solutions, travel technology events and more.",
    },
    {
      page: "partner-program",
      pageTitle: "TravelCarma Partner Program",
      bannerImage: BannerPartner,
      title: "Travel Technology Partner Program - TravelCarma",
      keywords:
        "Premier Partner Program, Authorized Sales Agent Partner Program, Referral Partner Program",
      desscription:
        "TravelCarma offer Multiple Partnership Options, Sales Partner in Travel Technology Industry, Provides  Custom Travel Technology Solutions in Travel Businesses for Travel Agency, Agents, Hoteliers and Tour Operators.",
    },
    {
      page: "careers",
      pageTitle: "Careers",
      pageSubTitle:
        "Looking to build a career in travel technology? Join our team!",
      bannerImage: BannerCareers,
      title: "Careers - Travel Technology Company | Online Travel Solutions",
      keywords: "Careers - Travel Technology Company | Online Travel Solutions",
      desscription:
        "Careers - Travel Technology Company | Online Travel Solutions",
    },
    {
      page: "flight-booking-engine",
      pageTitle: "Automate your Flight Bookings with our Flight Booking Engine",
      pageSubTitle:
        "An end-to-end Airline Reservation System to give your B2C/B2B customers a fast and seamless experience on both web and mobile.",
      bannerImage: BannerBeFlight,
      title: "Flight Booking Engine | Online Flight Reservation System",
      keywords:
        "flight booking engine, airline reservation system, online booking software, GDS booking system, online booking engine, online airline reservation system, flight booking system",
      desscription:
        "Our flight booking engine allows travel firms to automate the flight booking process with instant e-ticket generation and ability to integrate multiple flight suppliers.",
    },
    {
      page: "hotel-booking-engine",
      pageTitle:
        "Streamline your Hotel Bookings with our Hotel Reservation System",
      pageSubTitle:
        "With our hotel booking system your business can become a one-stop-shop for hotel bookings, generating revenue for you 24x7!",
      bannerImage: BannerBeHotel,
      title: "Hotel Booking Engine | Online Hotel Reservation System",
      keywords: "Hotel Booking Engine | Online Hotel Reservation System",
      desscription:
        "Online Hotel Reservation Software which provides hoteliers and agencies the ability to take online hotel bookings 24x7! It includes a complete inventory system, customer and booking management as well as real time reports.",
    },
    {
      page: "car-booking-engine",
      pageTitle: "Car Rental Booking Engine",
      bannerImage: BannerBeCar,
      title: "Car Rental Booking Engine | Car Reservation System",
      keywords: "Car Rental Booking Engine | Car Reservation System",
      desscription:
        "Our Car Rental Software provides Agencies and Car Rental Companies with Real-time Bookings, Inventory Management, Fare Management, B2B Agent Management, Map Based Search and more!",
    },
    {
      page: "activity-booking-engine",
      pageTitle: "Activity Booking Engine",
      bannerImage: BannerBeActivity,
      title:
        "Activity, Sightseeing Booking Engine | Online Excursions Reservation System",
      keywords:
        "Activity, Sightseeing Booking Engine | Online Excursions Reservation System",
      desscription:
        "Allows Tour Operators and Agents to book tours and activities online via multiple channels. It integrates with both direct contracts and third-party XML feeds",
    },
    {
      page: "transfers-booking-engine",
      pageTitle: "Transfers Booking Engine",
      bannerImage: BannerBeTransfers,
      title: "Transfers Booking Engine | Airport Transfers Reservation System",
      keywords:
        "Transfers Booking Engine | Airport Transfers Reservation System",
      desscription:
        "Let your customers book airport transfers online with our transfers booking engine. It automates your booking workflow and lets you manage your inventory in one place",
    },
    {
      page: "inventory-management",
      pageTitle: "Travel Inventory Management System (CRS)",
      pageSubTitle:
        "Multi-tenant CRSs to manage rates and real-time availability for Hotels, Activities, Fixed Packages and Ground Transportation",
      bannerImage: BannerInventoryDistribution,
      title:
        "Travel Inventory Management Solution | Hotel CRS | Activity CRS | Car Rental CRS",
      keywords:
        "Centrally manage and distribute your contracted inventory with real-time availability and prices for hotels, cars, activities etc",
      desscription:
        "Centrally manage and distribute your contracted inventory with real-time availability and prices for hotels, cars, activities etc",
    },
    {
      page: "back-office",
      pageTitle:
        "Simplify your operations with our Enterprise Back-office solution",
      bannerImage: BannerBackoffice,
      title:
        "Travel Mid & Back Office Management Software | Travel Agency Back Office",
      pageSubTitle:
        "Automate routine back-end processes and improve your overall business profitability",
      keywords:
        "Travel Mid & Back Office Management Software | Travel Agency Back Office & CRM Solution",
      desscription:
        "An advanced back-office management system for travel agencies and tour operators that helps to manage and automate routine administrative tasks to increased productivity and efficiency.",
    },
    {
      page: "b2b-booking-system",
      pageTitle: "Scale up your Revenue with our B2B Booking System",
      pageSubTitle:
        "End-to-end online booking solution for B2B agencies, wholesalers and DMCs that work with a large network of sub-agents",
      bannerImage: BannerB2bBooking,
      title:
        "B2B Booking Engine | B2B Travel Portal Development | B2B Travel Booking system",
      keywords:
        "B2B Travel Booking System, B2B Booking Portal, B2B Travel Booking Engine",
      desscription:
        "Let your customers book airport transfers online with our transfers booking engine. It automates your booking workflow and lets you manage your inventory in one place",
    },

    {
      page: "travel-agency-software",
      pageTitle:
        "Enhance your Sales and Operations with our Advanced Travel Agency Software",
      pageSubTitle:
        "Simplify the complexities of online booking, inventory management, back-office, accounting and more.",
      bannerImage: BannerTravelAgency,
      title:
        "Travel Agency Software | Travel ERP System | Travel Booking Software",
      keywords: "Travel Agency Software, travel erp, travel booking software",
      desscription:
        "Our Online Travel Agency Software helps travel agencies sell flights, hotels and packages online, manage reservations, connect to multiple travel suppliers, manage inventory and build quotations. Get a Quote Today!",
    },
    {
      page: "dmc-software",
      pageTitle: "Software for Destination Management Companies (DMCs)",
      pageSubTitle:
        "Increase your Bookings and Profitability with our DMC solution",
      bannerImage: BannerTourOperator,
      title: "DMC Software Solution | DMC Booking Software | TravelCarma",
      keywords: "DMC Software Solution, DMC Booking Software, TravelCarma",
      desscription:
        "Our DMC software helps Destination Management Companies distribute tour packages to B2B agents online and manage inventory & back-office on one platform.",
    },
    {
      page: "host-agency-solution",
      pageTitle: "Host Agency Solution",
      bannerImage: BannerHostAgency,
      title:
        "Powerful Affiliate Management and Online Travel Distribution Solution for Host Agencies",
      pageSubTitle:
        "This is a first-of-its kind solution designed especially for host agencies, helping them tap into their agent network to drive higher revenue, enhance agent loyalty and streamline their operations through the use of cutting-edge travel technology systems.",
      keywords: "Host Agency Solution, Distribution Solution for Host Agencies",
      desscription:
        "TravelCarma Symbion is the ultimate distribution and affiliate management solution for Host agencies, providing hosts a powerful technology platform to maximize inventory distribution and boost revenue through its affiliate network.",
    },
    {
      page: "bus-operator",
      pageTitle: "EZBus - Bus Reservation Software",
      bannerImage: BannerBusReservation,
      title:
        "Bus Booking Software | Online Bus Reservation System | Bus Booking Engine",
      keywords:
        "Bus Operator, Bus Booking Software, Online Bus Reservation System, Bus Booking Engine",
      desscription:
        "TravelCarma provides online bus reservation system for travel agencies, tour operators and bus aggregators. Our bus booking software manages your bus inventory, routes, fares, schedule, reservations, and entire back-office operations.",
    },
    {
      page: "travel-data-xchange",
      pageTitle: "TDX - Travel API Aggregation Hub",
      bannerImage: BannerTDX,
      title:
        "TDX | Travel Unified API Platform | Travel Content Aggregation and Distribution",
      keywords: "Travel Distribution Switch",
      desscription:
        "Our XML web services based travel switch allows large travel companies to aggregate and distribute data from multiple suppliers through a unified API platform.",
    },
    {
      page: "hajj-umrah",
      pageTitle: "Hajj and Umrah Booking Solution",
      bannerImage: BannerInfographics,
      title: "Hajj & Umrah Booking Software | Hajj & Umrah Booking System",
      keywords: "Hajj & Umrah Booking Software | Hajj & Umrah System",
      desscription:
        "TravelCarma provides an end-to-end Hajj & Umrah Booking System for OTAs and Umrah Operators with real-time connectivity with Maqam GDS",
    },
    {
      page: "inventory-distribution",
      pageTitle: "Travel Wholesaler Software (E-IMDP)",
      bannerImage: BannerDistribution,
      title: "Travel Wholesaler Software | Travel Distribution Platform",
      keywords:
        "Enterprise Inventory Management and Distribution Platform (E-IMDP)",
      desscription:
        "Our Travel distribution platform allows Travel Wholesalers, Tour operators and DMCs to manage and distribute their contracted inventory across multiple online channels via APIs.",
    },
    {
      page: "development-api",
      pageTitle: "Development APIs",
      pageSubTitle: "Develop your own travel applications in days, not months",
      bannerImage: BannerDevelopmentApi,
      title:
        "Development APIs Solution for Travel Industry | Travel Development API | TravelCarma",
      keywords: "Development APIs",
      desscription:
        "Our Development APIs enable travel companies to build their own travel applications with fully customized front ends, including various functionalities like agency management, booking management, customer management, pricing rules, aggregation etc.",
    },
    {
      page: "mobile-travel-apps",
      pageTitle: "Travel Mobile App Development",
      bannerImage: BannerTravelMobile,
      title: "Travel App Mobile Development",
      keywords: "Travel App Mobile Development",
      desscription: "Travel App Mobile Development",
    },
    {
      page: "travel-tech-consulting",
      pageTitle: "Travel Technology Consulting",
      bannerImage: BannerInfographics,
      title: "Travel Technology Consulting | TravelCarma",
      keywords: "Travel Technology Consulting, TravelCarma",
      desscription: "Travel Technology Consulting",
    },
    {
      page: "enterprise-software",
      pageTitle: "Travel Technology for the Tourism Industry",
      bannerImage: BannerEnterprise,
      title:
        "Travel Technology | Travel Technology Solutions for Tourism Industry",
      keywords:
        "Travel Technology, Travel Technology Solutions for Tourism Industry",
      desscription:
        "TravelCarma provides large travel companies with an end-to-end travel technology platform with custom software development and business consulting",
    },

    {
      page: "customers",
      pageTitle: "TravelCarma Customers",
      pageSubTitle:
        "We have 200+ clients in more than 55 countries. Our solutions have been fine-tuned for each of these markets and are being used successfully by travel companies of all sizes, from startups mid-sized travel agencies to large companies.",
      bannerImage: BannerCustomers,
      title:
        "TravelCarma Customers | Travel Agencies | Tour Operators | Hotels | Vehicle Operators",
      keywords:
        "travelcarma clients, travel technology solutions, travel agent software, tour operator software, bus reservation software,Software Development Services, India, testimonials, USA, Europe, Middle East, South East Asia , Africa , online travel technology , global travel customers",
      desscription:
        "We have helped hundreds of Travel Agencies, Tour Operators, Accommodation providers and Vehicle Operators become fully-fledged online travel businesses. We offer Travel Agency software, Hotel Booking Software, Car Rental Software, Bus Reservation Software",
    },
    {
      page: "customer-testimonials",
      pageTitle: "Testimonials",
      pageSubTitle:
        "Customer Satisfaction is the number one priority of our business. We at TravelCarma define our success based on the level of success our clients achieve by using our solutions.",
      bannerImage: BannerTestimonials,
      title:
        "Customer Testimonials & Reviews | TravelCarma Online Travel Solutions",
      keywords:
        "Customer Testimonials & Reviews, TravelCarma Online Travel Solutions",
      desscription:
        "Testimonials / Client Speaks about TravelCarma's Travel Software Solutions, Client Reviews about Travel Booking Software Solutions, Travel Agency Software, Travel Technology Solutions.",
    },
    {
      page: "case-studies",
      pageTitle: "Client Case Studies",
      pageSubTitle:
        "Here are a few examples of how TravelCarma has helped its clients achieve their business goals",
      bannerImage: BannerCaseStudy,
      title:
        "Travel Technology Company | Client Case Studies and Success Stories",
      keywords:
        "Travel Technology Company, Client Case Studies and Success Stories",
      desscription:
        "TravelCarma has helped hundreds of Travel and Tourism companies worldwide achieve their business goals. Here are some case studies of our satisfied customers. ",
    },
    {
      page: "travel-xml-api-integrations",
      pageTitle: "Travel API Integration",
      pageSubTitle:
        "TravelCarma has years of experience and expertise in Travel API Integration, having integrated over 85 XML API Suppliers on behalf of our clients worldwide, including all major GDSs, 130+ LCCs, Air Consolidators, Hotel and Activity Wholesalers, Car Rentals and Channel Managers.",
      bannerImage: BannerApiFlight,
      title:
        "Travel API Integration | GDS API Integration | XML API Integration",
      keywords:
        "travel supplier connectivity, XML Supplier Connectivity, Global distribution systems,  direct connect, travel & hotel XML, Consolidators, Wholesaler, Activity, Vehicles, provider, Flight API, Hotel API, Car Rental API",
      desscription:
        "TravelCarma provides travel API integration with over 85 Flight, Hotel and Car Suppliers, including GDSs like Sabre, Amadeus, TravelPort as well as Bedbanks like GTA, Hotelbeds, and RoomsXML.",
    },
    {
      page: "flight-api-integration",
      pageTitle: "Flight API Integration",
      bannerImage: BannerApiFlight,
      title: "Flight API Integration to Connect all of the Leading Airlines",
      keywords: "Flight API Integration",
      desscription:
        "TravelCarma provides Flight API integration with GDSs like Amadeus, Sabre & Travelport for Travel Agencies and Tour operators.",
    },
    {
      page: "hotel-api-integration",
      pageTitle: "Hotel API Integration",
      bannerImage: BannerApiHotel,
      title:
        "Hotel API Integration - Connect your Travel Agency to Global Hotel Suppliers",
      keywords:
        "Hotel API Integration - Connect your Travel Agency to Global Hotel Suppliers",
      desscription:
        "TravelCarma is a leading Hotel API Integration provider that integrates top hotel suppliers like Hotelbeds, GTA & Agoda to deliver the best online hotel booking solution.",
    },
    {
      page: "sightseeing-api-integration",
      pageTitle: "Activities & Sightseeing API Integration",
      bannerImage: BannerApiActivities,
      title:
        "Activities & Sightseeing API Integration for Travel Agencies worldwide",
      keywords:
        "Activities & Sightseeing API Integration for Travel Agencies worldwide",
      desscription:
        "We are the best Sightseeing API Integration provider to make your client's holiday memorable by offering global travel inventory on one platform.",
    },
    {
      page: "transfer-api-integration",
      pageTitle: "Transfers & Car Rental API Integration",
      bannerImage: BannerApiTransfer,
      title:
        "Transfers and Car Rental API Integration with top Ground Transportation Suppliers",
      keywords:
        "Transfers and Car Rental API Integration with top Ground Transportation Suppliers",
      desscription:
        "TravelCarma provides API integration with all the major transfers and car rental suppliers including Cartrawler, I'way, HotelBeds & Priceline.",
    },

    {
      page: "ebooks",
      pageTitle: "TravelCarma Ebooks",
      pageSubTitle:
        "Download our Free e-books for useful tips and tricks on how to grow your Travel Business",
      bannerImage: BannerEbooks,
      title: "Ebooks",
      keywords: "ebooks",
      desscription: "TravelCarma Ebooks",
    },
    {
      page: "infographics",
      pageTitle: "Infographics",
      pageSubTitle:
        "We regularly produce high quality infographics that help travel firms stay abreast.",
      bannerImage: BannerInfographics,
      title:
        "TravelCarma Infographics | Tourism & Travel Technology Infographics",
      keywords:
        "TravelCarma Infographics | Tourism & Travel Technology Infographics",
      desscription:
        "Download our infographics to stay abreast with the latest industry trends and tips & guides on how to run your travel business successfully",
    },
    {
      page: "videos",
      pageTitle: "Videos",
      bannerImage: BannerInfographics,
      title: "Travelcarma | Videos",
      keywords: "Travelcarma |  Videos",
      desscription: "Travelcarma |  Videos",
    },

    {
      page: "faqs",
      pageTitle: "Frequently Asked Questions",
      pageSubTitle:
        "Have questions about our products? You'll find answers to the most common questions we're asked by customers in this section. For more in-depth discussions, please get in touch with us",
      bannerImage: BannerInfographics,
      title: "TravelCarma Frequently Asked Questions",
      keywords:
        "Travel Agency FAQ, Frequently Asked Questions, Travel agency system FAQ, Travel agent system FAQ",
      desscription: "Travel Agency FAQ, Frequently Asked Questions",
    },
    {
      page: "contact-us",
      pageTitle: "Contact Us",
      pageSubTitle:
        "Interested in our solutions ? Simply fill out the form below and we'll get back to you within 24 hours!",
      bannerImage: BannerContactUs,
      title: "Contact Us - TravelCarma Travel Technology Solutions",
      keywords: "Contact Us, Contact TravelCarma",
      desscription:
        "Inquire now for Online Travel Solutions. Email :sales@travelcarma.com, Phone US: +1 904 302 7278, UK: +44 203 051 6811",
    },
    {
      page: "privacypolicy",
      pageTitle: "Privacy and Data Protection Policy",
      bannerImage: BannerPrivacyPolicy,
      title: "Privacy and Data Protection Policy",
      keywords: "Privacy and Data Protection Policy",
      desscription: "Privacy and Data Protection Policy",
    },
  ];

  const getPageTitle = () => {
    let data = "";
    data = pageData.find((x) => x.page === page);
    setData(data);
  };

  useEffect(() => {
    getPageTitle();
  }, []);

  return (
    <React.Fragment>
      <div className="page-title d-flex align-items-center mb-5">
        <div className="container text-center">
          <h1 className="text-white m-0 fw-bold">{data?.pageTitle}</h1>
          {data?.pageSubTitle && (
            <h5 className="text-white mt-2">{data?.pageSubTitle}</h5>
          )}
        </div>

        <img
          className="page-title-img"
          src={data?.bannerImage}
          alt={data?.pageTitle}
        />
      </div>

      <Helmet>
        <title>{data?.title}</title>
        <meta name="keywords" content={data?.keywords} />
        <meta name="description" content={data?.desscription} />
      </Helmet>
    </React.Fragment>
  );
};

export default PageHeader;
