import React from "react";
import PageHeader from "../components/page-header";
import AdventiaClipart from "../assets/images/solutions/adventia-dmc-clipart.png";
import AdventiaBox from "../assets/images/solutions/adventia-box.png";
import { Link } from "react-router-dom";
import ImageSlider from "../components/image-slider";

const SolutionsDmc = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="dmc-software" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <img
              src={AdventiaClipart}
              alt="Travel ERP System"
              className="w-100"
              style={{ maxWidth: "382px" }}
            />
          </div>
          <div className="col-lg-6 text-justify">
            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              Adventia is a cloud-hosted solution for large DMCs that allows you
              to manage all your inventory, tour bookings, B2B agents and
              back-office on a SaaS model. It allows you to design tour
              packages, apply business rules and sell them to your customers
              online.
            </p>

            <p style={{ fontSize: "1.1rem", lineHeight: "30px" }}>
              The biggest USP of our solution over others in the market is that
              while the application is SaaS, the CRS and reporting is fully
              customizable and the database can be provided separately. So our
              solution gives DMCs the advantages of SaaS but at the same time
              allows them full control over their data and reporting.
            </p>

            <Link to="/contact-us" className="btn btn-primary">
              Request a Demo
            </Link>
          </div>
        </div>

        <h2 className="text-center mt-5 mb-5">
          Advantages of Adventia for your DMC
        </h2>

        <div className="solution-highlights mb-5 pb-5">
          <div className="row">
            <div className="col-lg-5">
              <div className="d-flex align-items-center mt-3 mb-4 pb-4 border-bottom">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 20 20"
                    height="40px"
                    viewBox="0 0 20 20"
                    width="40px"
                  >
                    <g>
                      <rect fill="none" height="20" width="20" x="0" y="0" />
                      <g>
                        <path d="M4,16h10v1H4c-0.55,0-1-0.45-1-1V6h1V16z M17,4v9c0,0.55-0.45,1-1,1H7c-0.55,0-1-0.45-1-1V4c0-0.55,0.45-1,1-1h9 C16.55,3,17,3.45,17,4z M15.04,6.38l-0.71-0.71l-4.24,4.24L8.67,8.5L7.96,9.21l2.12,2.12L15.04,6.38z" />
                      </g>
                    </g>
                  </svg>
                </figure>

                <h4 className="mb-0">
                  Flexibility to pick and choose components as and when you need
                </h4>
              </div>
              <div className="d-flex align-items-center mb-4 pb-4">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="40px"
                    viewBox="0 0 24 24"
                    width="40px"
                    fill="#000000"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M17 16l-4-4V8.82C14.16 8.4 15 7.3 15 6c0-1.66-1.34-3-3-3S9 4.34 9 6c0 1.3.84 2.4 2 2.82V12l-4 4H3v5h5v-3.05l4-4.2 4 4.2V21h5v-5h-4z" />
                  </svg>
                </figure>
                <h4 className="mb-0">
                  Ability to distribute your products via API to OTAs, Global
                  Tour Operators and Wholesalers
                </h4>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <img
                style={{ maxWidth: "84%" }}
                src={AdventiaBox}
                alt="Adventia DMC Software"
              />

              <Link to="/contact-us" className="btn btn-primary mt-4">
                Request a Demo
              </Link>
            </div>
            <div className="col-lg-5">
              <div className="d-flex align-items-center mt-3 mb-4 pb-4 border-bottom">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="40px"
                    viewBox="0 0 24 24"
                    width="40px"
                    fill="#000000"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M20 18c1.1 0 1.99-.9 1.99-2L22 5c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2H0c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2h-4zM4 5h16v11H4V5zm8 14c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
                  </svg>
                </figure>
                <h4 className="mb-0">
                  B2B Booking Engines available as APIs and source code to help
                  you customize front ends
                </h4>
              </div>
              <div className="d-flex align-items-center mb-4 pb-4">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 25 25"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
                      fill="#444444"
                    ></path>
                  </svg>
                </figure>
                <h4 className="mb-0">
                  Scalable platform that can handle over 10k bookings/day
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row text-center solutions-section">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-5">
                <h2>Automate your Sales</h2>
                <p>
                  Let your customers book your{" "}
                  <strong>tour packages online</strong>, 24x7! Our portals are
                  attractive and optimized for mobiles and tablets so that you
                  can take bookings both on the web as well as on mobile
                  devices. Moreover, they are CMS based, making it extremely
                  easy for you to customize the site and manage all its content
                  yourself.
                </p>
                <Link to="/contact-us" className="btn btn-primary">
                  Request a Demo
                </Link>
              </div>
              <div className="col-lg-7 d-flex">
                <div className="product-slider shadow-sm w-100">
                  <ImageSlider slider="DMS1" />
                </div>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Mobile and Search-Engine friendly</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Offer your customers a seamless online experience on both web
                  and mobile, so your clients can browse and book your tours
                  from anywhere, anytime! You can easily update the content and
                  meta tags of your website to ensure that the content always
                  stays relevant and high quality for both your visitors and
                  search engines.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Online Payments</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  You can sell your solutions to agencies anywhere in the world
                  without worrying about credit and payments, and take payments
                  online to remove the necessity of taking deposits and bank
                  guarantees. Our DMC solution even offers a book and pay later
                  functionality.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Real Time Booking</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Let your <strong>customers/agents</strong> search and book
                  your <strong>tours/excursions</strong> with real-time
                  availability and pricing through our{" "}
                  <strong>B2C/B2B booking engine</strong>. Our Booking Engine is
                  integrated with 30+ <strong>Payment Gateways</strong>, giving
                  your customers a wide range payment options.
                </p>
              </div>
            </div>
            <div className="solutions-separator"></div>
            <div className="row">
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="DMS2" />
              </div>
              <div className="col-lg-5">
                <h2>Build Packages</h2>
                <p>
                  Design Fixed packages as well as Custom packages. Offer your
                  customers the flexibility to choose different flight, hotel,
                  activity and vehicle options from a standard package.
                </p>
                <Link to="/contact-us" className="btn btn-primary">
                  Request a Demo
                </Link>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Multiple Packaging Options</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  You can load all your inventory in our{" "}
                  <strong>central reservation system</strong> and create fixed
                  packages with fixed components (hotels / vehicles /
                  activities), itineraries and rates and then publish them on
                  your B2C/B2B portal.You can also create a custom package as
                  per the requirements of your customer and share it with them
                  via email.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Shopping Cart</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Allows your B2B agents to build their own packages
                  (with/without flights) using your inventories of
                  accommodation, activities, transportation, fixed tours etc
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Group Trip Planner</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Let your registered customers create their own trip plans and
                  share it with their co-travelers via email, invite them to
                  discuss the plan on the portal and make the booking for the
                  entire group. The plan can be saved with a validity date and
                  customer can book the same plan at a later date with real-time
                  rates and availability checks.
                </p>
              </div>
            </div>
            <div className="solutions-separator"></div>
            <div className="row">
              <div className="col-lg-5">
                <h2>Manage your Inventory</h2>
                <p>
                  Easily set up and manage complete details of all your
                  contracted inventory of hotels, packages, activities and
                  transfers along with availability and net rates. Our inventory
                  system can be fully customized to fit your needs.
                </p>
                <Link to="/contact-us" className="btn btn-primary">
                  Request a Demo
                </Link>
              </div>
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="DMS3" />
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Control Pricing</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Once you have loaded your inventory into the{" "}
                  <Link
                    to="/products/inventory-management-system"
                    className="text-decoration-none text-primary"
                  >
                    Inventory Management System(CRS)
                  </Link>
                  , you can easily add markups and/or commissions to the net
                  rates and control the price to be shown on your website when
                  somebody makes a search using the booking engine.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Set up Business Rules</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  You can also set up business rules, policies, promotions and
                  differential pricing based on season, customer class etc as
                  well as stop sell and black out dates(for hotels).
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Extranet</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  With <strong>TravelCarma</strong>,{" "}
                  <strong>DMCs/tour operators</strong> can also allow vendors to
                  log into the CRS so that they can directly manage their
                  inventory. This saves you the trouble of updating the vendor's
                  hotel/car inventory every time.
                </p>
              </div>
            </div>
            <div className="solutions-separator"></div>
            <div className="row">
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="DMS4" />
              </div>
              <div className="col-lg-5">
                <h2>Quote Quicker</h2>
                <p>
                  Build quotations for even the most complex tours within
                  minutes and easily share them with your customers via email,
                  thus reducing your turnaround time and cost and helping you
                  service 5x more queries.
                </p>
                <Link to="/contact-us" className="btn btn-primary">
                  Request a Demo
                </Link>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Reduce your effort</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  You or your downline agents can select multiple items from
                  your inventory, build a quotation and book all the items
                  together in a single transaction, thus reducing your effort
                  considerably.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Shorten your Turnaround Time</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Create a quote within 10 to 30 minutes and share it with your
                  customers via email, a process which generally takes 6 hours
                  to a day. The time and cost you save lets you focus on
                  building better tours, servicing more queries and generating
                  more bookings.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Reuse Saved Quotations</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Our system allows you to save a quotation you create for one
                  customer for providing similar quotations to other customers,
                  but with the latest prices. They are like stored packages but
                  flexible in nature wherein you can easily add or remove items
                  from it and get the updated price which you can share with
                  another customer.
                </p>
              </div>
            </div>
            <div className="solutions-separator"></div>
            <div className="row">
              <div className="col-lg-5">
                <h2>Streamline Operations</h2>
                <p>
                  Automate routine admin functions and manage your business
                  rules, agents and customers from a central location on the
                  web, so that you don't have to shuffle through multiple
                  screens. Adventia enhances productivity and helps you minimize
                  errors.
                </p>
                <Link to="/contact-us" className="btn btn-primary">
                  Request a Demo
                </Link>
              </div>
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="DMS5" />
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Back-office Automation</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Automate your entire <strong>online booking</strong> flow
                  along with cancellations and accounting functions such as
                  accounts payable, accounts receivable and reconciliation.
                  What's more, our system automatically generates weekly,
                  monthly and annual reports to make it easy for you to keep a
                  track of your bookings, revenue and costs.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Branded Travel Documents</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  With <strong>TravelCarma</strong>, you can effortlessly create
                  professional travel documents for your customers, including
                  quotations, itineraries(with photos and maps), confirmations
                  and vouchers, all with your own branding. Through our{" "}
                  <strong>agent portal</strong>, your agents can also book your
                  packages and issue documents from their end, branded with
                  their own logos.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">B2B Agent Management</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Let your your agents access your inventory through our{" "}
                  <strong>B2B/B2B2C portals</strong> and make bookings on your
                  behalf. Easily categorize agents into classes and apply
                  markups, commissions as well as promotional/special rates on
                  the different classes. Get in-depth sales reports telling you
                  who your bestselling agents are so you can reward them
                  accordingly.
                </p>
              </div>
            </div>
            <div className="solutions-separator"></div>
            <div className="row">
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="DMS6" />
              </div>
              <div className="col-lg-5">
                <h2>Integrate Third-party APIs</h2>
                <p>
                  TravelCarma allows <strong>DMCs/Tour Operators</strong> to
                  easily connect to multiple suppliers and sell from a global
                  pool of third-party hotel, activity and vehicle inventory, in
                  addition to direct connects. We have integrated over 85 global
                  suppliers for flights, hotels, activities and vehicles.
                </p>
                <Link to="/contact-us" className="btn btn-primary">
                  Request a Demo
                </Link>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Global Content</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Get access to inventory across a wide range of{" "}
                  <strong>wholesalers and consolidators in the world</strong>,
                  including Hotelspro, HotelBeds and GTA for hotels, Viator for
                  activities and CarTrawler, Hertz and Avis for car rentals.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Easy Integration</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Get easily connected to multiple suppliers with live feeds via
                  a unified <strong>XML interface</strong>. Our system allows
                  you to initiate a single search against multiple suppliers and
                  receive a single integrated response.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Inventory De-duplication</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Our data de-duplication and products mapping process removes
                  duplicates and ensures that you are selling unique and best
                  priced inventories from your system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsDmc;
