import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import NewsImg1 from "../assets/images/company/news/news-1.jpg";
import NewsImg2 from "../assets/images/company/news/news-2.jpg";
import NewsImg3 from "../assets/images/company/news/news-3.jpg";
import NewsImg4 from "../assets/images/company/news/news-4.jpg";
import NewsImg5 from "../assets/images/company/news/news-5.jpg";
import NewsImg6 from "../assets/images/company/news/news-6.jpg";
import NewsImg7 from "../assets/images/company/news/news-7.jpg";
import NewsImg8 from "../assets/images/company/news/news-8.jpg";
import NewsImg9 from "../assets/images/company/news/news-9.jpg";
import NewsImg10 from "../assets/images/company/news/news-10.jpg";
import NewsImg11 from "../assets/images/company/news/news-11.jpg";
import NewsImg12 from "../assets/images/company/news/news-12.jpg";
import NewsImg13 from "../assets/images/company/news/news-13.jpg";
import NewsImg14 from "../assets/images/company/news/news-14.jpg";
import NewsImg15 from "../assets/images/company/news/news-15.jpg";
import NewsImg16 from "../assets/images/company/news/news-16.jpg";
import NewsImg17 from "../assets/images/company/news/news-17.jpg";
import NewsImg18 from "../assets/images/company/news/news-18.jpg";
import NewsImg19 from "../assets/images/company/news/news-19.jpg";

const CompanyNews = () => {
  return (
    <div className="page-inside">
      <PageHeader page="news" />
      <div className="container">
        <div class="news-container-wrap">
          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg1}
                alt="TravelCarma Introduces a Resilience Package"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                TravelCarma Introduces a 'Resilience Package' to help Travel
                Agencies Bounce Back from COVID
              </h5>
              <p className="text-secondary">
                To say that COVID-19 crippled the tourism industry would be an
                understatement. According to reports, the industry has incurred
                losses to the tune of around $1.25 trillion due to the pandemic.
                Millions of people have been furloughed or let go. Traveler
                confidence has diminished greatly. Whatever happens, one thing
                is for sure - Travel will never be the same again. A 'New
                Normal' is emerging and industry has no choice but to adapt to
                this new normal. So the big question is - How can the industry
                bounce back from COVID?
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg2}
                alt="TravelCarma Awarded by SoftwareSuggest"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                TravelCarma Awarded 'Supreme Software' and 'User Recommended
                Software' by SoftwareSuggest
              </h5>
              <p className="text-secondary">
                TravelCarma has been recognized by SoftwareSuggest in two
                categories of their 2020 edition of annual software awards.
                TravelCarma's Travel ERP Emperia has been awarded 'Supreme
                Software' and 'User Recommended Software' SoftwareSuggest is a
                leading online platform for Business Software discovery which
                simplifies the process by Listing, Reviewing and Comparing
                Business Software. SoftwareSuggest helps software vendors
                discover and connect with verified prospects....
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg3}
                alt="TravelCarma to Showcase its Travel Technology Solutions at ITB Asia 2019"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                TravelCarma to Showcase its Travel Technology Solutions at ITB
                Asia 2019
              </h5>
              <p className="text-secondary">
                TravelCarma is proud to announce its participation at ITB Asia
                2019, taking place in Singapore from 16th-18th October, 2019.
                ITB Asia is the leading tourism trade show in Asia, and among
                the biggest in the world, attracting hundreds and thousands of
                attendees from various segments including Travel Agencies, OTAs,
                DMCs, Hotels, Airlines and Travel Trade Associations.
                TravelCarma will showcase its suite of travel technology
                solutions that includes...
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg4}
                alt="TravelCarma announces a strategic partnership with GIATA"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                TravelCarma announces a strategic partnership with GIATA
              </h5>
              <p className="text-secondary">
                TravelCarma, a leading Travel Technology company providing
                Travel ERP and distribution solutions to OTAs, DMCs and
                Wholesalers, is now integrated with GIATA, the world's largest
                hotel database. GIATA MultiCodes currently boasts of over 70
                million mappings of 540 suppliers for 1 million unique objects,
                making it the largest hotel mapping database in the world. This
                integration would allow TravelCarma clients with multiple hotel
                API suppliers to leverage GIATA's hotel mapping...
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg5}
                alt="Benefits of a Multi Lingual Website for Travel Firms"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                TravelCarma is now integrated with Kiwi.com
              </h5>
              <p className="text-secondary">
                It has always been our endeavor to offer best-in-class solutions
                to our clients to help them achieve their business goals. This
                includes regularly adding new travel suppliers onto the platform
                and enabling our clients the flexibility to distribute feeds
                from multiple suppliers from a single platform. We have already
                integrated over 80 suppliers, including all major GDSs,
                consolidators, bedbanks as well as activity and ground
                transportation suppliers worldwide. We are happy to announce...
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg6}
                alt="Benefits of a Multi Lingual Website for Travel Firms"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                Announcing our participation at ATM '16, Dubai
              </h5>
              <p className="text-secondary">
                We are proud to announce our participation at Arabian Travel
                Market (ATM), Dubai, from 25th to 28th April, 2016. ATM is the
                most important tourism event of the Middle East held annually
                and attended by 26000 professional visitors from all over the
                world. It displays 2800 product companies and tourist
                destinations.UAE has emerged as the tourism hub of the Middle
                East in recent times, with Dubai alone attracting 14.2 million
                overnight visitors in 2015. Tourism contributes around USD 35
                billion to UAE's GDP growing...
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg7}
                alt="Benefits of a Multi Lingual Website for Travel Firms"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                TravelCarma to showcase its Travel Technology Solutions at WTM
                Brazil 2016
              </h5>
              <p className="text-secondary">
                TravelCarma, one of the world's leading travel technology
                companies, will be exhibiting at WTM Latin America 2016 in Sao
                Paulo, Brazil from 29th to 31st March, 2016. WTM LATAM is the
                leading travel trade show of Latin America which brings together
                9000 travel, media and travel trade professionals. These people
                represent over 60 countries. Latin America has seen a stupendous
                growth in tourism in recent times. Latin America's travel and
                tourism industry enjoyed a growth rate of around 7.2% in 2015.
                WTM LATAM ...
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg8}
                alt="Benefits of a Multi Lingual Website for Travel Firms"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                TravelCarma to exhibit at SATTE '16 in New Delhi, India
              </h5>
              <p className="text-secondary">
                TravelCarma is proud to announce its visit to SATTE, New Delhi,
                from 29th to 31st January, 2016.SATTE is South Asia's leading
                Travel trade fair, having attracted over 16000 travel
                professionals from more than 35 countries in the previous
                edition. During the three day event, TravelCarma will showcase
                their cloud-based online reservation platform, which is
                available at a cost-effective monthly subscription.
                TravelCarma's SaaS (Software-as-a-service) platform allows
                travel businesses...
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg9}
                alt="Benefits of a Multi Lingual Website for Travel Firms"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                TravelCarma introduced its Innovation “TravelCarma The
                eMarketplace” at PhoCusWright's “The Travel Innovation Summit”,
                Hollywood, Florida, USA
              </h5>
              <p className="text-secondary">
                Travel Technology provider TravelCarma introduced its Innovation
                "TravelCarma The eMarketplace" at PhoCusWright's The Travel
                Innovation Summit on 15 Nov 2011, Hollywood, USA. TravelCarma is
                an Online Marketplace for the offline travel industry. It
                fulfills the much-needed requirement of the industry, by
                providing a platform which allows offline and online travel
                businesses to network and transact freely on their own terms...
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg10}
                alt="TravelCarma to showcase its Innovation at PhoCusWright's The Travel Innovation Summit"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                TravelCarma to showcase its Innovation at PhoCusWright's “The
                Travel Innovation Summit”
              </h5>
              <p className="text-secondary">
                Travel Technology provider TravelCarma has been selected to
                showcase its Innovation at PhoCusWright's "The Travel Innovation
                Summit" on 15 Nov 2011, US. TravelCarma, the sole entry from
                India, is proud to share space amongst world's leading 30
                innovators who will demonstrate their applications, technologies
                and solutions which they expect will forever change the face of
                travel planning, purchasing and memorializing.
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg11}
                alt="Meet TravelCarma representatives at the ITE HCMC 2011, Vietnam!"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                Meet TravelCarma representatives at the ITE HCMC 2011, Vietnam!
              </h5>
              <p className="text-secondary">
                We are glad to announce our participation in ITE HCMC 2011, at
                Vietnam (14 - 17 Sep'11). We would like to meet you to discuss
                synergies and doing business together for mutual benefits. We
                are interested in meeting Travel Agencies, Tour Operators,
                Wholesalers, OTAs, Hotels, and Destination Marketing Companies
                to discuss how we can help them increase their revenues through
                effective use of software solutions. We also want to meet local
                IT companies who would like to represent us in their region as a
                reseller.
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg12}
                alt="TravelCarma awarded as best SaaS system for Travel industry"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                TravelCarma awarded as best SaaS system for Travel industry
              </h5>
              <p className="text-secondary">
                TravelCarma's suite of SaaS based solutions for Online Booking
                and Distribution, bags award of "Top 8 SaaS-AppS" at NASSCOM
                EMERGEOUT Conclave on 7th June 2011, India. From the list of 50+
                SaaS systems, TravelCarma is recognized as the best in Travel
                industry segment. NASSCOM along with Zinnov did the evaluation
                based on criteria such as: uniqueness of the product, customers,
                no. of users for the product, marketability and the potential,
                online support, usability, performance, and upgrade capability
                etc.
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg13}
                alt="Thailand based association launches Hotel Reservation Portal using technology of TravelCarma"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                Thailand based association launches Hotel Reservation Portal
                using technology of TravelCarma
              </h5>
              <p className="text-secondary">
                Thai Hotel and Hospitality Management Association (THMA) in
                partnership with TravelCarma - a division of Avani Cimcon
                Technologies. has recently launched Hotel Reservation Portal to
                provide global exposure to small hotels of Thailand. The portal
                is branded as Small Hotel Alliance www.smallhotelalliance.com,
                which promotes Thai hotels part of THMA association.
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg14}
                alt="Collaborative Travel Planning Tool by TravelCarma"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                Collaborative Travel Planning Tool by TravelCarma
              </h5>
              <p className="text-secondary">
                TravelCarma - a Div. of Avani Cimcon Technologies. has launched
                yet another one-of-its-kind Collaborative Travel Planning Tool
                that helps travel businesses make their customers' buying
                experience much engaging than ever. Collaborative Travel
                Planning Tool along with trend-settler Facebook Booking Engine
                asserts its marketing position as leader in B2C solution for
                Travel and Hospitality Industry. Collaborative Travel Planning
                Tool allows potential booker to select hotels, activities,
                rental vehicles for specific travelling dates and create the
                travelling...
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg15}
                alt="TravelCarma inks contract with one of the leading destination agents in Africa"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                TravelCarma inks contract with one of the leading destination
                agents in Africa
              </h5>
              <p className="text-secondary">
                TravelCarma - a Div. of Avani Cimcon Technologies inks major
                deal with Kenya based destination agent; wherein TravelCarma
                will provide technology to take destination agent's business
                online and help them significantly increase their profitability.
                TravelCarma's solution includes CMS driven Travel Portal,
                Internet Booking Engine, reservation system (CRS) to manage
                contracted rates, Sales and Channel Management System to handle
                B2B, B2E, B2C and travel distribution partners' business.
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg16}
                alt="TravelCarma launches Facebook Booking Engine for Global Travel Customers"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                TravelCarma launches Facebook Booking Engine for Global Travel
                Customers
              </h5>
              <p className="text-secondary">
                After comprehensive research on user browsing patterns of social
                media platforms namely Facebook and twitter TravelCarma.com has
                successfully launched its Facebook Booking Engine for Hoteliers
                and Travel portals across the Globe. The Facebook travel
                solutions package of which the booking engine is a part is an
                add-on to TravelCarma's suite of online solutions for the Travel
                &amp; Hospitality Industry. "It's been a tremendous achievement
                for us to launch this futuristic Facebook booking engine for all
                our customers which will...
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg17}
                alt="THMA (Thai Hotel and Hospitality Management Association) selects Avani Cimcon Technologies (TravelCarma) as their Technology Partner for their Travel Portal"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                THMA (Thai Hotel and Hospitality Management Association) selects
                Avani Cimcon Technologies (TravelCarma) as their Technology
                Partner for their Travel Portal
              </h5>
              <p className="text-secondary">
                The Thai Hotel and Hospitality Management Association (THMA)
                announced today that they have awarded Avani Cimcon Technologies
                the contract to utilize Avani Cimcon's travel solutions for
                their On-line Direct Travel &amp; Booking Portal and as their
                Travel Technology Consultants. Customers will direct-book
                hotels, auto rentals, excursions and restaurants by using the
                state of the art booking engine.
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg18}
                alt="The Aruba Hotel and Tourism Association (AHATA) selects Avani Cimcon Technologies (TravelCarma) as their Technology Partner for Destination Portal"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                The Aruba Hotel and Tourism Association (AHATA) selects Avani
                Cimcon Technologies (TravelCarma) as their Technology Partner
                for Destination Portal
              </h5>
              <p className="text-secondary">
                The Aruba Hotel and Tourism Association (AHATA) announced today
                that they have awarded Avani Cimcon Technologies the contract to
                build their next generation destination portal. The new
                destination portal will provide a complete experience allowing
                the user to easily find, navigate and book their entire travel
                package with the association's 120+ members. Air, hotel,
                restaurants and excursions will be among the options offered...
              </p>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-4">
              <img
                className="w-100 rounded"
                src={NewsImg19}
                alt="Avani Cimcon Technologies awarded as “Top 100 IT Innovators” by NASSCOM"
              />
            </div>
            <div class="col-lg-8">
              <h5 className="text-primary mb-3">
                Avani Cimcon Technologies awarded as “Top 100 IT Innovators” by
                NASSCOM
              </h5>
              <p className="text-secondary">
                Avani Cimcon Technologies is proud to announce the recent
                achievement of our software solution - DXchange. We have been
                short-listed by NASSCOM to be profiled in the listing of
                NASSCOM's 100 IT Innovators - 2007 at
                www.nasscom.in/100itinnovators. DXchange is short-listed as one
                of the best innovation in the category of "Market facing
                innovation". This initiative of NASSCOM recognizes the
                organizations that have made innovation a part of their DNA and
                developed extraordinary and pioneering products and...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyNews;
