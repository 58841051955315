import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import SuppliersHotel from "../components/suppliers-hotel";

const ApiHotelIntegration = () => {
  return (
    <div>
      <PageHeader page="hotel-api-integration" />
      <div className="container">
        <div className="text-center">
          <p>
            Looking to sell third-party <strong>hotel inventory</strong> on your
            online booking portal? With TravelCarma you can integrate all your{" "}
            <b>Hotel APIs</b> into one{" "}
            <Link
              to="/products/booking-engines/hotel-booking-engine"
              className="text-decoration-none text-primary"
            >
              Hotel Booking Engine
            </Link>{" "}
            to offer your customers the best possible rates for accommodation on
            your own website. We have integrated over 40{" "}
            <b>Hotel API suppliers</b>, including global Bedbanks like
            Hotelbeds, Tourico, GTA and DOTW, as well as OTAs like Expedia,
            Priceline, MakeMyTrip and Cleartrip.
          </p>

          <p>
            With our Hotel API Integration you can sell live hotel content via
            multiple online channels - B2C, B2B, B2E and B2B2C(Sub-agent private
            label portals).
          </p>

          <SuppliersHotel />
        </div>
      </div>
    </div>
  );
};

export default ApiHotelIntegration;
