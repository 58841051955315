import React from "react";
import PageHeader from "../components/page-header";
import Award1 from "../assets/images/company/software-suggest-1.jpg";
import Award2 from "../assets/images/company/software-suggest-2.jpg";
import Award3 from "../assets/images/company/best-value.png";
import Award4 from "../assets/images/company/nasscom-award.jpg";
import Award5 from "../assets/images/company/it-innovators.jpg";

import { Link } from "react-router-dom";

const CompanyAwards = () => {
  return (
    <div className="page-why-us">
      <PageHeader page="awards" />
      <div className="container">
        <p className="text-center mb-5">
          We, at TravelCarma have managed to stand out and differentiate
          ourselves in a highly competitive, adverse market condition and are
          proud to be a part of this wonderful league of Travel Technology
          Innovators. Innovation is an important part of our growth strategies
          and has emerged with an Intellectual Property.
        </p>

        <div>
          <h2 className="text-center text-primary mb-5">
            SoftwareSuggest Recognition
          </h2>

          <p className="text-center">
            TravelCarma has been recognized in 3 categories by Software Suggest
            - 'Supreme Software', 'User Recommended Software' and 'Best Value'
          </p>

          <div className="row mt-4">
            <div className="col-lg-4 mb-3">
              <div
                className="p-4 rounded"
                style={{
                  boxShadow:
                    "0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset",
                }}
              >
                <img
                  src={Award1}
                  alt="TravelCarma Award- Software Suggest - User Recommended Software"
                  className="w-100"
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div
                className="p-4 rounded"
                style={{
                  boxShadow:
                    "0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset",
                }}
              >
                <img
                  src={Award2}
                  alt="TravelCarma Award- Software Suggest - User Recommended Software"
                  className="w-100"
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div
                className="p-4 rounded"
                style={{
                  boxShadow:
                    "0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset",
                }}
              >
                <img
                  src={Award3}
                  alt="TravelCarma Award- Software Suggest - User Recommended Software"
                  className="w-100"
                  style={{ maxHeight: "218px", objectFit: "contain" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="section-separator"></div>

        <div className="text-center">
          <h2 className="text-center text-primary mb-5">
            PhoCusWright's The Travel Innovation Summit
          </h2>
          <p>
            Oct 2011: Travel Technology provider TravelCarma has been selected
            to showcase its Innovation at PhoCusWright's "The Travel Innovation
            Summit” on 15 Nov 2011, US. TravelCarma, the sole entry from India,
            is proud to share space amongst world's leading 30 innovators who
            will demonstrate their applications, technologies and solutions
            which they expect will forever change the face of travel planning,
            purchasing and memorializing. From the technology standpoint,
            TravelCarma's innovation - “A Cloud-based Applications Delivery
            Platform integrated with an Open-Distribution based TravelXchange”
            provides travel companies with multi-platform POS and distribution
            of travel content across multiple channels.
          </p>
          <p>
            TravelCarma, Travel Technology Solutions Provider, presenting
            eMarketplace at PhoCusWright , Please Visit and Check Video
          </p>
          <p>
            <Link
              className="text-primary"
              to="https://www.youtube.com/embed/wWCxb7FyGaI"
              target="_blank"
            >
              https://www.youtube.com/embed/wWCxb7FyGaI
            </Link>
          </p>
        </div>

        <div className="section-separator"></div>

        <div className="text-center">
          <h2 className="text-center text-primary mb-5">
            Top SaaS-AppS for Travel Industry
          </h2>
          <p>
            TravelCarma's suite of SaaS based solutions bags award of "Top 8
            SaaS-AppS" at NASSCOM EMERGEOUT Conclave, India. From the list of
            50+ SaaS systems, TravelCarma is recognized as the best in Travel
            industry segment. The evaluation was based on criteria such as:
            uniqueness of the product, customers, no. of users for the product,
            marketability and the potential, online support, usability,
            performance, and upgrade capability etc.
          </p>
          <div className="row mt-4">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div
                className="p-4 rounded"
                style={{
                  boxShadow:
                    "0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset",
                }}
              >
                <img
                  src={Award4}
                  alt="Top SaaS-AppS for Travel Industry"
                  className="w-100"
                />
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>

        <div className="section-separator"></div>

        <div className="text-center">
          <h2 className="text-center text-primary mb-5">
            Top 100 IT Innovators
          </h2>
          <p>
            Avani Cimcon Technologies' flagship product “DXchange” has been
            selected as “Top 100 IT Innovation” by NASSCOM in 2007. DXchange is
            a Data Exchange Middleware with distributed component-based services
            oriented architecture. It forms an XML Web Services based
            communications infrastructure for an organization/application.
          </p>
          <div className="row mt-4">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div
                className="p-4 rounded"
                style={{
                  boxShadow:
                    "0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset",
                }}
              >
                <img
                  src={Award5}
                  alt="Top 100 IT Innovators"
                  className="w-100"
                />
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAwards;
