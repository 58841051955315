import React, { useState } from "react";
import PageHeader from "../components/page-header";
import UmrahSolutionOverview from "../assets/images/solutions/umrah-solution-overview.png";
import HubsBox from "../assets/images/solutions/hubs-box.png";
import LappyUmrah from "../assets/images/solutions/lappy-umrah.png";
import ImageSlider from "../components/image-slider";

const SolutionsHajjUmrah = () => {
  const [activeTab, setActive] = useState("tab1");

  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="hajj-umrah" />
      <div className="container">
        <p className="text-center mb-3">
          Hajj and Umrah is expected to be a $150 billion+ market by 2022.
          TravelCarma helps Umrah operators and agencies capture this booming
          market through an end-to-end Umrah Booking solution that covers
          everything from reservations, e-visa application, CRM, itineraries to
          accounting.
        </p>

        <p className="text-center mb-5">
          TravelCarma Hajj & Umrah Booking Solution (HUBS) is among the world's
          first solutions to provide real-time connectivity with the Maqam GDS
          (Also built by TravelCarma), allowing External Agencies, Umrah
          Operators and Mutamers to book packages as well as individual flights,
          hotels and ground transportation via authorized OTAs.
        </p>

        <div className="solution-highlights pb-5">
          <div className="row">
            <div className="col-lg-5">
              <div className="d-flex align-items-center mt-3 mb-4 pb-4 border-bottom">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 25 25"
                  >
                    <path
                      d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
                      fill="#444444"
                    ></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </figure>

                <h4 className="mb-0">25000+ B2B Agents on the platform</h4>
              </div>
              <div className="d-flex align-items-center mb-4 pb-4">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 25 25"
                  >
                    <path
                      d="M19 5v14H5V5h14m1.1-2H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM11 7h6v2h-6V7zm0 4h6v2h-6v-2zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7z"
                      fill="#444444"
                    ></path>
                    <path fill="none" d="M0 0h24v24H0z"></path>
                  </svg>
                </figure>
                <h4 className="mb-0">20000+ Bookings a month</h4>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <img
                style={{ maxWidth: "84%" }}
                src={HubsBox}
                alt="Hajj Umrah Booking System"
              />
              <button className="btn btn-primary mt-3">Request a Demo</button>
            </div>
            <div className="col-lg-5">
              <div className="d-flex align-items-center mt-3 mb-4 pb-4 border-bottom">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 20 20"
                    style={{ marginTop: "-10px", marginLeft: "-5px" }}
                  >
                    <path
                      d="M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z"
                      fill="#444444"
                    ></path>
                    <path fill="none" d="M0 0h24v24H0z"></path>
                  </svg>
                </figure>
                <h4 className="mb-0">
                  $15M+ business generated through the platform
                </h4>
              </div>
              <div className="d-flex align-items-center mb-4 pb-4">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 25 25"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
                      fill="#444444"
                    ></path>
                  </svg>
                </figure>
                <h4 className="mb-0">
                  Real-time connectivity with the Maqam GDS
                </h4>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-center mb-5">Overview</h2>
        <img
          src={UmrahSolutionOverview}
          className="w-100 shadow rounded p-5"
          alt="Hajj and Umrah Booking Solution Overview"
          style={{ maxWidth: "1114px" }}
        />
      </div>

      <div
        className="pb-3 pt-4 mb-5 mt-5"
        style={{
          background: "linear-gradient(to right, #6ab33f 0%, #19afa7 100%)",
        }}
      >
        <div className="container">
          <h2 className="text-center mt-3 mb-5 text-white">Top Features</h2>
          <div className="row">
            <div className="col-lg-7">
              <ul className="text-white">
                <li>
                  <i className="material-icons"></i>
                  Live bookings for flights, hotels, and ground transportation
                </li>
                <li>
                  <i className="material-icons"></i>
                  Available for both B2C and B2B
                </li>
                <li>
                  <i className="material-icons"></i>
                  Real-time Connectivity with Maqam GDS and e-visa system
                </li>
                <li>
                  <i className="material-icons"></i>
                  Automatic generation of BRNs using the GDS
                </li>
                <li>
                  <i className="material-icons"></i>
                  Connectivity with MoHU authorized suppliers like Agoda,
                  Naqaba, Eumra, Tawaf, Bab al Umrah and Taqabul Yahaj
                </li>
                <li>
                  <i className="material-icons"></i>
                  Bulk booking of hotel rooms for large groups
                </li>
                <li>
                  <i className="material-icons"></i>
                  Integrates seamlessly with your existing website and IT
                  infrastructure
                </li>
                <li>
                  <i className="material-icons"></i>
                  Visa module for online visa application
                </li>
                <li>
                  <i className="material-icons"></i>
                  Creation of custom itineraries and packages with multiple
                  departures
                </li>
                <li>
                  <i className="material-icons"></i>
                  Shopping Cart module to book multiple Umrah products together
                </li>
                <li>
                  <i className="material-icons"></i>
                  Booking Management and notifications for various channels
                </li>
                <li>
                  <i className="material-icons"></i>
                  Mobile-friendly portals with complete content management
                </li>
              </ul>
            </div>
            <div className="col-lg-5">
              <img className="w-100" src={LappyUmrah} alt="Lappy Umrah" />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h2 className="text-center mt-5 mb-5">Modules</h2>
        <ul className="nav nav-tabs mt-5">
          <li className="nav-item">
            <button
              className={"nav-link " + (activeTab === "tab1" ? "active" : "")}
              onClick={() => setActive("tab1")}
            >
              B2C Portal
            </button>
          </li>
          <li className="nav-item">
            <button
              className={"nav-link " + (activeTab === "tab2" ? "active" : "")}
              onClick={() => setActive("tab2")}
            >
              B2B Portal
            </button>
          </li>
          <li className="nav-item">
            <button
              className={"nav-link " + (activeTab === "tab3" ? "active" : "")}
              onClick={() => setActive("tab3")}
            >
              Mid and Back Office
            </button>
          </li>
        </ul>

        {activeTab === "tab1" && (
          <div className="mt-4">
            <div className="row">
              <div className="col-lg-6">
                <ul>
                  <li>
                    <i className="material-icons"></i>
                    Mutamers can book customized packages with their preferred
                    flights, hotels and ground services options
                  </li>
                  <li>
                    <i className="material-icons"></i>
                    The system also gives them the flexibility to book
                    individual products from the system
                  </li>
                  <li>
                    <i className="material-icons"></i>
                    They can either book a single product, or add multiple
                    products to a shopping cart and book them together depending
                    on the availability
                  </li>
                  <li>
                    <i className="material-icons"></i>
                    Allows pilgrims to check the applicable visa fee based on
                    which the customer can make the payment and apply for the
                    e-visa
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ImageSlider slider="HUS1" />
              </div>
            </div>
          </div>
        )}

        {activeTab === "tab2" && (
          <div className="mt-4">
            <div className="row">
              <div className="col-lg-6">
                <ul>
                  <li>
                    <i className="material-icons"></i>
                    Separate portal allowing only registered agents and EAs/UOs
                    to book
                  </li>
                  <li>
                    <i className="material-icons"></i>
                    Allows agents to use OTA balance to make bookings and pay
                    the OTA on a credit basis
                  </li>
                  <li>
                    <i className="material-icons"></i>
                    Allows OTAs to define agent credit limits as well as pricing
                    for different agents to manage revenue
                  </li>
                  <li>
                    <i className="material-icons"></i>
                    Supports bulk bookings of over 100 rooms at once
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ImageSlider slider="HUS2" />
              </div>
            </div>
          </div>
        )}

        {activeTab === "tab3" && (
          <div className="mt-4">
            <div className="row">
              <div className="col-lg-6">
                <ul>
                  <li>
                    <i className="material-icons"></i>
                    Centralized management of Customers and Umrah bookings
                  </li>
                  <li>
                    <i className="material-icons"></i>
                    Allows configuration of advanced business rules and policies
                    for B2B and B2C channels
                  </li>
                  <li>
                    <i className="material-icons"></i>
                    Configuration of promotions and discounts
                  </li>
                  <li>
                    <i className="material-icons"></i>
                    Auto-generation of travel and financial documents with
                    fully-customizable templates and own branding
                  </li>
                  <li>
                    <i className="material-icons"></i>
                    Configuration of various types of markups and commissions
                  </li>
                  <li>
                    <i className="material-icons"></i>A comprehensive reporting
                    module to help you keep track of bookings, sales and
                    commissions
                  </li>
                  <li>
                    <i className="material-icons"></i>
                    Accounting ledgers for reconciliation with suppliers and
                    agents to help track profitability
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ImageSlider slider="HUS3" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SolutionsHajjUmrah;
