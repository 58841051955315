import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import ImageSlider from "../components/image-slider";

const SolutionsBusOperator = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="bus-operator" />
      <div className="container">
        <p className="text-center mb-5">
          TravelCarma's Online Bus Booking System allows you to automate your
          bus bookings and payments for different bus routes and intercity
          transfers. It also helps you manage your bus inventory, fares, routes,
          schedules as well as your entire back-office including accounting and
          reporting. With TravelCarma you can transform your pen-and-paper
          process into a dynamic online reservation system and gain an edge over
          the competition.
        </p>

        <div className="solution-highlights mb-5">
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex align-items-center mt-3 mb-4 pb-4 border-bottom">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48px"
                    viewBox="0 0 24 24"
                    width="48px"
                    fill="rgb(241, 130, 71)"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path d="M23 11.01L18 11c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h5c.55 0 1-.45 1-1v-9c0-.55-.45-.99-1-.99zM23 20h-5v-7h5v7zM20 2H2C.89 2 0 2.89 0 4v12c0 1.1.89 2 2 2h7v2H7v2h8v-2h-2v-2h2v-2H2V4h18v5h2V4c0-1.11-.9-2-2-2zm-8.03 7L11 6l-.97 3H7l2.47 1.76-.94 2.91 2.47-1.8 2.47 1.8-.94-2.91L15 9h-3.03z"></path>
                  </svg>
                </figure>

                <h4 className="mb-0">Web Portals</h4>
              </div>
              <div className="d-flex align-items-center mb-4 pb-4">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48px"
                    viewBox="0 0 24 24"
                    fill="rgb(241, 130, 71)"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M12 2c-4.42 0-8 .5-8 4v10c0 .88.39 1.67 1 2.22V20c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h8v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1.78c.61-.55 1-1.34 1-2.22V6c0-3.5-3.58-4-8-4zm5.66 2.99H6.34C6.89 4.46 8.31 4 12 4s5.11.46 5.66.99zm.34 2V10H6V6.99h12zm-.34 9.74l-.29.27H6.63l-.29-.27C6.21 16.62 6 16.37 6 16v-4h12v4c0 .37-.21.62-.34.73z" />
                    <circle cx="8.5" cy="14.5" r="1.5" />
                    <circle cx="15.5" cy="14.5" r="1.5" />
                  </svg>
                </figure>
                <h4 className="mb-0">Bus Booking Engine</h4>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="d-flex align-items-center mt-3 mb-4 pb-4 border-bottom">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48px"
                    viewBox="0 0 24 24"
                    fill="rgb(241, 130, 71)"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M2 20h20v-4H2v4zm2-3h2v2H4v-2zM2 4v4h20V4H2zm4 3H4V5h2v2zm-4 7h20v-4H2v4zm2-3h2v2H4v-2z" />
                  </svg>
                </figure>
                <h4 className="mb-0">Inventory Management System</h4>
              </div>
              <div className="d-flex align-items-center mb-4 pb-4">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48px"
                    viewBox="0 0 24 24"
                    width="48px"
                    fill="rgb(241, 130, 71)"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path d="M17 16l-4-4V8.82C14.16 8.4 15 7.3 15 6c0-1.66-1.34-3-3-3S9 4.34 9 6c0 1.3.84 2.4 2 2.82V12l-4 4H3v5h5v-3.05l4-4.2 4 4.2V21h5v-5h-4z"></path>
                  </svg>
                </figure>
                <h4 className="mb-0">Agency Management System</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row text-center solutions-section">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-5">
                <h2>Web Portals</h2>
                <p>
                  Let your customers book your bus tickets in the comfort of
                  their living rooms! With our <strong>bus portals</strong>,
                  your customers can browse your <strong>bus inventory</strong>,
                  choose their journey dates, choose route options, pick seats
                  and finally make an online payment with instant confirmation.
                </p>
                <button className="btn btn-primary">Request a Demo</button>
              </div>
              <div className="col-lg-7 d-flex">
                <div className="product-slider shadow-sm w-100">
                  <ImageSlider slider="BSS1" />
                </div>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Easy to build and launch</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Using the <strong>CMS-based website</strong>, you can create
                  your website easily by selecting the templates available and
                  create the content for each page, including text, images,
                  videos and inquiry forms and your own branding across the
                  site. Moreover, our portals support multiple languages,
                  allowing you to target customers in various regions.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Easy to manage and update</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Our CMS portals make it very easy for you to manage and
                  customize your web content. You can update all the content on
                  your portal, with no knowledge of HTML and without the help of
                  webmasters or web developers, thus reducing your manpower
                  cost.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">SEO Friendly</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  In order to make all your pages to be listed on search
                  engines, our CMS provides SEO friendly pages with the facility
                  to easily update the site's meta data such as page titles,
                  descriptions, image tags etc. You can easily submit your site
                  to search engines and also host the same content with minor
                  modifications for multiple domains.
                </p>
              </div>
            </div>
            <div className="solutions-separator"></div>
            <div className="row">
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="BSS2" />
              </div>
              <div className="col-lg-5">
                <h2>Bus Booking Engine</h2>
                <p>
                  With our <strong>bus booking engine</strong>, you can have
                  your bus inventory booked across the globe as per your
                  customers' preferred currency. Our Bus booking engine can be
                  easily integrated with your website as a{" "}
                  <strong>white-label</strong> or{" "}
                  <strong>private label booking engine</strong>. Our
                  sophisticated booking engine rules can handle the most
                  complicated scenarios and also let you access inventory of
                  other bus operators via XML API.
                </p>
                <button className="btn btn-primary">Request a Demo</button>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">
                  Easy Integration with your website
                </h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Our bus booking engine is easy to integrate with your existing
                  website or with the <strong>content management system</strong>{" "}
                  you are using. You can manage the Internet booking engine, as
                  a white or private label, such that it fits with your web
                  environment, and your down line can also use your booking
                  engine that matches the look and feel of their website.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Instant e-ticket generation</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Our <strong>Bus reservation system</strong> is an e-ticketing
                  solution that helps your customers book seats online and get
                  instant confirmation. Once the customer has booked on your
                  system, they will receive the confirmation message through
                  email. The system also allows online modifications and
                  cancellations. Registered customers can also view their
                  booking history.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Automated Payments</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Automate your payments process through{" "}
                  <strong>TravelCarma</strong>. Our system supports a wide range
                  of online and offline payment options, including Paypal,
                  credit/debit cards and cash payments. We have integrated over
                  40+ payment gateways across the world and can easily integrate
                  the payment gateway of your choice upon request.
                </p>
              </div>
            </div>
            <div className="solutions-separator"></div>
            <div className="row">
              <div className="col-lg-5">
                <h2>Inventory Management System</h2>
                <p>
                  Centrally manage your entire{" "}
                  <strong>bus inventory with our Bus CRS</strong>. You can
                  easily configure your bus routes, seating templates, seat
                  availability etc and manage the complete{" "}
                  <Link
                    to="/solutions/inventory-distribution"
                    className="text-decoration-none text-primary"
                  >
                    inventory distribution
                  </Link>{" "}
                  through our web based CRS. Our{" "}
                  <strong>bus reservation system</strong> allows you to perform
                  back office functions like setting up of your fleet, branches,
                  fares, time tables, pick-up and drop off points etc.
                </p>
                <button className="btn btn-primary">Request a Demo</button>
              </div>
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="BSS3" />
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Business Management</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  You can manage your buses, schedules, fares, blocks/quotas,
                  seasonal pricing, amenities management etc through our CRS.
                  You can also manage your policies and can define new policies
                  for modification and cancellation of the bus reservation. As
                  an admin you can modify and cancel bookings for specific
                  travelers. All the data of the bus route, seat availability,
                  pricing, promotions etc are highly secured and been tied with
                  the user role and credentials.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Employee Management</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  You can create Employees under you by different roles such as
                  Booking Office, Accountant, Dispatch Staff, Supervisor etc.
                  Employees created by the bus operator or sub employees created
                  under a specific employee can make the booking for a walk-in
                  customer, agent on the phone, branch on the phone and
                  privileged member booking. The employee can also do current
                  bookings and single window bookings for any other operator.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Comprehensive Reporting</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Our dynamic dashboard gives you an overview on your business
                  to help you devise/modify your business strategies. Our system
                  generates business reports for booking and cancellation done
                  by agents or customers. You can also generate intelligent
                  reports like daily arrival report, inventory report, rate
                  report, billing details report etc. which can help to take
                  strategic decisions.
                </p>
              </div>
            </div>
            <div className="solutions-separator"></div>
            <div className="row">
              <div className="col-lg-7 d-flex">
                <ImageSlider slider="BSS4" />
              </div>
              <div className="col-lg-5">
                <h2>Agency Management System</h2>
                <p>
                  <strong>TravelCarma</strong> offers a complete Agency
                  Management System for bus operators to manage their agents.
                  All agents that have been created can configure their
                  business, manage their employees and set up email templates
                  through our bus system. It also allows you to manage mark up
                  and commissions for individual agents.
                </p>
                <button className="btn btn-primary">Request a Demo</button>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">
                  Full control over pricing and commissions
                </h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  You can manage all your all agents working for you through
                  this system. You can create, edit and delete the agents and
                  also block certain agents for a particular time period. Most
                  importantly, you can manage the pricing for your agents by
                  setting up the mark ups for them at an individual level.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Data security and Co-branding</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  Different credentials are available for different bus agents
                  to keep the data secure and restrict access to certain agents.
                  Our <strong>co-branding</strong> facility allows you to set up
                  the interface for each agent to provide them a rich look and
                  feel from our templates.
                </p>
              </div>
              <div className="col-lg-4">
                <h5 className="mt-5 mb-3">Intelligent Reports</h5>
                <p
                  className="text-secondary"
                  style={{ fontSize: "1rem", lineHeight: "28px" }}
                >
                  You can generate different reports in order to get information
                  on the business done by agents working under you. You can also
                  generate intelligent reports for the commissions to be paid to
                  your agents.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsBusOperator;
