import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import SuppliersCar from "../components/suppliers-car";

const ApiTransferIntegration = () => {
  return (
    <div>
      <PageHeader page="transfer-api-integration" />
      <div className="container">
        <div className="text-center">
          <p>
            Whether you're an OTA, a DMC or a Car aggregator, integrating{" "}
            <b>Car rental/Transfers APIs</b> offers huge revenue generating
            opportunities for you. TravelCarma <b>Car Rental API</b> and
            Transfers API Integration can help you provide{" "}
            <Link
              to="/products/booking-engines/car-booking-engine"
              className="text-decoration-none text-primary"
            >
              online car booking engine
            </Link>{" "}
            to your clients, along with flight and hotel booking on the same
            portal under your own branding.
          </p>
          <p>
            We have integrated all major vehicle API wholesalers like Cartrawler
            and I'way transfers, plus all the major GDSs providing car inventory
            such as Hotelbeds, Sabre and Tourico.
          </p>

          <SuppliersCar />
        </div>
      </div>
    </div>
  );
};

export default ApiTransferIntegration;
