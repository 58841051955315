import React from "react";
import { Link } from "react-router-dom";
import Ebrochure from "../assets/resources/TravelCarma_ebrochure.pdf";
import COVIDSurveyReport from "../assets/resources/COVID-Survey-Report.pdf";
import SM1 from "../assets/images/icn_skype.png";
import SM2 from "../assets/images/icn_email.png";
import SM3 from "../assets/images/icn_linkedIn.png";
import SM4 from "../assets/images/icn_facebook.png";
import SM5 from "../assets/images/icn_twiter.png";

const Footer = () => {
  return (
    <div>
      <div className="footer-links">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-2">
                  <Link>Company</Link>
                  <ul className="row list-unstyled">
                    <li>
                      <Link to="/company/about-travelcarma">About Us</Link>
                    </li>
                    <li>
                      <Link to="/company/leadership-team">Leadership Team</Link>
                    </li>
                    <li>
                      <Link to="/company/why-us">Why Us</Link>
                    </li>
                    <li>
                      <Link to="/company/awards">Travel Awards</Link>
                    </li>
                    <li>
                      <Link to="/company/events">Events</Link>
                    </li>
                  </ul>
                  <Link>Blog</Link>
                  <br />
                  <Link>Contact Us</Link>
                </div>
                <div className="col-lg-4">
                  <Link to="/">Products</Link>
                  <ul className="row list-unstyled">
                    <li>
                      <Link to="/products/booking-engines/flight-booking-engine">
                        Flight Booking Engine
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/booking-engines/hotel-booking-engine">
                        Hotel Booking Engine
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/booking-engines/car-booking-engine">
                        Car Booking Engine
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/booking-engines/activity-booking-engine">
                        Activity Booking Engine
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/booking-engines/transfers-booking-engine">
                        Transfers Booking Engine
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/inventory-management-system">
                        Inventory Management System
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/back-office">Back Office</Link>
                    </li>
                    <li>
                      <Link to="/products/b2b-booking-system">
                        B2B Booking System
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <Link to="/">Solutions</Link>
                  <ul className="row list-unstyled">
                    <li>
                      <Link to="/solutions/travel-agency-software">
                        Travel Agency
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions/dmc-software">DMC</Link>
                    </li>
                    <li>
                      <Link to="/solutions/host-agency-solution">
                        Host Agency Solution
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions/bus-operator">Bus Operator</Link>
                    </li>
                    <li>
                      <Link to="/solutions/travel-data-xchange-switch">
                        Travel Data Xchange Switch
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions/hajj-umrah-booking-solution">
                        Hajj Umrah Booking Solution
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions/inventory-distribution">
                        Inventory Distribution
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions/development-api">
                        Development API
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions/mobile-travel-apps">
                        Mobile Travel Apps
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <Link to="/">Customers</Link>
                  <ul className="row list-unstyled">
                    <li>
                      <Link to="/customers">Customers</Link>
                    </li>
                    <li>
                      <Link to="/customer-testimonials">Testimonials</Link>
                    </li>
                    <li>
                      <Link to="/case-studies">Case Studies</Link>
                    </li>
                  </ul>
                  <Link to="/">Suppliers</Link>
                  <ul className="row list-unstyled">
                    <li>
                      <Link to="/suppliers/travel-xml-api-integrations">
                        Travel API Integration
                      </Link>
                    </li>

                    <li>
                      <Link to="/suppliers/flight-api-integration">
                        Flight API Integration
                      </Link>
                    </li>
                    <li>
                      <Link to="/suppliers/hotel-api-integration">
                        Hotel API Integration
                      </Link>
                    </li>
                    <li>
                      <Link to="/suppliers/sightseeing-api-integration">
                        Activities API Integration
                      </Link>
                    </li>
                    <li>
                      <Link to="/suppliers/transfer-api-integration">
                        Car Rental API Integration
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* <div>
                  <Link to="/">Resources</Link>
                  <ul className="row list-unstyled">
                    <li>
                      <Link to="https://blog.travelcarma.com" target="_blank">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link to="/resources/ebooks">Ebooks</Link>
                    </li>
                    <li>
                      <Link to="/resources/infographics">Infographics</Link>
                    </li>
                    <li>
                      <Link to={Ebrochure} target="_blank">
                        Ebrochure
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.travelcarma.com/Presentations/Travelcarma-Corporate-Overview/Travelcarma-Corporate-Overview.html"
                        target="_blank"
                      >
                        Corporate Presentation
                      </Link>
                    </li>
                    <li>
                      <Link to={COVIDSurveyReport} target="_blank">
                        COVID Survey Report
                      </Link>
                    </li>
                    <li>
                      <Link to="/resources/videos">Videos</Link>
                    </li>
                    <li>
                      <Link to="/resources/faqs">FAQs</Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-3 rounded" style={{ background: "#2E2E2E" }}>
                <h5>Contact Us</h5>
                <h6>+91 792 687 2171</h6>
                <div className="SocialIcons pt-3">
                  <ul className="list-unstyled d-flex ">
                    <li>
                      <Link
                        title="Skype Me"
                        to="skype:marketing.avanicimcon?chat"
                      >
                        <img height="24" width="24" alt="Skype Me" src={SM1} />
                      </Link>
                    </li>
                    <li>
                      <Link title="Email Me" to="mailto:sales@travelcarma.com">
                        <img height="24" width="24" alt="Email Me" src={SM2} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        title="TravelCarma on LinkedIn"
                        target="_blank"
                        to="https://www.linkedin.com/company/travelcarma.com"
                      >
                        <img
                          height="24"
                          width="24"
                          alt="TravelCarma on LinkedIn"
                          src={SM3}
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        title="TravelCarma on Facebook"
                        target="_blank"
                        to="https://www.facebook.com/TravelCarma"
                      >
                        <img
                          height="24"
                          width="24"
                          alt="TravelCarma on Facebook"
                          src={SM4}
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        title="follow @TravelCarma"
                        target="_blank"
                        to="https://twitter.com/travelcarma"
                      >
                        <img height="24" width="24" alt="Twitter" src={SM5} />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="country-list">
        <div className="container text-center" style={{ color: "#999" }}>
          <h5>Global Client Base</h5>
          <p>
            <span>
              <strong>Africa :</strong>
            </span>{" "}
            <span>Benin, </span> <span>Cameroon, </span> <span>Kenya, </span>{" "}
            <span>Namibia, </span> <span>Nigeria,</span> <span>Libya,</span>{" "}
            <span>Zambia,</span> <span>Tunisia</span> |{" "}
            <span>
              <strong>Asia :</strong>
            </span>{" "}
            <span>China,</span> <span>Hong Kong,</span> <span>India,</span>{" "}
            <span>Indonesia,</span> <span>Pakistan,</span>{" "}
            <span>Philippines,</span> <span>Thailand,</span>{" "}
            <span>Vietnam</span> |{" "}
            <span>
              <strong>Europe :</strong>
            </span>{" "}
            <span>Albania,</span> <span>Croatia,</span> <span>Greece,</span>{" "}
            <span>Greenland,</span> <span>Italy,</span> <span>Spain,</span>{" "}
            <span>Turkey,</span> <span>United Kingdom </span> |{" "}
            <span>
              <strong>Latin America :</strong>
            </span>{" "}
            <span>Argentina,</span> <span>Chile,</span>{" "}
            <span>Dominican Republic,</span> <span>Honduras,</span>{" "}
            <span>Venezuela</span> |{" "}
            <span>
              <strong>Middle East :</strong>
            </span>{" "}
            <span>Bahrain,</span> <span>Iran,</span> <span>Jordan,</span>{" "}
            <span> Kuwait,</span> <span>Lebanon,</span> <span>Oman,</span>{" "}
            <span>Palestinian Territory,</span>{" "}
            <span>United Arab Emirates</span> |{" "}
            <span>
              <strong>North America :</strong>
            </span>{" "}
            <span>Barbados,</span> <span>Bermuda,</span> <span>Canada,</span>{" "}
            <span>Jamaica,</span> <span>USA,</span> <span>Trinidad</span> |{" "}
            <span>
              <strong>Oceania :</strong>
            </span>{" "}
            <span>Australia,</span> <span>New Zealand</span>
          </p>
        </div>
      </div>
      <footer className="pt-2 pb-2">
        <div className="container">
          <p className="text-center text-secondary small m-0">
            Copyright 2023 by TravelCarma All rights reserved
            <Link
              to="https://www.dmca.com/Protection/Status.aspx?ID=53aca12c-7870-4be2-85e6-0e813ba3da6a&refurl=https://www.travelcarma.com/index.htm"
              title="DMCA.com Protection Status"
              className="ms-4"
            >
              <img
                src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-09.png?ID=96f65bbd-feb9-43ba-b2ab-cc5e76028076"
                alt="DMCA.com Protection Status"
              />
            </Link>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
