import React from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import ImageSlider from "../components/image-slider";

const ProductsInventoryManagement = () => {
  return (
    <div className="page-inside page-booking-engine">
      <PageHeader page="inventory-management" />

      <div className="benefits-section-bg mt-5">
        <div className="container">
          <div className="row">
            <h2 className="text-center">Hotel CRS</h2>
            <div className="col-lg-6">
              <p style={{ fontSize: "1rem", lineHeight: "32px" }}>
                <strong>TravelCarma Hotel CRS</strong> is a multi-tenant{" "}
                <strong>
                  hotel inventory management and distribution system
                </strong>
                , providing hotel room rates and availability for many different
                channels such as the GDS, 3rd party websites and brand websites
                etc. It allows property owners as well as intermediaries to
                manage their hotel inventory from a single interface.
              </p>
              <p style={{ fontSize: "1rem", lineHeight: "32px" }}>
                Among other functionalities, the CRS can administer room
                allocations of single properties or on hotel chain level.
                Furthermore, rates can be controlled and availabilities in the{" "}
                <Link
                  to="/solutions/inventory-distribution"
                  className="text-decoration-none text-primary"
                >
                  inventory distribution channels
                </Link>{" "}
                can be monitored. The CRS of a hotel chain transfers data to the
                distribution channels.
              </p>
            </div>

            <div className="col-lg-6 d-flex">
              <ImageSlider slider="HCS1" />
            </div>

            <h3 className="mt-4 mb-5 text-center">Benefits</h3>

            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>Defining property, room
                  types, property amenities, room amenities etc.
                </li>
                <li>
                  <i className="material-icons"></i>Upload photos of the
                  property, rooms and amenities
                </li>
                <li>
                  <i className="material-icons"></i>Configure room types and
                  room allocation for selling online
                </li>
                <li>
                  <i className="material-icons"></i>Pre-mapped with Tripadvisor
                  hotel data for reviews integration
                </li>
                <li>
                  <i className="material-icons"></i>Periodic rate configuration
                  for different seasons, events, festivals etc.
                </li>
                <li>
                  <i className="material-icons"></i>Rates can be defined
                  specific to the nationality of your customer
                </li>
                <li>
                  <i className="material-icons"></i>
                  <strong>Enterprise CRS</strong> for Hotels along with rate
                  management using rate code
                </li>
                <li>
                  <i className="material-icons"></i>Set up promotion/ offers for
                  a specific period with exception criteria
                </li>
                <li>
                  <i className="material-icons"></i>Criteria includes % off,
                  fixed discounts, free nights and value added promotions
                </li>
                <li>
                  <i className="material-icons"></i>Set up different policies by
                  providing their respective details and apply different
                  parameters
                </li>
                <li>
                  <i className="material-icons"></i>Policies include payment
                  policies, cancellation policies, tax policy, child policy,
                  property policies with terms &amp; conditions
                </li>
                <li>
                  <i className="material-icons"></i>User can easily manage
                  various details for their hotel property
                </li>
                <li>
                  <i className="material-icons"></i>CRS displays a Dashboard,
                  which shows the current status of business in graphical way
                </li>
              </ul>
            </div>

            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>User can view various
                  transaction dynamic details on the Dashboard with specific
                  filters based on customized transactional criteria's
                </li>
                <li>
                  <i className="material-icons"></i>Business Reports includes
                  business details report, revenue reports, product wise revenue
                  reports, billing reports, daily arrival reports, reservation
                  details reports, and cancellation reports, billing details
                  reports, inventory reports, business rate reports and
                  nationality rate reports etc.
                </li>
                <li>
                  <i className="material-icons"></i>Stop Sell: Set generic stop
                  sell dates and promotional blackout dates for an inventory to
                  make it unavailable for online booking
                </li>
                <li>
                  <i className="material-icons"></i>Notifications that are sent
                  automatically in the event of booking, room allocation selling
                  out, cancellation etc.
                </li>
                <li>
                  <i className="material-icons"></i>Integrated Google Map to set
                  longitude and latitude for the location
                </li>
                <li>
                  <i className="material-icons"></i>Ability to book large number
                  of rooms of one hotel through API in single request
                </li>
                <li>
                  <i className="material-icons"></i>CRS will provide volume
                  based net rates per transaction along with rate code based
                  pricing and discounting.
                </li>
                <li>
                  <i className="material-icons"></i>Create users at operational
                  levels who have rights to manage their own as well as their
                  subordinate user's hotel inventory
                </li>
                <li>
                  <i className="material-icons"></i>Meta Data publishing through
                  Lookup API or download via FTP on request
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section-separator"></div>

      <div className="benefits-section-bg">
        <div className="container">
          <div className="row">
            <h2 className="text-center">Activity/Excursion CRS</h2>
            <div className="col-lg-6">
              <p style={{ fontSize: "1rem", lineHeight: "32px" }}>
                <strong>Activity / Sightseeing / Excursions CRS</strong> is a
                multi-tenant <strong>inventory management system</strong> with
                extranet that allows activity operators to load all their
                offerings in a centralized database along with its rates,
                availability, schedules and policies along with capacity in each
                schedule.
              </p>
              <p style={{ fontSize: "1rem", lineHeight: "32px" }}>
                They can create their tours individually and they can create
                rates and availability schedule for each tour along with
                separate policies for each tour.
              </p>
            </div>

            <div className="col-lg-6 d-flex">
              <ImageSlider slider="ACS1" />
            </div>

            <h3 className="mt-4 mb-5 text-center">Benefits</h3>

            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>Ability to have multilevel
                  access - Administrator and Member Users
                </li>
                <li>
                  <i className="material-icons"></i>Allows multiple tour
                  operator inventory management using single login access
                </li>
                <li>
                  <i className="material-icons"></i>Ability to provide
                  consolidated output from all tour operators as an API using
                  enterprise distribution management system
                </li>
                <li>
                  <i className="material-icons"></i>Ability to create unlimited
                  Activity/Excursion offers under individual{" "}
                  <Link
                    to="/solutions/dmc-software"
                    className="text-decoration-none text-primary"
                  >
                    tour operator
                  </Link>{" "}
                  access
                </li>
                <li>
                  <i className="material-icons"></i>Ability to define
                  inclusions, exclusions, general terms and conditions for each
                  tour separately
                </li>
              </ul>
            </div>

            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>Policies setup (Payment
                  policy, Cancellation policy, Tax policy) at general{" "}
                  <strong>tour operator</strong> as well as for individual tour
                  offers
                </li>
                <li>
                  <i className="material-icons"></i>Multiple season as well as
                  schedule based rate setup
                </li>
                <li>
                  <i className="material-icons"></i>Traveler type wise rate
                  setup (Adult, child, infant) / Per unit based rate setup
                </li>
                <li>
                  <i className="material-icons"></i>Ability to upload unlimited
                  photos for each tour
                </li>
                <li>
                  <i className="material-icons"></i>Ability to upload tour
                  brochure in PDF format
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section-separator"></div>

      <div className="benefits-section-bg">
        <div className="container">
          <div className="row">
            <h2 className="text-center">Transfers CRS</h2>
            <div className="col-lg-6">
              <p style={{ fontSize: "1rem", lineHeight: "32px" }}>
                <strong>TravelCarma Transfers CRS</strong> allows transfer
                operators to load all their transfers in a centralized database
                along with its rates, availability and policies along with
                capacity.
              </p>
              <p style={{ fontSize: "1rem", lineHeight: "32px" }}>
                It could be connected with{" "}
                <Link
                  to="/solutions/inventory-distribution"
                  className="text-decoration-none text-primary"
                >
                  Inventory Distribution platform
                </Link>{" "}
                via connector for further distribution. Operators can create
                their point-to-point transfers individually and they can set up
                rates and availability schedule for each transfer.
              </p>
            </div>

            <div className="col-lg-6 d-flex">
              <ImageSlider slider="TCS1" />
            </div>

            <h3 className="mt-4 mb-5 text-center">Benefits</h3>

            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>Ability to have multilevel
                  access - Administrator and Member Users
                </li>
                <li>
                  <i className="material-icons"></i>Allows multiple{" "}
                  <strong>transfer operators inventory management</strong> using
                  single login access
                </li>
                <li>
                  <i className="material-icons"></i>Ability to provide
                  consolidated output from all <strong>tour operators</strong>{" "}
                  as an API using{" "}
                  <strong>enterprise distribution management system</strong>
                </li>
                <li>
                  <i className="material-icons"></i>Ability to create unlimited
                  transfer categories and routes under individual operator
                  access
                </li>
                <li>
                  <i className="material-icons"></i>Multiple season as well as
                  schedule based rate setup
                </li>
              </ul>
            </div>

            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>Policies setup (Payment
                  policy, Cancellation policy, Tax policy) at general tour
                  operator as well as for individual tour offers
                </li>
                <li>
                  <i className="material-icons"></i>Ability to do rate setup
                  based on individual seat based as well as for chartered
                  services
                </li>
                <li>
                  <i className="material-icons"></i>Ability to upload unlimited
                  photos for each transfer category
                </li>
                <li>
                  <i className="material-icons"></i>Ability to define terms and
                  conditions
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section-separator"></div>

      <div className="benefits-section-bg">
        <div className="container">
          <div className="row">
            <h2 className="text-center">Car Rental CRS</h2>
            <div className="col-lg-6">
              <p style={{ fontSize: "1rem", lineHeight: "32px" }}>
                <strong>TravelCarma car rental CRS</strong> allows customers of{" "}
                <strong>car rental operators</strong> and agencies to book car
                rentals online with real-time rates and instant confirmation.
                The CRS gives users full control over their{" "}
                <Link
                  to="/solutions/inventory-distribution"
                  className="text-decoration-none text-primary"
                >
                  inventory distribution
                </Link>
                .
              </p>
              <p style={{ fontSize: "1rem", lineHeight: "32px" }}>
                You can configure details of all the cars in your fleet, pick-up
                locations, rates and availability for each car, promotions as
                well as details of all the <strong>rental car operators</strong>{" "}
                you work with. The CRS has built-in Extranet that allows
                operators to log in and manage the inventory on their own.
              </p>
            </div>

            <div className="col-lg-6 d-flex">
              <ImageSlider slider="CCS1" />
            </div>

            <h3 className="mt-4 mb-5 text-center">Benefits</h3>

            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>Configure car
                  categories/types using SIPP codes
                </li>
                <li>
                  <i className="material-icons"></i>Configure car
                  categories/types using SIPP codes
                </li>
                <li>
                  <i className="material-icons"></i>Set up suppliers
                </li>
                <li>
                  <i className="material-icons"></i>Set up rates per hour/day
                </li>
                <li>
                  <i className="material-icons"></i>Define pick-up location
                  (Meet &amp; Greet, Shuttle Bus, In Terminal etc)
                </li>
                <li>
                  <i className="material-icons"></i>Stop Sell
                </li>
                <li>
                  <i className="material-icons"></i>Promotions Management
                </li>
              </ul>
            </div>

            <div className="col-lg-6">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <i className="material-icons"></i>Define inclusions (Collision
                  and damage waiver, theft protection etc)
                </li>
                <li>
                  <i className="material-icons"></i>Extranet facility which you
                  can provide to the car rental operators to manage their own
                  cars
                </li>
                <li>
                  <i className="material-icons"></i>Rate Authorization - allows
                  you to publish rates uploaded by car rental operators after
                  verifying them
                </li>
                <li>
                  <i className="material-icons"></i>Policy Management such as
                  Payment policy, Cancellation policy, Tax policy, Children
                  policy, Terms and Conditions, etc
                </li>
                <li>
                  <i className="material-icons"></i>Reporting module to generate
                  different type of reports such as revenue report, supplier
                  wise report, summary report, booking detail reports, etc.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsInventoryManagement;
